
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  components: {},
  computed: {
    ...mapGetters(["reverse_label_data"]),
  },
})
export default class Label extends Vue {
  public selectedAttributesWater: string[];
  public selectedAttributesCanal: string[];
  public labelData: any;

  @Prop() readonly styleSet: any;
  @Prop() readonly syslist: { id: number; show: boolean; name: string }[];

  @Watch("reverse_label_data")
  onReverseLabelStyleDataChange(data: any) {
    this.reverseStyleData(data);
  }

  constructor() {
    super();
    this.selectedAttributesWater = [];
    this.selectedAttributesCanal = [];
    this.labelData = {};
  }

  get symbolTagsWater() {
    const list = this.styleSet.filter((x) => x.layerId === 1);
    return list;
  }

  get symbolTagsCanal() {
    const list = this.styleSet.filter((x) => x.layerId === 3);
    return list;
  }

  setSymbolsWater(items, selected) {
    this.labelData["water"] = selected;
    this.$store.dispatch("map/changeLabelData", this.labelData);
    this.$emit("set-symbols", items, selected);
  }

  setSymbolsCanal(items, selected) {
    this.labelData["canal"] = selected;
    this.$store.dispatch("map/changeLabelData", this.labelData);
    this.$emit("set-symbols", items, selected);
  }

  reverseStyleData(data) {
    if (data.water || data.canal) {
      this.selectedAttributesWater = data.water;
      this.selectedAttributesCanal = data.canal;
    } else {
      this.selectedAttributesWater = [];
      this.selectedAttributesCanal = [];
    }
  }
}
