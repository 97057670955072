
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  components: {},
  computed: {
  },
})
export default class Projection extends Vue {
  public menu: boolean;

  @Prop() readonly coordinateSystems: any[];

  constructor() {
    super();
    this.menu = false;
  }

  changeCoordinatesProjection(id) {
    this.$emit("change-coordinates-projection", this.coordinateSystems.find(x => x.id === id));
  }
}
