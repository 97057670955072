
/* eslint-disable */
//ServicesF
import SymbolLayers from "../services/SymbolLayers";
import GeocodeAPI from "../services/GeocodeAPI";
import ActiveDrawingService from "../services/ActiveDrawingService";
import MapService from "../services/MapService";

//Map libraries
import "@/external/print-lib/css/styles.css";
import mapboxgl, { Map, GeoJSONSource, LngLatLike } from "mapbox-gl";
import * as turf from "@turf/turf";
import MapboxGeocoder from "@maplibre/maplibre-gl-geocoder";
import "@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

//Interfaces
import { IDataObject } from "@/interfaces";

//Components
import SidebarMain from "@/components/map/SidebarMain.vue";
import SidebarFilters from "@/components/map/SidebarFilters.vue";
import SidebarStyle from "@/components/map/SidebarStyle.vue";
import SidebarLayers from "@/components/map/SidebarLayers.vue";
import ExportAttributes from "@/components/map/ExportAttributes.vue";
import IDEditor from "@/components/map/IDEditor.vue";
import Superset from "@/components/map/Superset.vue";
import Projection from "@/components/map/Projection.vue";
const TMapExport = require("@/components/map/TMapExport.vue").default;

//API, Vue, Vuex, Constants
import { getMapWMSLayers } from "@/api/map";
import { getPolygons, savePolygon } from "@/api/breakdown";
import {
  getNomenclatures,
  getTagRefByLang,
  getSystems,
  getObjects,
} from "@/api/nomenclatures";
import {
  getMapSettings,
  getConditionalFilters,
  getPredefinedDefinition,
  saveCustomThematicSetup,
  deleteCustomThematicSetup,
} from "@/api/definition";
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import layer from "@/utils/constants/map/layer";
import tools from "@/utils/constants/map/tools";
import source from "@/utils/constants/map/source";
import common from "@/utils/constants/map/common";
import { calcScale, calcZoom, loadingHide, loadingShow } from "@/utils";

@Component({
  components: {
    SidebarMain,
    SidebarFilters,
    SidebarStyle,
    IDEditor,
    Superset,
    SidebarLayers,
    ExportAttributes,
    TMapExport,
    Projection,
  },
  computed: {
    ...mapGetters([
      "last_map_center",
      "last_map_zoom",
      "zone_target_attribute",
      "search_feature",
      "search_cadastre",
    ]),
  },
})
export default class TMap extends Vue {
  public data: IDataObject;
  public map: Map;
  public systems: { id: number; show: boolean; name: string }[];
  public externalSystems: { id: number; show: boolean; name: string }[];
  public otherSystems: { id: number; show: boolean; name: string }[];
  public projectSystems: { id: number; show: boolean; name: string }[][];
  public outOfUseSystems: { id: number; show: boolean; name: string }[][];
  public uvmSystems: { id: number; show: boolean; name: string }[];
  public objectTypes: { id: number; show: boolean; name: string }[][];
  public nomenclatureFilters: any[];
  public layerList: any[];
  public layerSelectableList: any[];
  public layerSources: object;
  public layerStyles: object;
  public layerFilters: object;
  public conditionalFilters: object;
  public tagRefs: object;
  public showRedirectButton: boolean;
  public showLabelLayer: boolean;
  public symbolLayer: SymbolLayers;
  public geocodeApi: GeocodeAPI;
  public activedrawing: ActiveDrawingService;
  public mapService: MapService;
  public mbdraw: MapboxDraw;
  public polygonAttr: any[];
  public showContainer: boolean;
  public zoomScale: string;
  public geojson: any;
  public linestring: any;
  public showIDEditor: boolean;
  public showActiveDrawingByConfig: boolean;
  public showExternalSystemsByConfig: boolean;
  public showOtherSystemsByConfig: boolean;
  public showProjectSystemsByConfig: boolean;
  public showOutOfUseSystemsByConfig: boolean;
  public showUVMSystemsByConfig: boolean;
  public measureMode: boolean;
  public showByMobileDevice: boolean;
  public showSuperset: boolean;
  public coordinateSystems: any[];
  public coordinateSystem: string;
  public predefinedData: any;
  public filtersContainer: any;
  public generalFilter: any;
  public selectedWMSLayers: string[];
  public mouseLngLat: any;
  public touchLngLat: any;

  @Watch("showLabelLayer")
  onZoomChange(zoomValid: boolean) {
    this.symbolLayer.setLayersByParams(
      this.map,
      this.systems,
      this.objectTypes,
      zoomValid
    );
  }

  @Watch("data.selectionData")
  onSelectionChange(data: object[]) {
    if (this.map.getZoom() >= 10) {
      this.mapSelectionUpdate(data);
    }
  }

  @Watch("$store.getters.token")
  onBearerTokenPresent(data: string) {
    this.addMapWMSLayers();
    this.loadNomenclatures();
    this.getMapDefinitionSettings();
    this.loadConditionalFilters();
    this.loadTagRefByLang();
    this.loadPredefinedDefinition();
  }

  @Watch("zone_target_attribute")
  onTargetAttrChange(target: object) {
    this.setZoneAttributes(target["osm_id"], target["key_value"]);
  }

  @Watch("search_feature")
  onSearchFeatureChange(feature: object) {
    this.centerToFeature(feature);
  }

  @Watch("search_cadastre")
  onSearchCadastreChange(feature: any) {
    const bbox = feature.bbox;
    this.map.fitBounds(bbox, { duration: 1000, offset: [0, 0] });
  }

  constructor() {
    super();
    this.data = {
      zoom: this.$store.getters.last_map_zoom ?? 10,
      center: JSON.parse(process.env.VUE_APP_OSM_CENTER),
      maxBounds: common.OSM_MAX_BOUNDS,
      showMap: true,
      isSourceLoaded: false,
      isBoxZoom: false,
      isDragPan: false,
      selectionList: [],
      selectionData: [],
      highlightedId: -1,
      highlightedSrc: "",
      highlightedSrcLayer: "",
      windowSelection: { start: null, active: true, startX: 0, startY: 0 },
      boxMode: "select",
      zones: source.ZONES_INIT_OBJECT,
      editZoneAttr: false,
      editZoneGeom: false,
      deletedZones: [],
      wsmlayers: [],
    };
    this.layerList = [];
    this.layerSelectableList = [];
    this.layerStyles = {};
    this.layerFilters = {};
    this.layerSources = {};
    this.nomenclatureFilters = [];
    this.conditionalFilters = {};
    this.tagRefs = {};

    this.systems = common.SYSTEMS;
    this.objectTypes = common.OBJECTS;
    this.showRedirectButton = false;
    this.showLabelLayer = false;
    this.symbolLayer = new SymbolLayers();
    this.geocodeApi = new GeocodeAPI();
    this.activedrawing = new ActiveDrawingService();
    this.mapService = new MapService();
    this.mbdraw = tools.MAPBOX_DRAW_CONTROL;
    this.polygonAttr = [];
    this.showContainer = false;
    this.zoomScale = "";
    this.geojson = { type: "FeatureCollection", features: [] };
    this.linestring = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: [],
      },
    };
    this.externalSystems = common.EXTERNAL_SYSTEMS;
    this.otherSystems = common.OTHER_SYSTEMS;
    this.projectSystems = common.PROJECT_SYSTEMS;
    this.outOfUseSystems = common.OUT_OF_USE_SYSTEMS;
    this.uvmSystems = common.UVM_SYSTEMS;

    this.showIDEditor =
      Number(process.env.VUE_APP_SHOW_IDEDITOR) === 1 ? true : false;
    this.showSuperset =
      Number(process.env.VUE_APP_SHOW_SUPERSET) === 1 ? true : false;
    this.showActiveDrawingByConfig =
      Number(process.env.VUE_APP_SHOW_ACTIVE_DRAWING) === 1 ? true : false;

    this.showExternalSystemsByConfig =
      Number(process.env.VUE_APP_SHOW_EXTERNAL_LAYERS) === 1 ? true : false;
    this.showOtherSystemsByConfig =
      Number(process.env.VUE_APP_SHOW_OTHER_LAYERS) === 1 ? true : false;
    this.showProjectSystemsByConfig =
      Number(process.env.VUE_APP_SHOW_PROJECT_LAYERS) === 1 ? true : false;
    this.showOutOfUseSystemsByConfig =
      Number(process.env.VUE_APP_SHOW_OUT_OF_USE_LAYERS) === 1 ? true : false;
    this.showUVMSystemsByConfig =
      Number(process.env.VUE_APP_SHOW_UVM_LAYERS) === 1 ? true : false;

    this.showByMobileDevice = !this.$store.getters.isMobile;
    this.coordinateSystems = common.COORDINATE_SYSTEMS;
    this.coordinateSystem =
      "+proj=lcc +lat_0=42.6678756833333 +lon_0=25.5 +lat_1=42 +lat_2=43.3333333333333 +x_0=500000 +y_0=4725824.3591 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs";
    this.predefinedData = [];
    this.filtersContainer = {};
    this.generalFilter = [];
    this.selectedWMSLayers = ['OSM'];
    this.mouseLngLat = [];
    this.touchLngLat = [];
  }

  mounted() {
    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN;
    this.map = new mapboxgl.Map(layer.MAP_INIT_LAYER);
    if (
      this.$store.getters.last_map_center &&
      this.$store.getters.last_map_zoom
    ) {
      this.map.setCenter(this.$store.getters.last_map_center as LngLatLike);
      this.map.setZoom(this.$store.getters.last_map_zoom as number);
    } else {
      this.map.setCenter(this.data.center as LngLatLike);
      this.map.setZoom(this.data.zoom as number);
    }

    // On map actions - on load
    this.map.on("load", () => {
      this.zoomScale = `${calcScale(
        this.map.getZoom(),
        this.map.getCenter().lat
      )}`;
    });

    // On map actions - on zoomstart
    this.map.on("zoomstart", () => {
      const zoomLevel = this.map.getZoom();
      if (zoomLevel >= 15) {
        this.showRedirectButton = true;
        this.showLabelLayer = true;
      } else {
        this.showRedirectButton = false;
        this.showLabelLayer = false;
      }
      this.zoomScale = `${calcScale(zoomLevel, this.map.getCenter().lat)}`;
      this.mapService.showCenterAndZoom(
        this.zoomScale,
        this.mouseLngLat,
        this.coordinateSystem
      );

      this.saveMapPosition();
    });

    this.map.on("touchmove", (e) => {
      const zoomLevel = this.map.getZoom();
      this.touchLngLat = e.lngLat;
      if (zoomLevel >= 15) {
        this.showLabelLayer = true;
      } else {
        this.showLabelLayer = false;
      }
      this.zoomScale = `${calcScale(zoomLevel, this.map.getCenter().lat)}`;
      this.mapService.showCenterAndZoom(this.zoomScale, this.touchLngLat, this.coordinateSystem);

      this.saveMapPosition();
    });


    this.map.on("dragend", () => {
      this.saveMapPosition();
    });

    this.map.on("mousemove", (e) => {
      this.mouseLngLat = e.lngLat;
      this.mapService.showCenterAndZoom(
        this.zoomScale,
        this.mouseLngLat,
        this.coordinateSystem
      );
    });

    const scale = new mapboxgl.ScaleControl({
      maxWidth: 100,
      unit: "metric",
    });
    this.map.addControl(scale);

    // Functional drawing buttons
    if (this.showActiveDrawingByConfig && this.showByMobileDevice) {
      this.map.addControl(this.mbdraw, "bottom-left");

      const ctrlGroupBtns = this.activedrawing.setDrawingControlGroup();
      ctrlGroupBtns.editbtn.onclick = this.zoneStartReshape;
      ctrlGroupBtns.editabtn.onclick = this.zoneStartEditAttr;
      ctrlGroupBtns.savebtn.onclick = this.zoneSaveEdits;
      ctrlGroupBtns.cancelbtn.onclick = this.zoneCancelEdits;
      this.activedrawing.noeditModeSetUI();

      // Events
      this.map.on("draw.create", this.newZone);
      this.map.on("draw.delete", this.delZone);
      this.map.on("draw.update", this.reshapeZone);
    }

    // Measure buttons
    this.map.addControl(tools.MEASURE_CONTROL, "bottom-left");
    const ctrlMeasureBtns = this.activedrawing.setMeasureControlGroup();
    this.map.on("click", this.measureStart);
    ctrlMeasureBtns.measurebtn.onclick = this.measureStartAction;
    ctrlMeasureBtns.cancelMeasurebtn.onclick = this.measureEndAction;

    // Add search control
    const geocoder_api = this.geocodeApi.getApiSource();
    const options = tools.GEOCODER_OPTIONS;
    options.accessToken = mapboxgl.accessToken;
    options.maplibregl = mapboxgl;
    const geocoderContainer = new MapboxGeocoder(geocoder_api, options);
    this.geocodeApi.setSearchGroup(geocoderContainer, this.map);

    // Add print control to the map
    if (this.showByMobileDevice) {
      this.map.addControl(tools.EXPORT_CONTROL, "top-right");
    }

    //Add geolocate control to the map.
    this.map.addControl(tools.GEOLOCATE_CONTROL);

    //Add compass
    this.map.addControl(tools.COMPASS_CONTROL);

    //Zone events
    this.map.on("click", layer.ZONES_POLYGON, this.zoneAttr);
    this.map.on("click", layer.ZONES_LINE, this.zoneAttr);
    this.map.on("click", layer.ZONES_POINT, this.zoneAttr);
    this.map.on("mousedown", this.rightClick);
    this.map.on("mousedown", this.mDownBox);
  }

  public getMapDefinitionSettings() {
    getMapSettings()
      .then((response) => {
        const data = response.data as any;

        //Populate local data
        this.addLocalData(data);
        //Populate map data
        this.addMapData();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  public addLocalData(data) {
    data.forEach((def) => {
      this.layerList.push({
        id: def.layer.id,
        name: def.layer.layerName,
        type: def.layer.layerType.name,
        minZoom: def.layer.minZoom,
        maxZoom: def.layer.maxZoom,
        sourceLayer: def.layer.sourceLayer,
        selectable: def.selectable,
      });
      this.layerSources[def.layer.id] = {
        sourceId: def.source.sourceName,
        sourceObj: JSON.parse(def.source.sourceObject),
      };
      this.layerStyles[def.layer.id] = {
        layout: JSON.parse(def.style.layout),
        paint: JSON.parse(def.style.paint),
      };
      if (def.filter) {
        this.layerFilters[def.layer.id] = {
          filter: JSON.parse(def.filter.condition),
          shortName: def.filter.shortName,
        };
      }
      if (def.selectable) {
        this.layerSelectableList.push({
          id: def.layer.id,
          name: def.layer.layerName,
        });
      }
    });
  }

  public addMapData() {
    //Sources
    Object.keys(this.layerSources).forEach((key) => {
      const source = this.layerSources[key];
      if (!this.map.getSource(source.sourceId)) {
        this.map.addSource(source.sourceId, source.sourceObj as any);
      }
    });

    // Layers
    this.layerList.forEach((l) => {
      const layerObj = {
        id: l.name,
        source: this.layerSources[l.id].sourceId,
        type: l.type,
        layout: this.layerStyles[l.id].layout,
        paint: this.layerStyles[l.id].paint,
        minzoom: l.minZoom ?? 1,
        maxzoom: l.maxZoom ?? 24,
      } as any;
      if (l.sourceLayer) {
        layerObj["source-layer"] = l.sourceLayer;
      }
      if (this.layerFilters[l.id]) {
        layerObj.filter = this.layerFilters[l.id].filter;
      }
      this.map.addLayer(layerObj);
    });

    this.loadSecondarySourceData();
    this.setSelectionLayers();
    this.setMapLayers()
  }

  public loadSecondarySourceData() {
    getPolygons().then((res) => {
      this.data.zones.features = res.data;
      const polygonSrc = this.map.getSource(source.ZONES) as GeoJSONSource;
      polygonSrc.setData({ type: "FeatureCollection", features: res.data });
    });
  }

  public loadNomenclatures() {
    getSystems().then((res) => {
      this.systems = res.data as any;
    });
    getObjects().then((res) => {
      this.objectTypes = res.data as any;
    });
    getNomenclatures().then((res) => {
      this.nomenclatureFilters = res.data as any;

      Object.keys(this.nomenclatureFilters).forEach((x) => {
        this.nomenclatureFilters[x]["selectedIds"] = this.nomenclatureFilters[
          x
        ].nomenclatureValues
          .filter((v) => v.show)
          .map((v) => v.id);
        this.nomenclatureFilters[x]["from"] = 0;
        this.nomenclatureFilters[x]["to"] = 0;
        this.nomenclatureFilters[x]["exploit_status"] = -1;
      });
    });
  }

  public loadConditionalFilters() {
    getConditionalFilters().then((res) => {
      (res.data as any).forEach((c) => {
        this.conditionalFilters[c.shortName] = c.condition;
      });
    });
  }

  public loadTagRefByLang() {
    getTagRefByLang().then((res) => {
      const data = res.data as any;
      data.forEach((x) => {
        this.tagRefs[x.code] = x.text;
      });
      this.mapTagRefs(this.tagRefs);
    });
  }

  public loadPredefinedDefinition() {
    getPredefinedDefinition().then((res) => {
      this.predefinedData = res.data as any;
    });
  }

  public setMapLayers() {
    let vstate;
    if (this.systems[0].show) {
      // Water
      vstate = this.objectTypes[0][0].show ? "visible" : "none"; // Lines
      this.map.setLayoutProperty(layer.WATERLINES, "visibility", vstate);
      vstate = this.objectTypes[0][1].show ? "visible" : "none"; // Points
      this.map.setLayoutProperty(layer.WATERPOINTS, "visibility", vstate);
      vstate = this.objectTypes[0][2].show ? "visible" : "none"; // Areal
      this.map.setLayoutProperty(layer.AREAL_POLY_WATER, "visibility", vstate);
      this.map.setLayoutProperty(layer.AREAL_LINE_WATER, "visibility", vstate);
      vstate = this.objectTypes[0][3].show ? "visible" : "none"; // Watermeters
      this.map.setLayoutProperty(
        layer.WATERMETERS_RESIDENTIAL,
        "visibility",
        vstate
      );
      vstate = this.objectTypes[0][4].show ? "visible" : "none"; // Watermeters
      this.map.setLayoutProperty(
        layer.WATERMETERS_PUBLIC,
        "visibility",
        vstate
      );
    } else {
      this.map.setLayoutProperty(layer.WATERLINES, "visibility", "none");
      this.map.setLayoutProperty(layer.WATERPOINTS, "visibility", "none");
      this.map.setLayoutProperty(layer.AREAL_POLY_WATER, "visibility", "none");
      this.map.setLayoutProperty(layer.AREAL_LINE_WATER, "visibility", "none");
      this.map.setLayoutProperty(
        layer.WATERMETERS_RESIDENTIAL,
        "visibility",
        "none"
      );
      this.map.setLayoutProperty(
        layer.WATERMETERS_PUBLIC,
        "visibility",
        "none"
      );
    }

    if (this.systems[1].show) {
      // Canal
      vstate = this.objectTypes[1][0].show ? "visible" : "none"; // Lines
      this.map.setLayoutProperty(layer.CANALLINES, "visibility", vstate);
      vstate = this.objectTypes[1][1].show ? "visible" : "none"; // Points
      this.map.setLayoutProperty(layer.CANALPOINTS, "visibility", vstate);
      vstate = this.objectTypes[1][2].show ? "visible" : "none"; // Areal
      this.map.setLayoutProperty(layer.AREAL_POLY_CANAL, "visibility", vstate);
      this.map.setLayoutProperty(layer.AREAL_LINE_CANAL, "visibility", vstate);
    } else {
      this.map.setLayoutProperty(layer.CANALLINES, "visibility", "none");
      this.map.setLayoutProperty(layer.CANALPOINTS, "visibility", "none");
      this.map.setLayoutProperty(layer.AREAL_POLY_CANAL, "visibility", "none");
      this.map.setLayoutProperty(layer.AREAL_LINE_CANAL, "visibility", "none");
    }

    this.symbolLayer.setLayersByParams(
      this.map,
      this.systems,
      this.objectTypes,
      this.showLabelLayer
    );
    this.clearSel();
  }

  public setMapExternalLayers() {
    let vstate;

    //TODO make foreach
    vstate = this.externalSystems[0].show ? "visible" : "none";
    this.map.setLayoutProperty(layer.ELECTRICLINES, "visibility", vstate);
    vstate = this.externalSystems[1].show ? "visible" : "none";
    this.map.setLayoutProperty(layer.GASLINES, "visibility", vstate);
    vstate = this.externalSystems[2].show ? "visible" : "none";
    this.map.setLayoutProperty(layer.COMMUNICATIONLINES, "visibility", vstate);
    vstate = this.externalSystems[3].show ? "visible" : "none";
    this.map.setLayoutProperty(layer.WDETAILPOINTS, "visibility", vstate);
    this.map.setLayoutProperty(layer.WDETAILPOINTS_LABEL, "visibility", vstate);
  }

  public setMapOtherLayers() {
    let vstate;
    vstate = this.otherSystems[0].show ? "visible" : "none";
    this.map.setLayoutProperty(layer.SIGNALLINES, "visibility", vstate);
    vstate = this.otherSystems[1].show ? "visible" : "none";
    this.map.setLayoutProperty(layer.POWERLINES, "visibility", vstate);
    vstate = this.otherSystems[2].show ? "visible" : "none";
    this.map.setLayoutProperty(layer.FENCELINES, "visibility", vstate);
    vstate = this.otherSystems[3].show ? "visible" : "none";
    this.map.setLayoutProperty(layer.OTHERLINES, "visibility", vstate);
    vstate = this.otherSystems[4].show ? "visible" : "none";
    this.map.setLayoutProperty(layer.OTHERPOINTS, "visibility", vstate);
    this.map.setLayoutProperty(layer.OTHERPOINTS_LABEL, "visibility", vstate);
    vstate = this.otherSystems[5].show ? "visible" : "none";
    this.map.setLayoutProperty(layer.OTHERAREAL, "visibility", vstate);
  }

  public setMapProjectLayers() {
    let vstate;
    vstate = this.projectSystems[0][0].show ? "visible" : "none";
    this.map.setLayoutProperty(layer.PROJECT_LINE_WATER, "visibility", vstate);
    vstate = this.projectSystems[0][1].show ? "visible" : "none";
    this.map.setLayoutProperty(layer.PROJECT_POINT_WATER, "visibility", vstate);
    vstate = this.projectSystems[0][2].show ? "visible" : "none";
    this.map.setLayoutProperty(
      layer.PROJECT_POLYGON_WATER,
      "visibility",
      vstate
    );
    vstate = this.projectSystems[1][0].show ? "visible" : "none";
    this.map.setLayoutProperty(layer.PROJECT_LINE_CANAL, "visibility", vstate);
    vstate = this.projectSystems[1][1].show ? "visible" : "none";
    this.map.setLayoutProperty(layer.PROJECT_POINT_CANAL, "visibility", vstate);
    vstate = this.projectSystems[1][2].show ? "visible" : "none";
    this.map.setLayoutProperty(
      layer.PROJECT_POLYGON_CANAL,
      "visibility",
      vstate
    );
  }

  public setMapOutOfUseLayers() {
    let vstate;
    vstate = this.outOfUseSystems[0][0].show ? "visible" : "none";
    this.map.setLayoutProperty(
      layer.OUTOFSERVICE_WATERLINES,
      "visibility",
      vstate
    );
    vstate = this.outOfUseSystems[0][1].show ? "visible" : "none";
    this.map.setLayoutProperty(
      layer.OUTOFSERVICE_WATERPOINTS,
      "visibility",
      vstate
    );
    this.map.setLayoutProperty(
      layer.OUTOFSERVICE_WATERPOINTS_LABEL,
      "visibility",
      vstate
    );
    vstate = this.outOfUseSystems[1][0].show ? "visible" : "none";
    this.map.setLayoutProperty(
      layer.OUTOFSERVICE_CANALLINES,
      "visibility",
      vstate
    );
    vstate = this.outOfUseSystems[1][1].show ? "visible" : "none";
    this.map.setLayoutProperty(
      layer.OUTOFSERVICE_CANALPOINTS,
      "visibility",
      vstate
    );
    this.map.setLayoutProperty(
      layer.OUTOFSERVICE_CANALPOINTS_LABEL,
      "visibility",
      vstate
    );
  }

  public setMapUVMLayers() {
    let vstate;
    vstate = this.uvmSystems[0].show ? "visible" : "none";
    this.map.setLayoutProperty(
      layer.SANITARY_SECURITY_ZONES,
      "visibility",
      vstate
    );
    this.map.setLayoutProperty(
      layer.SANITARY_SECURITY_ZONES_LINE,
      "visibility",
      vstate
    );
    vstate = this.uvmSystems[1].show ? "visible" : "none";
    this.map.setLayoutProperty(layer.PER_ZONES, "visibility", vstate);
  }

  public filterChanged(item) {
    this.generalFilter = ["all"];

    const lname = this.layerList.find((x) => x.id === item.layerId)
      ?.name as string;

    let expr: any = this.getExpression(item);

    if (!this.filtersContainer[lname]) {
      this.filtersContainer[lname] = {};
    }
    this.filtersContainer[lname][item.nomType.code] = expr;

    Object.keys(this.filtersContainer[lname]).forEach((value) => {
      if (this.filtersContainer[lname][value]) {
        this.generalFilter.push(this.filtersContainer[lname][value]);
      }
    });

    if (this.generalFilter.length <= 2 && !this.generalFilter[1]) {
      this.generalFilter = null;
    }

    this.map.setFilter(lname, this.generalFilter);
    this.map.setFilter(`${lname}-label`, this.generalFilter);

    if (item.objectType.id === 2 && item.systemType.id < 2) {
      this.map.setFilter(`${lname}-line`, this.generalFilter);
    }
  }

  public getExpression(item) {
    let expr: string | null = "";
    let exprlist: string[] = [];

    if (item.nomType.type.includes("text")) {
      item.nomenclatureValues.forEach((e) => {
        e.show = item.selectedIds.includes(e.id) ? true : false;
        if (!e.show) {
          expr = this.conditionalFilters["is_equal"];
          expr = expr!
            .replaceAll("${1}", item.nomType.code)
            .replace("${2}", e.value);
          exprlist.push(expr);
        }
      });

      if (exprlist.length === 0) {
        return null;
      } else if (exprlist.length === 1) {
        return ["!"].concat(exprlist.map((x) => JSON.parse(x)));
      } else {
        return ["!", ["any"].concat(exprlist.map((x) => JSON.parse(x)))];
      }
    } else if (item.nomType.type === "number" || item.nomType.type === "date") {
      if (!item.from || !item.to) return null;

      expr = `["all", ${this.conditionalFilters["is_bigger"]},${this.conditionalFilters["is_smaller"]}]`;
      expr = expr
        .replaceAll("${1}", item.nomType.code)
        .replace("${2}", item.from)
        .replace("${3}", item.to);

      return JSON.parse(expr);
    } else if (item.nomType.type === "radio") {
      if (item.exploit_status === 0) {
        //in exploit
        expr = this.conditionalFilters["is_equal"];
        expr = expr!
          .replaceAll("${1}", item.nomType.code)
          .replace("${2}", "true");
        exprlist.push(expr);
      } else if (item.exploit_status === 1) {
        //out of exploit
        expr = this.conditionalFilters["is_equal"];
        expr = expr!
          .replaceAll("${1}", item.nomType.code)
          .replace("${2}", "false");
        exprlist.push(expr);
      } else if (item.exploit_status === -1) {
        //all
        return null;
      }
      return ["!"].concat(exprlist.map((x) => JSON.parse(x)));
    }
  }

  public hideDrawnLayer() {
    this.map.setLayoutProperty(layer.ZONES_LINE, "visibility", "none");
    this.map.setLayoutProperty(layer.ZONES_POINT, "visibility", "none");
    this.map.setLayoutProperty(layer.ZONES_POLYGON, "visibility", "none");
    this.map.setLayoutProperty(layer.ZONES_LINE_LABEL, "visibility", "none");
    this.map.setLayoutProperty(layer.ZONES_POINT_LABEL, "visibility", "none");
    this.map.setLayoutProperty(layer.ZONES_POLYGON_LABEL, "visibility", "none");
  }

  public showDrawnLayer() {
    this.map.setLayoutProperty(layer.ZONES_LINE, "visibility", "visible");
    this.map.setLayoutProperty(layer.ZONES_POINT, "visibility", "visible");
    this.map.setLayoutProperty(layer.ZONES_POLYGON, "visibility", "visible");
    this.map.setLayoutProperty(layer.ZONES_LINE_LABEL, "visibility", "visible");
    this.map.setLayoutProperty(
      layer.ZONES_POINT_LABEL,
      "visibility",
      "visible"
    );
    this.map.setLayoutProperty(
      layer.ZONES_POLYGON_LABEL,
      "visibility",
      "visible"
    );
  }

  public newZone(e) {
    const id = e.features[0].id;
    this.mbdraw.setFeatureProperty(id, "id", id);
    this.mbdraw.setFeatureProperty(id, "update", ["new"]);
  }

  public delZone(e) {
    const ftrpropid = e.features[0].properties.osm_id;
    this.data.deletedZones.push(ftrpropid);
  }

  public reshapeZone(e) {
    const ftr = e.features[0];
    const id = e.features[0].id;
    const upd = ftr.properties.update;
    if (upd) {
      if (upd.indexOf("reshape") === -1) {
        upd.push("reshape");
        this.mbdraw.setFeatureProperty(id, "update", upd);
      }
    } else {
      this.mbdraw.setFeatureProperty(id, "update", ["reshape"]);
    }
  }

  public zoneStartReshape() {
    //Hide symbol layers
    this.symbolLayer.hideAll(this.map);
    //Clear selected assets
    this.clearSel();

    this.mbdraw.set(this.data.zones);
    this.setZonesLayoutProperty("none");
    this.data.editZoneGeom = true;
    this.activedrawing.reshapeModeSetUI();
  }

  public zoneStartEditAttr() {
    //Hide symbol layers
    this.symbolLayer.hideAll(this.map);
    //Clear selected assets
    this.clearSel();

    this.data.editZoneAttr = true;
    this.mapZoneAttrMode();

    this.activedrawing.attributeModeSetUI();
  }

  public zoneSaveEdits() {
    if (this.data.editZoneGeom) {
      this.data.zones = this.mbdraw.getAll();
      this.data.editZoneGeom = false;
      (this.map.getSource(source.ZONES) as GeoJSONSource).setData(
        this.data.zones
      );
      this.mbdraw.deleteAll();
      this.setZonesLayoutProperty("visible");
    }
    this.data.editZoneAttr = false;
    this.mapZoneAttrMode();

    this.activedrawing.noeditModeSetUI();
    this.data.zones.features.forEach((z) => {
      if (!z.properties!.update) {
        z.properties!.update = [];
      }
    });
    const output = { deleted: this.data.deletedZones, zones: this.data.zones };

    savePolygon(output).then((res) => {
      this.polygonAttr = [];
      this.data.zones.features.forEach((z) => {
        z.properties!.update = [];
      });
      getPolygons().then((res) => {
        this.data.zones.features = res.data;
        const polygonSrc = this.map.getSource(source.ZONES) as GeoJSONSource;
        polygonSrc.setData({
          type: "FeatureCollection",
          features: res.data,
        });
        this.mapZoneAttrs();
        this.symbolLayer.showAll(this.map);
      });
    });
  }

  public zoneCancelEdits() {
    this.mbdraw.deleteAll();
    this.data.editZoneGeom = false;
    this.data.editZoneAttr = false;

    this.setZonesLayoutProperty("visible");
    this.activedrawing.noeditModeSetUI();
    this.polygonAttr = [];

    this.mapZoneAttrs();
    this.mapZoneAttrMode();
    this.symbolLayer.showAll(this.map);
  }

  public measureStart(e) {
    const measureBtn = document.getElementById("draw_toolbar_measure");

    if (measureBtn?.classList.contains("hidebtn")) {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: [layer.MEASURE_POINTS],
      });

      // Remove the linestring from the group
      // So we can redraw it based on the points collection
      if (this.geojson.features.length > 1) this.geojson.features.pop();

      // Clear the Distance container to populate it with a new value
      let distanceContainer = document.getElementById("distance");
      distanceContainer!.innerHTML = "";

      // If a feature was clicked, remove it from the map
      if (features.length) {
        const id = features[0].properties.id;
        this.geojson.features = this.geojson.features.filter(function (point) {
          return point.properties.id !== id;
        });
      } else {
        var point = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [e.lngLat.lng, e.lngLat.lat],
          },
          properties: {
            id: String(new Date().getTime()),
          },
        };

        this.geojson.features.push(point);
      }

      if (this.geojson.features.length > 1) {
        this.linestring.geometry.coordinates = this.geojson.features.map(
          function (point) {
            return point.geometry.coordinates;
          }
        );
        this.geojson.features.push(this.linestring);
        // Populate the distanceContainer with total distance
        let value = document.createElement("pre");
        value.textContent =
          this.$t("total_length") +
          (turf.length(this.linestring) * 1000).toFixed(2).toLocaleString() +
          this.$t("unit_of_measure_meters");
        distanceContainer!.appendChild(value);
      }

      const measureSrc = this.map.getSource(source.MEASURE) as GeoJSONSource;
      measureSrc.setData(this.geojson);
    }
  }

  public measureStartAction(e) {
    this.measureMode = true;
    this.activedrawing.measureModeSetUI();
    this.disableSelectionLayers();
    this.map.getCanvas().style.cursor = "crosshair";
  }

  public measureEndAction(e) {
    this.measureMode = false;
    this.activedrawing.noMeasureModeSetUI();
    this.geojson = {
      type: "FeatureCollection",
      features: [],
    };
    this.linestring = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: [],
      },
    };

    let distanceContainer = document.getElementById("distance");
    distanceContainer!.innerHTML = "";

    const measureSrc = this.map.getSource(source.MEASURE) as GeoJSONSource;
    measureSrc.setData(this.geojson);
    this.map.getCanvas().style.cursor = "";

    this.setSelectionLayers();
  }

  public setZonesLayoutProperty(value) {
    this.map.setLayoutProperty(layer.ZONES_POLYGON, "visibility", value);
    this.map.setLayoutProperty(layer.ZONES_LINE, "visibility", value);
    this.map.setLayoutProperty(layer.ZONES_POINT, "visibility", value);
  }

  public generateURL(urlParams) {
    const bounds = this.map.getBounds().getCenter();
    const link = document.createElement("a");

    link.href = `${process.env.VUE_APP_ID_EDITOR_URL
      }#background=OSM${urlParams}&map=${this.map.getZoom() + 1.2}/${bounds.lat
      }/${bounds.lng}`;

    console.log(link.href);

    link.target = "_blank";
    link.click();
  }

  public addMapWMSLayers() {
    getMapWMSLayers().then((res) => {
      //Manualy add main osm layer
      this.data.wsmlayers.push({
        id: "OSM",
        text: this.$t("main_map"),
        show: true,
        zoomExtent: 0,
        selected: true,
      });

      res.data.forEach((x) => {
        // Populate radio button component
        this.data.wsmlayers.push({
          id: x.secondary_id,
          text: x.name,
          show: x.show,
          zoomExtent: x.zoom_extent,
          selected: false,
        });

        //Add provided WMS layers to the map
        this.map.addSource(x.secondary_id, {
          type: x.type,
          tiles: [x.url],
          tileSize: 256,
        });
        this.map.addLayer({
          id: x.secondary_id,
          type: x.type,
          source: x.secondary_id,
          metadata: {},
          paint: {},
          layout: {
            visibility: "none",
          },
        });
      });
    });
  }

  public wmsLayerChanged(selectedLayers) {
    this.selectedWMSLayers = selectedLayers;
    this.data.wsmlayers.forEach((x, i) => {
      const hasLayer = this.map.getLayer(x.id);
      if (hasLayer) {
        if (selectedLayers.includes(x.id)) {
          this.map.setLayoutProperty(x.id, "visibility", "visible");
          this.data.wsmlayers[i]["selected"] = true;
        }
        else {
          this.map.setLayoutProperty(x.id, "visibility", "none");
          this.data.wsmlayers[i]["selected"] = false;
        }
      }
      else if (!hasLayer && x.id === 'OSM') {
        const baseMapLayers = this.map.getStyle().layers.filter(x => x.source === 'composite');
        if (selectedLayers.includes(x.id)) {
          baseMapLayers.forEach(l => {
            this.map.setLayoutProperty(l.id, "visibility", "visible");
          });
          this.data.wsmlayers[i]["selected"] = true;
        }
        else {
          baseMapLayers.forEach(l => {
            this.map.setLayoutProperty(l.id, "visibility", "none");
          });
          this.data.wsmlayers[i]["selected"] = false;
        }
      }
    });
  }

  public zoneAttr(e) {
    if (this.data.editZoneAttr) {
      const props = {
        properties: e.features[0].properties,
        layer: e.features[0].layer.id,
      };

      const foundObj: any = this.polygonAttr.find(
        (x) => x.properties.osm_id === e.features[0].properties.osm_id
      );

      if (!foundObj) {
        this.polygonAttr.push(props);

        this.mapZoneAttrs();
      }
    }
  }

  public setZoneAttributes(id, attrvalue) {
    for (let i = 0; i < this.data.zones.features.length; i++) {
      const props = this.data.zones.features[i].properties;
      if (props && props.osm_id && props.osm_id === id) {
        Object.keys(attrvalue).forEach((key) => (props[key] = attrvalue[key]));
        if (props.update) {
          if (props.update.indexOf("attr") === -1) props.update.push("attr");
        } else {
          props.update = ["attr"];
        }
        (this.map.getSource(source.ZONES) as GeoJSONSource).setData(
          this.data.zones
        );
      }
    }
  }

  public styleLineColorChanged(obj, toRemove) {
    if (toRemove) {
      this.removeSetting(obj);
    } else {
      this.setColors(obj);
    }
  }

  public setColors(obj) {
    const layer = this.layerList.find((x) => x.id === obj.layerId);
    const paint = this.map
      .getStyle()
      .layers.find((x) => x.id === layer?.name)?.paint;
    const styleName = paint?.["line-color"] ? "line-color" : "circle-color";
    let styleArr = paint?.[styleName] as any[];

    if (styleArr) {
      const caseSection = styleArr.splice(0, 5);
      const defaultColor = styleArr.pop();

      if (obj.nomType.type === "text") {
        this.setColorsForTextType(obj, styleArr);
      } else if (obj.nomType.type === "number" || obj.nomType.type === "date") {
        this.setColorsForNumericType(obj, styleArr);
      }

      styleArr.unshift(...caseSection);
      styleArr.push(defaultColor);
      this.map.setPaintProperty(layer?.name as string, styleName, styleArr);
      this.map.setPaintProperty(
        `${layer?.name}-label` as string,
        "text-color",
        styleArr
      );
    }
  }

  public setColorsForTextType(obj, styleArr) {
    obj.nomenclatureValues.forEach((v) => {
      if (v.color) {
        let exist = false;
        for (let i = 0; i < styleArr.length; i += 2) {
          if (styleArr[i][2] == v.value) {
            styleArr[i + 1] = v.color;
            exist = true;
          }
        }
        if (!exist) {
          styleArr.unshift(v.color);
          styleArr.unshift(["==", ["get", `${obj.code}`], `${v.value}`]);
        }
      }
    });
  }

  public setColorsForNumericType(obj, styleArr) {
    let exist = false;
    for (let i = 0; i < styleArr.length; i += 2) {
      if (styleArr[i][1][2] == obj.from && styleArr[i][2][2] == obj.to) {
        styleArr[i + 1] = obj.color;
        exist = true;
      }
    }
    if (!exist) {
      let expr = `["all", ${this.conditionalFilters["is_bigger"]},${this.conditionalFilters["is_smaller"]}]`;
      expr = expr
        .replaceAll("${1}", obj.nomType.code)
        .replace("${2}", obj.from)
        .replace("${3}", obj.to);

      styleArr.unshift(obj.color);
      styleArr.unshift(JSON.parse(expr));
    }
  }

  public removeSetting(obj) {
    const layer = this.layerList.find((x) => x.id === obj.layerId);
    const paint = this.map
      .getStyle()
      .layers.find((x) => x.id === layer?.name)?.paint;
    const styleName = paint?.["line-color"] ? "line-color" : "circle-color";
    let styleArr = paint?.[styleName] as any[];
    if (obj.type === "text") {
      for (let i = 5; i < styleArr.length - 2; i += 2) {
        if (styleArr[i][2] === obj.value) {
          const originalStyle =
            this.layerStyles[obj.layerId].paint?.[styleName];
          let matched = false;
          let matchedAt = 0;
          for (let y = 5; y < originalStyle.length - 2; y += 2) {
            if (originalStyle[y][2] === obj.value) {
              matched = true;
              matchedAt = y;
              break;
            }
          }
          if (matched) {
            styleArr[i] = originalStyle[matchedAt];
            styleArr[i + 1] = originalStyle[matchedAt + 1];
          } else {
            styleArr.splice(i, 2);
          }
          break;
        }
      }
    } else if (obj.type === "number" || obj.type === "date") {
      const from = Number(obj.value.split("-")[0]);
      const to = Number(obj.value.split("-")[1]);
      for (let i = 5; i < styleArr.length - 1; i += 2) {
        if (styleArr[i][1][2] === from && styleArr[i][2][2] === to) {
          styleArr.splice(i, 2);
          break;
        }
      }
    }
    this.map.setPaintProperty(layer?.name as string, styleName, styleArr);
    this.map.setPaintProperty(
      `${layer?.name}-label` as string,
      "text-color",
      styleArr
    );
  }

  public setSelectionLayers() {
    this.layerSelectableList.forEach((l) => {
      this.map.on("mouseenter", l.name, this.mEnter);
      this.map.on("mouseleave", l.name, this.mLeave);
      this.map.on("mousedown", l.name, this.mDown);
    });
  }

  public disableSelectionLayers() {
    this.layerSelectableList.forEach((l) => {
      this.map.off("mouseenter", l.name, this.mEnter);
      this.map.off("mouseleave", l.name, this.mLeave);
      this.map.off("mousedown", l.name, this.mDown);
    });
  }

  public addFtrToSel(ftr) {
    if (!this.data.editZoneGeom && !this.data.editZoneAttr) {
      const idx = this.data.selectionList.indexOf(ftr.id);
      if (idx === -1) {
        this.data.selectionList.push(ftr.id);
        this.data.selectionData.push({
          props: ftr.properties,
          src: ftr.source,
          geom: ftr.geometry,
          sourceLayer: ftr.sourceLayer,
        });
        this.map.setFeatureState(
          { source: ftr.source, id: ftr.id, sourceLayer: ftr.sourceLayer },
          { selected: true }
        );
      }
      this.populateSelectedDataToLayers();
    }
  }

  public addFtrToSelToggle(ftr) {
    const idx = this.data.selectionList.indexOf(ftr.id);
    if (idx === -1) {
      this.data.selectionList.push(ftr.id);
      this.data.selectionData.push({
        props: ftr.properties,
        src: ftr.source,
        geom: ftr.geometry,
        sourceLayer: ftr.sourceLayer,
      });
      this.map.setFeatureState(
        {
          source: ftr.source,
          id: ftr.id,
          sourceLayer: ftr.sourceLayer,
        },
        { selected: true }
      );
    } else {
      this.data.selectionList.splice(idx, 1);
      this.data.selectionData.splice(idx, 1);
      this.map.setFeatureState(
        {
          source: ftr.source,
          id: ftr.id,
          sourceLayer: ftr.sourceLayer,
        },
        { selected: false }
      );
    }
    this.populateSelectedDataToLayers();
  }

  public populateSelectedDataToLayers() {
    Object.keys(this.layerList).forEach((l) => {
      const layer = this.layerList[l];
      if (!layer.selectable && layer.name.toLowerCase().includes("select")) {
        this.map.setFilter(layer.name, [
          "in",
          "osm_id",
          ...this.data.selectionList,
        ] as any);
      }
    });
  }

  public clearSel() {
    this.data.selectionList.forEach((id, index) => {
      this.map.setFeatureState(
        {
          source: this.data.selectionData[index]["src"],
          id: id,
          sourceLayer: this.data.selectionData[index].sourceLayer,
        },
        { selected: false }
      );
    });
    this.data.selectionList = [];
    this.data.selectionData = [];
  }

  public resetSelBox() {
    this.map.dragPan.enable();
    this.map.boxZoom.enable();
    this.data.windowSelection.start = null;
  }

  public mEnter(e) {
    this.map.getCanvas().style.cursor = "pointer";
    if (e.features && e.features.length > 0) {
      const el = e.features[0];
      if (this.data.highlightedId > -1 && this.data.highlightedSrc !== "") {
        this.map.setFeatureState(
          {
            source: this.data.highlightedSrc,
            id: this.data.highlightedId,
            sourceLayer: this.data.highlightedSrcLayer,
          },
          { highlighted: false }
        );
      }
      this.data.highlightedId = el.id;
      this.data.highlightedSrc = el.source;
      this.data.highlightedSrcLayer = el.sourceLayer;
      this.map.setFeatureState(
        { source: el.source, id: el.id, sourceLayer: el.sourceLayer },
        { highlighted: true }
      );
    }
  }

  public mLeave() {
    this.map.getCanvas().style.cursor = "";
    if (this.data.highlightedId > -1) {
      this.map.setFeatureState(
        {
          source: this.data.highlightedSrc,
          id: this.data.highlightedId,
          sourceLayer: this.data.highlightedSrcLayer,
        },
        { highlighted: false }
      );
    }
    this.data.highlightedId = -1;
    this.data.highlightedSrc = "";
    this.data.highlightedSrcLayer = "";
  }

  public mDown(e) {
    if (e.features.length > 0) {
      if (e.originalEvent.shiftKey) {
        this.addFtrToSelToggle(e.features[0]);
      } else {
        this.clearSel();
        this.addFtrToSel(e.features[0]);
      }
      this.$emit("Selection Changed", this.data.selectionData);
    }
  }

  public rightClick(e) {
    if (e.originalEvent.button === 2) {
      this.clearSel();
    }
  }

  public mDownBox(e) {
    if (e.originalEvent.shiftKey) {
      this.data.isDragPan = this.map.dragPan.isEnabled();
      this.data.isBoxZoom = this.map.boxZoom.isEnabled();
      this.map.dragPan.disable();
      this.map.boxZoom.disable();
      this.data.windowSelection.start = e.lngLat;
      this.data.windowSelection.startX = e.point.x;
      this.data.windowSelection.startY = e.point.y;
      this.map.on("mouseup", this.mUpBox);
    }
  }

  public mUpBox(e) {
    if (this.data.windowSelection.start) {
      if (this.data.windowSelection.active) {
        if (
          Math.abs(e.point.x - this.data.windowSelection.startX) > 5 ||
          Math.abs(e.point.x - this.data.windowSelection.startX) > 5
        ) {
          const minlng = Math.min(
            this.data.windowSelection.start.lng,
            e.lngLat.lng
          );
          const minlat = Math.min(
            this.data.windowSelection.start.lat,
            e.lngLat.lat
          );
          const maxlng = Math.max(
            this.data.windowSelection.start.lng,
            e.lngLat.lng
          );
          const maxlat = Math.max(
            this.data.windowSelection.start.lat,
            e.lngLat.lat
          );
          if (this.data.boxMode === "select") {
            const ll = this.map.project([minlng, minlat]);
            const ur = this.map.project([maxlng, maxlat]);

            const features = this.map.queryRenderedFeatures(
              [
                [ll.x, ll.y],
                [ur.x, ur.y],
              ],
              {
                layers: this.layerSelectableList.map((x) => x.name),
              }
            );
            features.forEach((ftr) => {
              this.addFtrToSel(ftr);
            });
          } else if (this.data.boxMode === "export") {
            this.data.boxMode = "select";

            const child = this.$refs.TMapExport as typeof TMapExport;
            child.exportBoundingBox(minlat, minlng, maxlat, maxlng);
          }
        }
      }
      this.resetSelBox();
      this.map.off("mouseup", this.mUpBox);
    }
  }

  public scaleChanged(newScale) {
    const newMapZoom = calcZoom(newScale, this.map.getCenter().lat);
    this.map.zoomTo(newMapZoom, { duration: 1000, offset: [0, 0] });
    this.zoomScale = newScale;
  }

  public visibilityChanged() {
    this.showContainer = false;
  }

  public symbolChanged(items, selected) {
    const currentSystemLayer = this.layerList.find(
      (x) => x.id === items[0].layerId
    );
    const labelLayerName = `${currentSystemLayer.name}-label`;
    const labelLayerObj = this.layerList.find((x) => x.name === labelLayerName);

    let layoutProp = this.layerStyles[labelLayerObj.id]["layout"]["text-field"];

    layoutProp[4][2].splice(1);
    if (layoutProp[6]) {
      layoutProp[6][2].splice(1);
    }

    selected.forEach((x) => {
      const tag = items.find((i) => i.id === x).code;
      layoutProp[4][2].push(["get", tag]);
      layoutProp[4][2].push(" ");
      if (layoutProp[6]) {
        layoutProp[6][2].push(["get", tag]);
        layoutProp[6][2].push(" ");
      }
    });

    this.map.setLayoutProperty(labelLayerObj.name, "text-field", layoutProp);
  }

  public sizeWaterLineChanged(arr) {
    let currentLayer = this.layerList.find((x) => x.name === layer.WATERLINES);
    this.sizeChanged(arr, currentLayer);
  }

  public sizeCanalLineChanged(arr) {
    let currentLayer = this.layerList.find((x) => x.name === layer.CANALLINES);
    this.sizeChanged(arr, currentLayer);
  }

  public sizeChanged(arr, layer) {
    let layoutProp: any = this.layerStyles[layer.id]["paint"]["line-width"];
    layoutProp = ["case"];

    let expr = "";
    arr.forEach((r) => {
      expr = `["all", ${this.conditionalFilters["is_bigger"]},${this.conditionalFilters["is_smaller"]}]`;
      expr = expr
        .replaceAll("${1}", "aq_diameter")
        .replace("${2}", r.from)
        .replace("${3}", r.to);

      layoutProp.push(JSON.parse(expr));
      layoutProp.push(Number(r.size));
    });

    layoutProp.push(2);
    this.map.setPaintProperty(layer.name, "line-width", layoutProp);
  }

  onChangeCoordinatesProjection(system) {
    this.coordinateSystem = system.key;
    this.map.setProjection(system.projection);
  }

  resetWaterWidth() {
    let currentLayer = this.layerList.find((x) => x.name === layer.WATERLINES);
    this.resetStyle(currentLayer);
  }

  resetCanalWidth() {
    let currentLayer = this.layerList.find((x) => x.name === layer.CANALLINES);
    this.resetStyle(currentLayer);
  }

  resetStyle(layer) {
    let layoutProp: any = this.layerStyles[layer.id]["paint"]["line-width"];
    this.map.setPaintProperty(layer.name, "line-width", layoutProp);
  }

  saveCustomThematic(description, isSystem) {
    loadingShow();
    const data = {
      bbox: JSON.stringify(this.map.getBounds()),
      systemJson: JSON.stringify(this.systems),
      objectJson: JSON.stringify(this.objectTypes),
      mapJson: JSON.stringify(this.map.getStyle().layers),
      nomFiltersJson: JSON.stringify(this.nomenclatureFilters),
      wmsJson: JSON.stringify(this.selectedWMSLayers),
      selectionJson: JSON.stringify(this.data.selectionData),
      description: description,
      isSystem: isSystem,
      otherJson: JSON.stringify({
        external: this.externalSystems,
        other: this.otherSystems,
        project: this.projectSystems,
        outOfUse: this.outOfUseSystems,
        uvm: this.uvmSystems,
      }),
      styleJson: JSON.stringify({
        color_data: this.$store.getters.color_data,
        label_data: this.$store.getters.label_data,
        diameter_data: this.$store.getters.diameter_data,
      }),
    };

    saveCustomThematicSetup(data)
      .then(() => {
        this.loadPredefinedDefinition();
      })
      .finally(() => {
        loadingHide();
      });
  }

  deleteCustomTheme(item) {
    loadingShow();

    const data = { Id: item.id };
    deleteCustomThematicSetup(data)
      .then(() => {
        this.loadPredefinedDefinition();
      })
      .finally(() => {
        loadingHide();
      });
  }

  applyCustomTheme(item) {
    //Set main systems with objects
    const system = JSON.parse(item.systemJson);
    const object = JSON.parse(item.objectJson);
    this.systems = system;
    this.objectTypes = object;
    this.setMapLayers();

    //Set all nomenclature filters
    const nomFilters = JSON.parse(item.nomFiltersJson);
    this.nomenclatureFilters = nomFilters;
    Object.keys(this.nomenclatureFilters).forEach((key) => {
      this.filterChanged(this.nomenclatureFilters[key]);
    });

    //Set map stilization - paint and layout properties
    const mapLayers = JSON.parse(item.mapJson);
    mapLayers.forEach((l) => {
      if (this.map.getLayer(l.id)) {
        if (l.layout) {
          Object.keys(l.layout).forEach((prop) => {
            this.map.setLayoutProperty(l.id, prop, l.layout[prop]);
          });
        }
        if (l.paint) {
          Object.keys(l.paint).forEach((prop) => {
            this.map.setPaintProperty(l.id, prop, l.paint[prop]);
          });
        }
      }
    });

    //Set WMS layers applied to the map
    const wmsLayers = JSON.parse(item.wmsJson);
    this.wmsLayerChanged(wmsLayers);

    //Set selected entities
    const selection = JSON.parse(item.selectionJson);
    this.data.selectionList = selection.map((x) => x.props.osm_id);
    this.data.selectionData = selection;
    selection.forEach((x) => {
      this.map.setFeatureState(
        {
          source: x.src,
          id: x.props.osm_id,
          sourceLayer: x.sourceLayer,
        },
        { selected: true }
      );
    });

    //Set other systems
    const otherSystems = JSON.parse(item.otherJson);

    if (this.showExternalSystemsByConfig) {
      this.externalSystems = otherSystems.external;
      this.setMapExternalLayers();
    }
    if (this.showOtherSystemsByConfig) {
      this.otherSystems = otherSystems.other;
      this.setMapOtherLayers();
    }
    if (this.showProjectSystemsByConfig) {
      this.projectSystems = otherSystems.project;
      this.setMapProjectLayers();
    }
    if (this.showOutOfUseSystemsByConfig) {
      this.outOfUseSystems = otherSystems.outOfUse;
      this.setMapOutOfUseLayers();
    }
    if (this.showUVMSystemsByConfig) {
      this.uvmSystems = otherSystems.uvm;
      this.setMapUVMLayers();
    }

    //Set styles - user experience
    const styleJson = JSON.parse(item.styleJson);
    this.$store.dispatch("map/changeReverseColorData", styleJson.color_data);
    this.$store.dispatch("map/changeReverseLabelData", styleJson.label_data);
    this.$store.dispatch("map/changeReverseDiameterData", styleJson.diameter_data);
  }

  centerToFeature(feature) {
    //Clear if any selected assets
    this.clearSel();

    //Build object for the upcoming selection
    const target = {
      id: feature.item.osm_id,
      properties: feature.item,
      geometry: feature.item.way,
      source: feature.source.sourceName,
      sourceLayer: feature.layer.sourceLayer,
    };

    //Set map bbox
    const bbox = JSON.parse(feature.item.bbox);
    this.map.fitBounds(
      [
        [bbox[0], bbox[1]],
        [bbox[2], bbox[3]],
      ],
      { duration: 1000, offset: [0, 0] }
    );

    //Force select on found asset
    setTimeout(() => {
      this.addFtrToSel(target);
    }, 2500);
  }

  public mapSelectionUpdate(data) {
    this.$store.dispatch("map/changeMapSelection", data).then(() => {
      this.$forceUpdate();
    });
  }

  public mapTagRefs(data) {
    this.$store.dispatch("map/changeMapTagRefs", data).then(() => {
      this.$forceUpdate();
    });
  }

  mapZoneAttrs() {
    this.$store.dispatch("map/changeZonesAttributes", this.polygonAttr);
  }

  mapZoneAttrMode() {
    this.$store.dispatch(
      "map/changeZonesAttributesMode",
      this.data.editZoneAttr
    );
  }

  saveMapPosition() {
    this.$store
      .dispatch("map/changeMapCenter", this.map.getCenter())
      .then(() => {
        this.$forceUpdate();
      });
    this.$store.dispatch("map/changeMapZoom", this.map.getZoom()).then(() => {
      this.$forceUpdate();
    });
  }
}
