
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import Color from "@/components/map/styles/Color.vue";
import Label from "@/components/map/styles/Label.vue";
import Diameter from "@/components/map/styles/Diameter.vue";
import Lines from "@/components/map/styles/Lines.vue";
import Custom from "@/components/map/styles/Custom.vue";

@Component({
  components: {
    Color,
    Label,
    Diameter,
    Lines,
    Custom,
  },
  computed: {
    ...mapGetters(["isMobile"]),
  },
})
export default class SidebarStyle extends Vue {
  public toggleIcon: string;
  public styleSet: any[];
  public panel: number[];

  @Prop() readonly filters: any;
  @Prop() readonly syslist: { id: number; show: boolean; name: string }[];
  @Prop() readonly predefinedData: any;

  constructor() {
    super();
    this.styleSet = [];
    this.toggleIcon = "mdi-brush-outline";
    this.panel = [];
  }

  setColor(obj, cond) {
    this.$emit("change-line-color", obj, cond);
  }

  setSymbols(items, selected) {
    this.$emit("change-symbols", items, selected);
  }

  setWaterLineSize(arr) {
    this.$emit("change-water-line-size", arr);
  }

  setCanalLineSize(arr) {
    this.$emit("change-canal-line-size", arr);
  }

  onResetWaterLineWidth() {
    this.$emit("reset-water-width");
  }

  onResetCanalLineWidth() {
    this.$emit("reset-canal-width");
  }

  saveThematic(description, isSystem) {
    this.$emit("save-custom-thematic", description, isSystem);
  }

  deleteCutomTheme(item) {
    this.$emit("on-delete-custom-theme", item);
  }

  applyCutomTheme(item) {
    this.$emit("on-apply-custom-theme", item);
  }

  public toggleSidebar() {
    const elem = document.getElementById("sidebar-style");
    const layersToggle = Array.from(
      document.getElementsByClassName(
        "sidebar-layer-toggle"
      ) as HTMLCollectionOf<HTMLElement>
    );

    if (elem) {
      const classes = elem.className.split(" ");
      const collapsed = classes.indexOf("collapsed") !== -1;
      if (collapsed) {
        classes.splice(classes.indexOf("collapsed"), 1);

        setTimeout(() => {
          layersToggle[0].style.display = "none";
          this.toggleIcon = "mdi-arrow-collapse-right";
        }, 100);
      } else {
        classes.push("collapsed");

        setTimeout(() => {
          layersToggle[0].style.display = "flex";
          this.toggleIcon = "mdi-brush-outline";
        }, 550);
      }
      elem.className = classes.join(" ");
    }
  }
}
