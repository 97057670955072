import request from '@/utils/request';

export function getPolygons() {
    return request({
        url: process.env.VUE_APP_API_BACKEND + 'Warehouse/GetPolygons',
        method: 'get',
        params: {
            projectID: process.env.VUE_APP_PROJECTID
        }
    }) as any
}
export function getPolygonsPRS() {
    return request({
        url: process.env.VUE_APP_BREAKDOWN_BACKEND + 'Regions/GetPolygons',
        method: 'get',
        params: {
            active: true
        }
    }) as any
}

export function getMapData() {
    return request({
        url: process.env.VUE_APP_API_BACKEND + 'Definition/GetPublicZonesDefinition',
        method: 'get',
        params: {
            projectID: process.env.VUE_APP_PROJECTID
        }
    }) as any
}

export function savePolygon(data) {
    return request({
        url: process.env.VUE_APP_API_BACKEND + 'Warehouse/UpdatePolygonsData',
        method: 'post',
        data: data
    }) as any
}