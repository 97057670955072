
import { Component, Prop, Vue } from "vue-property-decorator";

import fileDownload from "js-file-download";
import { exportBox, exportFull } from "@/api/map/";
import { IDataObject } from "@/interfaces";

@Component({
  components: {},
  computed: {
  },
})
export default class TMapExport extends Vue {
  public shapeDefaultFileName = "shape.zip";
  public toggle_none = null;
  public loadingFull = false;
  public loadingBox = false;

  @Prop() readonly data: IDataObject;
  @Prop() readonly projection: string;

  constructor() {
    super();
  }

  public exportFullSet() {
    this.loadingFull = true;
    exportFull(this.projection).then((response) => {
      fileDownload(response.data, this.shapeDefaultFileName);
    }).finally(() => { this.loadingFull = false; });
  }

  public exportBoundingBox(minLat, minLng, maxLat, maxLng) {
    this.loadingBox = true;
    exportBox(minLat, minLng, maxLat, maxLng,  this.projection).then((response) => {
      fileDownload(response.data, this.shapeDefaultFileName);
    }).finally(() => { this.loadingBox = false; });
  }
}
