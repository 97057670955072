
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({
  components: {},
  computed: {
  },
})
export default class SidebarFilters extends Vue {
  private toggleIcon: string;
  private rules: any;

  @Prop() readonly syslist: { id: number; show: boolean; name: string }[];
  @Prop() readonly objlist: { id: number; show: boolean; name: string }[];
  @Prop() readonly projectlist: { id: number; show: boolean; name: string }[][];
  @Prop() readonly filters: any[];

  constructor() {
    super();

    this.toggleIcon = "mdi-filter-outline";
    this.rules = {
      positive: (value) => value >= 0 || this.$t("invalid_number"),
    };
  }

  public actionOnAll(item) {
    if (item.selectedIds.length > 0) {
      item.selectedIds = [];
    } else {
      item.selectedIds = item.nomenclatureValues.map((v) => v.id);
    }
    this.$forceUpdate();
    this.mapFilter(item);
  }

  public mapFilter(item) {
    this.$emit("change-visibility", item);
  }

  public clearAllFilters() {
    Object.keys(this.filters).forEach((key) => {
      const f = this.filters[key];
      if (f.visible) {
        if (f.selectedIds.length !== f.nomenclatureValues.length) {
          f.selectedIds = f.nomenclatureValues.map((v) => v.id);
        } else if (f.from !== 0 && f.to !== 0) {
          f.from = null;
          f.to = null;
        } else if (!f.exploit_status) {
          f.exploit_status = -1;
        }
        this.$forceUpdate();
        this.mapFilter(f);
      }
    });
  }

  get getSortedFilters() {
    const list: any = [];
    Object.keys(this.filters)
      .sort(
        (a, b) =>
          this.filters[a].nomDefOrderNumber - this.filters[b].nomDefOrderNumber
      )
      .forEach((x) => {
        list.push(this.filters[x]);
      });
    return list;
  }

  public toggleSidebar() {
    const elem = document.getElementById("sidebar-filter");
    const styleToggle = Array.from(
      document.getElementsByClassName(
        "sidebar-style-toggle"
      ) as HTMLCollectionOf<HTMLElement>
    );
    const layersToggle = Array.from(
      document.getElementsByClassName(
        "sidebar-layer-toggle"
      ) as HTMLCollectionOf<HTMLElement>
    );

    if (elem) {
      const classes = elem.className.split(" ");
      const collapsed = classes.indexOf("collapsed") !== -1;
      if (collapsed) {
        classes.splice(classes.indexOf("collapsed"), 1);

        setTimeout(() => {
          styleToggle[0].style.display = "none";
          layersToggle[0].style.display = "none";
          this.toggleIcon = "mdi-arrow-collapse-right";
        }, 100);
      } else {
        classes.push("collapsed");

        setTimeout(() => {
          styleToggle[0].style.display = "flex";
          layersToggle[0].style.display = "flex";
          this.toggleIcon = "mdi-filter-outline";
        }, 550);
      }
      elem.className = classes.join(" ");
    }
  }
}
