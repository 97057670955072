import i18n from "@/i18n";
import common from "@/utils/constants/map/common";
import proj4 from "proj4";
import store from "@/store";

export default class MapService {
    showCenterAndZoom(newScale, lnglat, coordinateSystem) {
        const container = document.getElementById("center_and_zoom");
        container!.innerHTML = "";

        // Populate the distanceContainer with total distance
        const cordinateSystemName = common.COORDINATE_SYSTEMS.find(x => x.key === coordinateSystem)
        const value = document.createElement("con");

        if (!store.getters.isMobile) {
            const reprojected = proj4("EPSG:4326", coordinateSystem).forward([lnglat.lng, lnglat.lat]);

            if (cordinateSystemName?.value === "WGS 84") {

                const lng = reprojected[0].toFixed(8);
                const lat = reprojected[1].toFixed(8);

                const scale = newScale;
                value.innerHTML = `${i18n.t('coordinates')}: ${lng} , ${lat}; <br> ${i18n.t('map_scale')}: 1:${scale} ${i18n.t('coordinate_system')}:   ${cordinateSystemName?.value}`;

            } else {
                const lng = reprojected[0].toFixed(3);
                const lat = reprojected[1].toFixed(3);
                const scale = newScale;

                value.innerHTML = `${i18n.t('coordinates')}: ${lng} , ${lat}; <br> ${i18n.t('map_scale')}: 1:${scale} ${i18n.t('coordinate_system')}:   ${cordinateSystemName?.value}`;
            }
            container!.appendChild(value);
        }
    }
}
