var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"id":"drawer-zone-attributes"}},[_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.polygonAttr),function(item,key){return _c('v-expansion-panel',{key:key},[_c('v-expansion-panel-header',[_vm._v(_vm._s(_vm.$t("attributes_of"))+" "+_vm._s(_vm.objectTypes[item.layer]))]),_c('v-expansion-panel-content',[_c('v-text-field',{attrs:{"disabled":"","label":"ID","placeholder":"Ще се генерира автоматично"},model:{value:(item.properties.osm_id),callback:function ($$v) {_vm.$set(item.properties, "osm_id", $$v)},expression:"item.properties.osm_id"}}),_c('v-text-field',{attrs:{"label":_vm.$t('name'),"clearable":""},on:{"change":function($event){return _vm.onAttrChange(item.properties.osm_id, {
              name: item.properties.name,
            })}},model:{value:(item.properties.name),callback:function ($$v) {_vm.$set(item.properties, "name", $$v)},expression:"item.properties.name"}}),(item.layer === _vm.layers.ZONES_LINE)?[_c('v-autocomplete',{staticStyle:{"z-index":"100"},attrs:{"items":_vm.lineType,"item-text":"value","item-value":"value","no-data-text":_vm.$t('no_results_found'),"label":_vm.$t('type'),"auto-select-first":""},on:{"change":function($event){return _vm.onAttrChange(item.properties.osm_id, {
                type: item.properties.type,
              })}},model:{value:(item.properties.type),callback:function ($$v) {_vm.$set(item.properties, "type", $$v)},expression:"item.properties.type"}})]:(item.layer === _vm.layers.ZONES_POLYGON)?[_c('v-autocomplete',{staticStyle:{"z-index":"100"},attrs:{"items":_vm.zoneType,"item-text":"value","item-value":"value","no-data-text":_vm.$t('no_results_found'),"label":_vm.$t('type'),"auto-select-first":""},on:{"change":function($event){return _vm.onAttrChange(item.properties.osm_id, {
                type: item.properties.type,
              })}},model:{value:(item.properties.type),callback:function ($$v) {_vm.$set(item.properties, "type", $$v)},expression:"item.properties.type"}})]:_vm._e(),_c('date-time-picker',{attrs:{"label":_vm.$t('creation_date'),"date-format":"DD.MM.YYYY","time-format":"HH:mm","disabled":true},on:{"input":function($event){return _vm.onAttrChange(item.properties.osm_id, {
              creation_date: item.properties.creation_date,
            })}},model:{value:(item.properties.creation_date),callback:function ($$v) {_vm.$set(item.properties, "creation_date", $$v)},expression:"item.properties.creation_date"}}),_c('date-time-picker',{attrs:{"label":_vm.$t('start_date'),"date-format":"DD.MM.YYYY","time-format":"HH:mm"},on:{"input":function($event){return _vm.onAttrChange(item.properties.osm_id, {
              start_time: item.properties.start_time,
            })}},model:{value:(item.properties.start_time),callback:function ($$v) {_vm.$set(item.properties, "start_time", $$v)},expression:"item.properties.start_time"}}),_c('date-time-picker',{attrs:{"label":_vm.$t('end_time_expected'),"date-format":"DD.MM.YYYY","time-format":"HH:mm"},on:{"input":function($event){return _vm.onAttrChange(item.properties.osm_id, {
              end_time_expected: item.properties.end_time_expected,
            })}},model:{value:(item.properties.end_time_expected),callback:function ($$v) {_vm.$set(item.properties, "end_time_expected", $$v)},expression:"item.properties.end_time_expected"}}),_c('date-time-picker',{attrs:{"label":_vm.$t('end_time_real'),"date-format":"DD.MM.YYYY","time-format":"HH:mm"},on:{"input":function($event){return _vm.onAttrChange(item.properties.osm_id, {
              end_time_real: item.properties.end_time_real,
            })}},model:{value:(item.properties.end_time_real),callback:function ($$v) {_vm.$set(item.properties, "end_time_real", $$v)},expression:"item.properties.end_time_real"}}),_c('date-time-picker',{attrs:{"label":_vm.$t('last_edit'),"date-format":"DD.MM.YYYY","time-format":"HH:mm","disabled":true},on:{"input":function($event){return _vm.onAttrChange(item.properties.osm_id, {
              last_edit: item.properties.last_edit,
            })}},model:{value:(item.properties.last_edit),callback:function ($$v) {_vm.$set(item.properties, "last_edit", $$v)},expression:"item.properties.last_edit"}}),_c('v-text-field',{attrs:{"label":_vm.$t('created_by'),"disabled":"","placeholder":"Ще се вземе от системата"},on:{"change":function($event){return _vm.onAttrChange(item.properties.osm_id, {
              created_by: item.properties.created_by,
            })}},model:{value:(item.properties.created_by),callback:function ($$v) {_vm.$set(item.properties, "created_by", $$v)},expression:"item.properties.created_by"}}),_c('v-textarea',{attrs:{"label":_vm.$t('description'),"auto-grow":"","rows":"1","clearable":""},on:{"change":function($event){return _vm.onAttrChange(item.properties.osm_id, {
              description: item.properties.description,
            })}},model:{value:(item.properties.description),callback:function ($$v) {_vm.$set(item.properties, "description", $$v)},expression:"item.properties.description"}}),_c('v-switch',{attrs:{"label":`${item.properties.active ? `${_vm.$t('active_zone')}` : `${_vm.$t('inactive_zone')}`}`,"color":`${item.properties.active ? 'primary' : 'error'}`},on:{"change":function($event){return _vm.onAttrChange(item.properties.osm_id, {
              active: item.properties.active,
            })}},model:{value:(item.properties.active),callback:function ($$v) {_vm.$set(item.properties, "active", $$v)},expression:"item.properties.active"}})],2)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }