
import { formatDateTime } from "@/utils";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({
  components: {},
  computed: {
  },
})
export default class BreakdownLegend extends Vue {
  public panel: number[];
  public computedObjInfo: string;
  public progress: number;

  @Watch("obj")
  onBreakdownChange() {
    this.getObjInfo();
  }

  @Prop() readonly obj: any;

  constructor() {
    super();
    this.panel = [0, 1, 2];
    this.computedObjInfo = "";
    this.progress = 15;
  }

  public getObjInfo() {
    this.computedObjInfo = "";
    const obj = this.obj;
    if (Object.keys(obj).length > 0) {
      console.log(obj)
      this.computedObjInfo = `
        <b>Наименование:</b> ${obj.name}
        <br/>
        <b>Тип:</b> ${obj.type}
        <br/>
        <b>Дата на създаване:</b> ${formatDateTime(obj.creation_date)}
        <br/>
        <b>Начална дата:</b> ${formatDateTime(obj.props.repairStart)}
        <br/>
        <b>Дата на приключване:</b> ${formatDateTime(obj.end_time_expected)}
        <br/>
        <b>Последна корекция:</b> ${formatDateTime(obj.last_edit)}
        <br/>
        <b>Описание:</b> ${obj.description}`;
    }
  }

  public toggleLegend() {
    this.$emit("toggle-legend", false,"breakdown-legend");
  }
}
