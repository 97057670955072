/* eslint-disable */

import { IControl, Map as MapboxMap } from 'mapbox-gl';
import CrosshairManager from './crosshair-manager';
import PrintableAreaManager from './printable-area-manager';
import { english, bulgarian, Translation } from './local';
import MapGenerator, {
  Size, Format, PageOrientation, DPI, Unit,
} from './map-generator';

type Options = {
  PageSize: any;
  PageOrientation: string;
  Format: string;
  DPI: number;
  Crosshair?: boolean;
  PrintableArea: boolean;
  accessToken?: string;
  Local?: 'bg' | 'en';
}

/**
 * Mapbox GL Export Control.
 * @param {Object} targets - Object of layer.id and title
 */
export default class MapboxExportControl implements IControl {
  private controlContainer: HTMLElement;

  private crosshair: CrosshairManager | undefined;

  private printableArea: PrintableAreaManager | undefined;

  private map?: MapboxMap;

  private exportButton: HTMLButtonElement;

  private exportVueContainer: HTMLElement;

  private exportVueCloseButton: HTMLButtonElement;

  private options: Options = {
    PageSize: Size.A4,
    PageOrientation: PageOrientation.Landscape,
    Format: Format.PDF,
    DPI: DPI[200],
    Crosshair: false,
    PrintableArea: false,
    accessToken: undefined,
  }

  constructor(options: Options) {
    if (options) {
      this.options = Object.assign(this.options, options);
    }
  }

  public getDefaultPosition(): string {
    const defaultPosition = 'top-right';
    return defaultPosition;
  }

  public getTranslation(): Translation {
    switch (this.options.Local) {
      case 'en':
        return english;
      case 'bg':
        return bulgarian;
      default:
        return english;
    }
  }

  public onAdd(map: MapboxMap): HTMLElement {
    this.map = map;
    this.controlContainer = document.createElement('div');
    this.controlContainer.classList.add('mapboxgl-ctrl');
    this.controlContainer.classList.add('mapboxgl-ctrl-group');
    this.exportButton = document.createElement('button');
    this.exportButton.classList.add('mapboxgl-ctrl-icon');
    this.exportButton.classList.add('mapboxgl-export-control');
    this.exportButton.type = 'button';
    this.exportButton.addEventListener('click', () => {
      this.exportButton.style.display = 'none';

      this.exportVueContainer = <HTMLElement>document.getElementById('export-attributes');
      this.exportVueContainer.setAttribute('style', 'display:block !important');

      this.toggleCrosshair(true);
      this.togglePrintableArea(true);
    });

    this.exportVueCloseButton = <HTMLButtonElement>document.getElementById('export-attributes-close-btn');
    this.exportVueCloseButton.addEventListener('click', () => {
      this.printableArea?.clearBackupdata();
      this.exportVueContainer = <HTMLElement>document.getElementById('export-attributes');
      this.exportVueContainer.setAttribute('style', 'display:none !important');
      this.exportButton.style.display = 'block';
      this.toggleCrosshair(false);
      this.togglePrintableArea(false);
    });

    this.controlContainer.appendChild(this.exportButton);

    const pageSize = document.querySelector("#mapbox-gl-export-page-size-custom")!;
    pageSize.setAttribute = () => {
      this.updatePrintableArea();
    };

    const pageOrientation = document.querySelector("#mapbox-gl-export-page-orientaiton-custom")!;
    pageOrientation.setAttribute = () => {
      this.updatePrintableArea();
    };

    // const scale = document.querySelector("#mapbox-gl-export-scale-custom")!;
    // scale.setAttribute = () => {
    //   this.updatePrintableArea();
    // };

    const generateButton = <HTMLButtonElement>document.getElementById('export-attributes-generate-btn');
    generateButton.addEventListener('click', () => {
      const pageSize: HTMLSelectElement = <HTMLSelectElement>document.getElementById('mapbox-gl-export-page-size-custom');
      const pageOrientation: HTMLSelectElement = <HTMLSelectElement>document.getElementById('mapbox-gl-export-page-orientaiton-custom');
      const formatType: HTMLSelectElement = <HTMLSelectElement>document.getElementById('mapbox-gl-export-format-type-custom');
      const dpiType: HTMLSelectElement = <HTMLSelectElement>document.getElementById('mapbox-gl-export-dpi-type-custom');
      const orientValue = PageOrientation[pageOrientation.value];
      let pageSizeValue = Size[pageSize.value];
      if (orientValue === PageOrientation.Portrait) {
        pageSizeValue = [pageSizeValue[1], pageSizeValue[0]];
      } else {
        pageSizeValue = [pageSizeValue[0], pageSizeValue[1]];
      }

      this.printableArea?.revertToPrimaryZoom(map);
      const mapGenerator = new MapGenerator(
        map,
        pageSizeValue,
        Number(DPI[dpiType.value]),
        Format[formatType.value],
        Unit.mm,
        this.options.accessToken,
      );
      mapGenerator.generate();
    });

    return this.controlContainer;
  }

  private toggleCrosshair(state: boolean) {
    if (this.options.Crosshair === true) {
      if (state === false) {
        if (this.crosshair !== undefined) {
          this.crosshair.destroy();
          this.crosshair = undefined;
        }
      } else {
        this.crosshair = new CrosshairManager(this.map);
        this.crosshair.create();
      }
    }
  }

  private togglePrintableArea(state: boolean) {
    if (this.options.PrintableArea === true) {
      if (state === false) {
        if (this.printableArea !== undefined) {
          this.printableArea.destroy();
          this.printableArea = undefined;
        }
      } else {
        this.printableArea = new PrintableAreaManager(this.map);
        this.updatePrintableArea();
      }
    }
  }

  public updatePrintableArea() {
    if (this.printableArea === undefined) {
      return;
    }
    const pageSize: HTMLSelectElement = <HTMLSelectElement>document.getElementById('mapbox-gl-export-page-size-custom');
    const pageOrientation: HTMLSelectElement = <HTMLSelectElement>document.getElementById('mapbox-gl-export-page-orientaiton-custom');
    const orientValue = PageOrientation[pageOrientation.value];
    let pageSizeValue = Size[pageSize.value];
    if (orientValue === PageOrientation.Portrait) {
      this.printableArea.updateArea(pageSizeValue[1], pageSizeValue[0], Size.A4[1], Size.A4[0]);
    } else {
      this.printableArea.updateArea(pageSizeValue[0], pageSizeValue[1], Size.A4[0], Size.A4[1]);
    }
  }
}
