/* eslint-disable */

import mapboxgl, { GeolocateControl, NavigationControl } from "mapbox-gl";
import { Size, PageOrientation, Format, DPI, } from "@/external/print-lib/lib/map-generator";
import MapboxExportControl from "@/external/print-lib/lib/export-control";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { MeasureControl } from "@/models/Map/PointDataModel";

const GEOCODER_OPTIONS = {
    limit: 10,
    collapsed: false,
    zoom: 15,
    placeholder: "Търсене по адрес",
    marker: true
} as any;

const PRINT_CONTAINER = {
    PageSize: Size.A4,
    PageOrientation: PageOrientation.Landscape,
    Format: Format.PDF,
    DPI: DPI[200],
    Crosshair: true,
    PrintableArea: true,
    Local: "bg"
} as any;
const EXPORT_CONTROL = new MapboxExportControl(PRINT_CONTAINER);

const GEOLOCATE_CONTAINER = {
    positionOptions: {
        enableHighAccuracy: true,
    },
    // When active the map will receive updates to the device's location as it changes.
    trackUserLocation: true,
    showAccuracyCircle: true,
    showUserLocation: true,
} as any;
const GEOLOCATE_CONTROL = new GeolocateControl(GEOLOCATE_CONTAINER);

const COMPASS_CONTAINER = {
    showCompass: true,
    showZoom: false,
} as any;
const COMPASS_CONTROL = new NavigationControl(COMPASS_CONTAINER);

const MAPBOX_DRAW_CONTAINER = {
    displayControlsDefault: false,
    controls: {
        point: true,
        line_string: true,
        polygon: true,
        trash: true,
        combine_features: false,
        uncombine_features: false,
    },
} as any;
const MAPBOX_DRAW_CONTROL = new MapboxDraw(MAPBOX_DRAW_CONTAINER);

const MEASURE_CONTROL = new MeasureControl();

export default {
    GEOCODER_OPTIONS,
    GEOLOCATE_CONTROL,
    COMPASS_CONTROL,
    EXPORT_CONTROL,
    MAPBOX_DRAW_CONTROL,
    MEASURE_CONTROL
}