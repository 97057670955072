
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  components: {},
  computed: {
    ...mapGetters(["reverse_diameter_data"]),
  },
})
export default class Diameter extends Vue {
  public waterRanges: { from: number; to: number; size: number }[];
  public canalRanges: { from: number; to: number; size: number }[];
  public rules: any;
  public storeData: any;

  @Prop() readonly syslist: { id: number; show: boolean; name: string }[];

  @Watch("reverse_diameter_data")
  onReverseDiameterStyleDataChange(data: any) {
    this.reverseStyleData(data);
  }

  constructor() {
    super();
    this.waterRanges = [{ from: 0, to: 0, size: 0 }];
    this.canalRanges = [{ from: 0, to: 0, size: 0 }];
    this.rules = {
      positive: (value) => value > 0 || this.$t("invalid_number"),
    };
    this.storeData = {};
  }

  addRow(arr) {
    arr.push({ from: 0, to: 0, size: 0 });
  }

  removeRow(arr, idx, system) {
    if (arr.length > 1) {
      arr.splice(idx, 1);
    } else {
      if (system === 0) {
        this.waterRanges = [{ from: 0, to: 0, size: 0 }];
      } else {
        this.canalRanges = [{ from: 0, to: 0, size: 0 }];
      }
    }
  }

  setWaterLineSize() {
    this.storeData.water = this.waterRanges;
    this.$store.dispatch("map/changeDiameterData", this.storeData);
    this.$emit("change-water-line-size", this.waterRanges);
  }

  setCanalLineSize() {
    this.storeData.canal = this.canalRanges;
    this.$store.dispatch("map/changeDiameterData", this.storeData);
    this.$emit("change-canal-line-size", this.canalRanges);
  }

  restoreWaterLineWidth() {
    this.$emit("reset-water-line-width");
  }

  restoreCanalLineWidth() {
    this.$emit("reset-canal-line-width");
  }

  reverseStyleData(data) {
    if (data.water || data.canal) {
      this.waterRanges = data.water;
      this.canalRanges = data.canal;
    }else {
      this.waterRanges = [{ from: 0, to: 0, size: 0 }];
      this.canalRanges = [{ from: 0, to: 0, size: 0 }];
    }
  }
}
