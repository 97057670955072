
  import { formatDateTime ,formatDateCusom} from "@/utils";
  import { Component, Vue, Prop, Watch } from "vue-property-decorator";
  
  @Component({
    components: {},
    computed: {
    },
  })
  export default class BreakdownInfoList extends Vue {
    public panel: number[];
    public computedObjInfo: string;
    public progress: number;
  
   
  
    @Prop() readonly obj: any;
    @Prop() readonly selectedItemId: number;
  
    constructor() {
      super();
      this.panel = [0, 1, 2];
      this.computedObjInfo = "";
      this.progress = 15;
    }
    goToBreakdown(item){
        this.$emit("go-to-breakdown",item)
    }
    formatDate(dateStr) {
     return   formatDateCusom(dateStr)
   
    }

  
    public toggleInfoList() {
      this.$emit("toggle-info-list", false,"breakdown-info-list");
    }
  }
  