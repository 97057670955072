
import { loadAllAttributes, sendPhoto, downloadPhoto, deletePhoto } from "@/api/map";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  components: {},
  computed: {
    ...mapGetters(["selected_features", "tag_refs"]),
  },
})
export default class PhotoFrom extends Vue {

  public uploadPhotoForm: boolean;
  public form: any;
  public dialog: boolean;
  public docName: string |null = null ;
  public idToDelete: number  | null =null;

  public selectedFile: File | null = null;

  @Prop() readonly responseUploadedPhoto: any;
  @Prop() readonly asset: any;
  @Prop() readonly multipleSelectionShow: boolean;

  @Watch("selected_features")
  onSelectionChange(data: any[]) {
    this.uploadPhotoForm = false;




  }


  constructor() {
    super();
    this.dialog = false
    this.uploadPhotoForm = false;
    this.form = new FormData();
    this.selectedFile = null;
    this.idToDelete = null ;
    this.docName = null;



  }
  public async sendPhotoForm(osm_id) {
    const formData = new FormData();

    if (this.selectedFile) {
      formData.append('file', this.selectedFile);
    }
    try {
      await sendPhoto(osm_id, formData).then(()=>{this.$emit("get-documetns")});
      
      this.selectedFile = null;
      this.uploadPhotoForm = false;
     
    } catch (error) {
      console.error(error);
      alert('An error occurred while uploading the photo');
    }

  }



  public async DownloadPhoto(id) {
    downloadPhoto(id);
  }
  public async DeletePhoto() {
    deletePhoto(this.idToDelete).then(()=>{this.dialog = false; this.$emit("get-documetns")});
  }
  public confirmDelete(data){
    this.idToDelete = data.id;
    this.docName = data.description;
    this.dialog = true;
  }
}

