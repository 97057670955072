import { IRequestData } from '@/interfaces/Request';
import request from '@/utils/request';
import { AxiosPromise } from 'axios';

export function getSystems() {
  return request({
    url: process.env.VUE_APP_API_BACKEND + 'Nomenclature/GetSystems',
    method: 'get',
    params: {
      lang: process.env.VUE_APP_LANGUAGE
    }
  }) as AxiosPromise<IRequestData>
}

export function getObjects() {
  return request({
    url: process.env.VUE_APP_API_BACKEND + 'Nomenclature/GetObjects',
    method: 'get',
    params: {
      lang: process.env.VUE_APP_LANGUAGE
    }
  }) as AxiosPromise<IRequestData>
}

export function getNomenclatures() {
  return request({
    url: process.env.VUE_APP_API_BACKEND + 'Nomenclature/GetNomenclatures',
    method: 'get',
    params: {
      lang: process.env.VUE_APP_LANGUAGE,
      projectID: process.env.VUE_APP_PROJECTID
    }
  }) as AxiosPromise<IRequestData>
}

export function getTagRefByLang() {
  return request({
    url: process.env.VUE_APP_API_BACKEND + 'Nomenclature/GetTagRefByLang',
    method: 'get',
    params: { 
      lang: process.env.VUE_APP_LANGUAGE,
      projectID: process.env.VUE_APP_PROJECTID }
  }) as AxiosPromise<IRequestData>
}

export function getPropertiesToSearchBy() {
  return request({
    url: process.env.VUE_APP_API_BACKEND + 'Nomenclature/GetPropertiesToSearchBy',
    method: 'get',
    params: { 
      lang: process.env.VUE_APP_LANGUAGE,
      projectID: process.env.VUE_APP_PROJECTID }
  }) as AxiosPromise<IRequestData>
}