
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  components: {},
  computed: {
    ...mapGetters([]),
  },
})
export default class Lines extends Vue {
  constructor() {
    super();
  }
}
