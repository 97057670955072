
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { promptActionConfirmation } from "@/utils";

@Component({
  components: {},
  computed: {
    ...mapGetters([]),
  },
})
/* eslint-disable */
export default class Custom extends Vue {
  public tHeaders: any[];
  public tItems: any[];
  public themeDescription: string;
  public isSystemStyle: boolean;
  public previousRow: number;

  @Prop() readonly predefinedData: any;

  constructor() {
    super();
    this.tHeaders = [
      { value: "actions", width: "5%", sortable: false },
      {
        text: `${this.$t("description")}`,
        align: "start",
        value: "description",
        width: "15%",
        sortable: false,
      },
    ];
    this.tItems = [];
    this.themeDescription = "";
    this.isSystemStyle = false;
    this.previousRow = -1;
  }

  saveThematic() {
    this.$emit("on-save-thematic", this.themeDescription, this.isSystemStyle);
  }

  deleteItem(item) {
    promptActionConfirmation(
      this.$t("delete_row"),
      () => {
        this.$emit("delete-custom-theme", item);
      },
      () => {}
    );
  }

  applyTheme(item, index) {
    promptActionConfirmation(
      this.$t("apply_changes"),
      () => {
        this.$emit("apply-custom-theme", item);
      },
      () => {}
    );
  }

  rowClick(item, row) {
    const selectState = row.isSelected ? false : true;
    row.select(selectState);
  }
}
