
  import { Component, Vue } from 'vue-property-decorator';

  interface ILink {
    href: string;
    text: string;
  }

  @Component
  export default class CoreAppFooter extends Vue {
    public links: ILink[]

    constructor(){
      super();
      this.links = [];
    }
  }
