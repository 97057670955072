/* eslint-disable */
import { getToken } from '@/utils/auth';
import { MutationTree, ActionTree } from "vuex"
import { IUserStoreModule } from './interfaces';

class UserState implements IUserStoreModule {
  public token: string;
  public name: string;
  public isLogged: boolean;
  public username: string;
  public role: string;

  constructor() {
    this.token = getToken();
    this.isLogged = false;
    this.name = '';
    this.username = '';
    this.role = "Anonymous";
  }
}

const mutations: MutationTree<UserState> = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_USERNAME: (state, username) => {
    state.username = username
  },
  SET_ROLE: (state, role) => {
    state.role = role
  },
  LOGGED_IN: (state) => {
    state.isLogged = true;
  },
  LOGGED_OUT: (state) => {
    state.isLogged = false;
  }
}

const actions: ActionTree<UserState, any> = {
  setToken({ commit, dispatch }, data) {
    return new Promise(async resolve => {
        commit('SET_TOKEN', data);
    })
  },

  setUsername({ commit, dispatch }, data) {
    return new Promise(async resolve => {
        commit('SET_USERNAME', data);
    })
  }
}
const state = new UserState();

const UserModule = {
  namespaced: true,
  state,
  mutations,
  actions
}

export default UserModule
