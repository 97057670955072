
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import source from "@/utils/constants/map/source";

interface IDEditorObj {
  water_objects: boolean;
  sewer_objects: boolean;
  points: boolean;
  svo_objects: boolean;
}

@Component({
  components: {},
  computed: {
  },
})
export default class IDEditorGenerator extends Vue {
  private data: IDEditorObj;
  private disabledFeaturesConst = "&disable_features=";
  private disabledFeatures = "";
  private idConst = "&id=";
  private objectsByID = "";
  private isActive = true;

  @Prop() readonly redirectButton: boolean;
  @Prop() readonly syslist: { id: number; show: boolean; name: string }[];
  @Prop() readonly objlist: { id: number; show: boolean; name: string }[];
  @Prop() readonly selectionList: any;

  @Watch("syslist", { immediate: true, deep: true })
  onSystemChange(systems: { id: number; show: boolean; name: string }[]) {
    this.data.water_objects = systems[0].show ? true : false;
    this.data.sewer_objects = systems[1].show ? true : false;
  }

  @Watch("objlist", { immediate: true, deep: true })
  onObjectChange(objects: { id: number; show: boolean; name: string }[]) {
    this.data.points = objects[0][1].show && objects[1][1].show ? true : false;
  }

  @Watch("selectionList", { deep: true })
  onSelectionChange(selection: any) {
    this.objectsByID = "";
    selection.forEach((el) => {
      // if (el.src === source.WATERLINES) {
      //   this.objectsByID += `w${el.props.osm_id},`;
      // } else if (el.src === source.WATERPOINTS) {
      //   this.objectsByID += `n${el.props.osm_id},`;
      // }
    });

    this.data.svo_objects = [
      ...new Set(selection.map((item) => item.props.aq_category)),
    ].some((x) => x === "СВО")
      ? true
      : false;
  }

  constructor() {
    super();
    this.data = {
      water_objects: true,
      sewer_objects: true,
      points: false,
      svo_objects: true,
    };
  }

  public generateParams() {
    if (this.isActive) {
      this.disabledFeatures = "";
      Object.keys(this.data).forEach((prop) => {
        if (!this.data[prop]) {
          this.disabledFeatures += prop + ",";
        }
      });

      let textToReturn = "";
      if (this.disabledFeatures.length > 0) {
        textToReturn = `${
          this.disabledFeaturesConst
        }${this.disabledFeatures.substring(
          0,
          this.disabledFeatures.length - 1
        )}`;
      }

      if (this.objectsByID.length > 0) {
        textToReturn += `${this.idConst}${this.objectsByID.substring(
          0,
          this.objectsByID.length - 1
        )}`;
      }

      this.$emit("generate-url", textToReturn);
    }
  }

  get getTooltip(): string {
    if (this.redirectButton) {
      if (this.selectionList.length === 0) {
        return `${this.$t("go_to_id")}`;
      } else {
        const scrs = [
          ...new Set(this.selectionList.map((item) => item.src)),
        ] as string[];
        if (
          scrs.find((x) => x.includes("lines")) &&
          scrs.find((x) => x.includes("points"))
        ) {
          this.isActive = false;
          return `${this.$t("select_one_type_of_objects")}`;
        }
      }
    }
    this.isActive = true;
    return `${this.$t("go_to_id")}`;
  }

  get getColor(): string {
    if (this.redirectButton) {
      if (this.selectionList.length === 0) {
        return "primary";
      } else {
        const scrs = [
          ...new Set(this.selectionList.map((item) => item.src)),
        ] as string[];
        if (
          scrs.find((x) => x.includes("lines")) &&
          scrs.find((x) => x.includes("points"))
        ) {
          return "secondary";
        }
      }
    }
    return "primary";
  }
}
