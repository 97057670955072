import { IRequestData } from '@/interfaces/Request';
import request from '@/utils/request';
import axios, { AxiosPromise } from 'axios';


export function getMapSettings() {
  return request({
    url: process.env.VUE_APP_API_BACKEND + 'Definition/GetMapDefinition',
    method: 'get',
    params: {
      projectID: process.env.VUE_APP_PROJECTID
    }
  }) as AxiosPromise<IRequestData>
}

export function getConditionalFilters() {
  return request({
    url: process.env.VUE_APP_API_BACKEND + 'Definition/GetConditionalFilters',
    method: 'get'
  }) as AxiosPromise<IRequestData>
}

export function getPredefinedDefinition() {
  return request({
    url: process.env.VUE_APP_API_BACKEND + 'Definition/GetPredefinedDefinitions',
    method: 'get',
    params: {
      projectID: process.env.VUE_APP_PROJECTID
    }
  }) as AxiosPromise<IRequestData>
}

export function saveCustomThematicSetup(data) {
  return request({
    url: process.env.VUE_APP_API_BACKEND + 'Definition/SaveCustomThematicSetup',
    method: 'post',
    data: data,
    params: {
      projectID: process.env.VUE_APP_PROJECTID
    }
  }) as AxiosPromise<IRequestData>
}

export function deleteCustomThematicSetup(data) {
  return request({
    url: process.env.VUE_APP_API_BACKEND + 'Definition/DeleteCustomThematicSetup',
    method: 'post',
    data: data
  }) as AxiosPromise<IRequestData>
}