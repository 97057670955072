
import { setSupersetConfig } from "@/api/map";

import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({
  components: {},
  computed: {
  },
})
export default class Superset extends Vue {
  private showBtn = false;

  @Prop() readonly selectionList: any;

  @Watch("selectionList", { deep: true })
  onSelectionChange(selection: any) {
    this.showBtn = process.env.VUE_APP_SHOW_SUPERSET === 1 ? selection.length > 0 ? true : false : false;
  }

  constructor() {
    super();
  }

  redirectToSuperset() {
    const selection: { selectedLines: string[]; selectedPoints: string[] } = {
      selectedLines: [],
      selectedPoints: [],
    };

    this.selectionList.forEach((x) => {
      if (x.src === "src_lines_w" || x.src === "src_lines_c") {
        selection.selectedLines.push(`"${x.props.osm_id}"`);
      } else if (x.src === "src_points_w" || x.src === "src_points_c") {
        selection.selectedPoints.push(`"${x.props.osm_id}"`);
      }
    });

    setSupersetConfig(selection).then((res) => {
      res.data.forEach((id) => {
        if (id !== 0) {
          //redirect
          const link = document.createElement("a");
          link.href = `${process.env.VUE_APP_SUPERSET_URL}/superset/dashboard/${id}/`;
          link.target = "_blank";
          link.click();
        }
      });
    });
  }
}
