import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import { IAppStoreModule } from './modules/app/interfaces';
import { IUserStoreModule } from './modules/user/interfaces';
import { IMapStoreModule } from './modules/map/interfaces';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export interface IRootState {
  /** root state modules **/
  app: IAppStoreModule;
  user: IUserStoreModule;
  map: IMapStoreModule;
}

const modulesFiles = require.context('./modules', true, /index.ts$/)

// eslint-disable-next-line
const modules = modulesFiles.keys().reduce((modules: {[index: string]: any}, modulePath) => {
  const moduleName: string = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  const value = modulesFiles(modulePath)
  modules[moduleName.split("/")[0]] = value.default

  return modules;
}, {})

const store = new Vuex.Store({
  getters,
  modules: modules,
  plugins: [createPersistedState({
    paths: [
      "app",
      "user",
      "map"
    ]
  })]
})

export default store;
