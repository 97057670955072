var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(_vm._s(_vm.$t("colors")))]),_c('v-expansion-panel-content',[_c('v-autocomplete',{attrs:{"items":_vm.styleSet,"label":_vm.$t('select_attr_for_thematic_coloring'),"item-text":"displayText","item-value":"id","dense":"","attach":"","outlined":""},on:{"change":_vm.getValues},model:{value:(_vm.currentType),callback:function ($$v) {_vm.currentType=$$v},expression:"currentType"}}),_c('v-autocomplete',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.nValues.length > 0 && _vm.filters[_vm.currentType].nomType.type === 'text'
      ),expression:"\n        nValues.length > 0 && filters[currentType].nomType.type === 'text'\n      "}],attrs:{"items":_vm.nValues,"label":"Избор стойност(и) на атрибут","item-text":"value","item-value":"id","multiple":"","dense":"","attach":"","outlined":""},on:{"change":_vm.onSelect},model:{value:(_vm.dropdownSelection),callback:function ($$v) {_vm.dropdownSelection=$$v},expression:"dropdownSelection"}}),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.nRange.length > 0 && _vm.filters[_vm.currentType].nomType.type === 'number'
      ),expression:"\n        nRange.length > 0 && filters[currentType].nomType.type === 'number'\n      "}]},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"placeholder":"от","type":"number","dense":"","outlined":"","prepend-icon":"mdi-google-circles-group","clearable":"","rules":[_vm.rules.positive]},on:{"change":function($event){return _vm.onSelect(null, _vm.nRange[0], _vm.nRange[1])}},model:{value:(_vm.nRange[0]),callback:function ($$v) {_vm.$set(_vm.nRange, 0, $$v)},expression:"nRange[0]"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"placeholder":"до","type":"number","dense":"","outlined":"","prepend-icon":"mdi-google-circles-group","clearable":"","rules":[_vm.rules.positive]},on:{"change":function($event){return _vm.onSelect(null, _vm.nRange[0], _vm.nRange[1])}},model:{value:(_vm.nRange[1]),callback:function ($$v) {_vm.$set(_vm.nRange, 1, $$v)},expression:"nRange[1]"}})],1)],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.nRange.length > 0 && _vm.filters[_vm.currentType].nomType.type === 'date'
      ),expression:"\n        nRange.length > 0 && filters[currentType].nomType.type === 'date'\n      "}]},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"placeholder":"от","type":"number","dense":"","prepend-icon":"mdi-calendar-clock","outlined":"","clearable":"","rules":[_vm.rules.positive]},on:{"change":function($event){return _vm.onSelect(null, _vm.nRange[0], _vm.nRange[1])}},model:{value:(_vm.nRange[0]),callback:function ($$v) {_vm.$set(_vm.nRange, 0, $$v)},expression:"nRange[0]"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"placeholder":"до","type":"number","dense":"","prepend-icon":"mdi-calendar-clock","outlined":"","clearable":"","rules":[_vm.rules.positive]},on:{"change":function($event){return _vm.onSelect(null, _vm.nRange[0], _vm.nRange[1])}},model:{value:(_vm.nRange[1]),callback:function ($$v) {_vm.$set(_vm.nRange, 1, $$v)},expression:"nRange[1]"}})],1)],1),_c('v-autocomplete',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.nValues.length > 0 && _vm.filters[_vm.currentType].nomType.type === 'radio'
      ),expression:"\n        nValues.length > 0 && filters[currentType].nomType.type === 'radio'\n      "}],attrs:{"items":_vm.nValues,"label":"Избор стойност(и) на атрибут","item-text":"value","item-value":"id","multiple":"","dense":"","attach":"","outlined":""},on:{"change":_vm.onSelect},model:{value:(_vm.dropdownSelection),callback:function ($$v) {_vm.dropdownSelection=$$v},expression:"dropdownSelection"}}),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.nValues.length > 0 || _vm.nRange.length > 0),expression:"nValues.length > 0 || nRange.length > 0"}],attrs:{"color":"primary","block":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-color-highlight")])],1)]}}])},[_c('v-color-picker',{staticClass:"mx-auto",attrs:{"hide-canvas":"","hide-sliders":"","show-swatches":"","swatches-max-height":"200"},on:{"update:color":_vm.setColor}})],1),_c('v-row'),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.nValues.length > 0 || _vm.nRange.length > 0),expression:"nValues.length > 0 || nRange.length > 0"}],staticClass:"elevation-1",staticStyle:{"margin-top":"50px"},attrs:{"headers":_vm.tHeaders,"items":_vm.tItems,"no-data-text":"Няма избран цвят","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item, index }){return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item, index)}}},[_vm._v(" mdi-close ")])]}},{key:`item.color`,fn:function({ item }){return [_c('v-sheet',{attrs:{"color":item.color,"elevation":"2","height":"10","width":"20"}})]}}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }