
// @ts-nocheck
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { getPolygonsPRS, getMapData } from "@/api/breakdown";
import { FeatureCollection } from "geojson";
import GeocodeAPI from "../services/GeocodeAPI";

import mapboxgl, { Map, GeoJSONSource } from "mapbox-gl";
import MapboxGeocoder from "@maplibre/maplibre-gl-geocoder";

import BreakdownLegend from "@/components/map/BreakdownLegend.vue";
import BreakdownInfoList from "@/components/map/BreakdownInfoList.vue";
import MapBoxCustomPopup from "@/components/map/MapBoxCustomPopup.vue";



import layer from "@/utils/constants/map/layer";
import tools from "@/utils/constants/map/tools";
import source from "@/utils/constants/map/source";
import common from "@/utils/constants/map/common";

interface IDataObject {
  zoom: number;
  center: object;
  url: string;
  maxBounds: object[];
  selectionList: number[];
  selectionData: { props: object; src: string; geom: any; sourceLayer: string }[];
  zones: FeatureCollection;
}

@Component({
  components: {
    BreakdownLegend,
    BreakdownInfoList,
  },
  computed: {
    ...mapGetters(["last_map_center", "last_map_zoom"]),
  },
})
export default class TMapBreakdown extends Vue {
  public data: IDataObject;
  public map: Map;
  public selectedObject: any;
  public layerList: { id: number; name: string; type: string }[];
  public layerSources: object;
  public layerStyles: object;
  public layerFilters: object;
  public geocodeApi: GeocodeAPI;
  public polygonId: number | null;
  public popup: mapboxgl.Popup;

  @Prop() readonly center: number[];
  @Prop() readonly zoom: number;

  @Watch("data.selectionData")
  onSelectionDataChange(obj: any) {
    const bbox = obj.breakdown?.bbox;
    console.log(obj)
    console.log("here ")

    if (bbox) {
      this.map.fitBounds(bbox, { duration: 1000, offset: [0, 0] });

    }
    this.mountPopup(obj[0])


  }
  @Watch("data.zones.features")
  onZonesChange(zones) {
    this.polygonId = Number(this.$route.query.polygonId);
    if (this.polygonId) {
      this.loadPolygonData(this.polygonId);
      const filteredZones = zones.find(obj => obj.properties.osm_id === this.polygonId);

      if (filteredZones) {
        this.goToBreakdown(filteredZones);
        // this.getBreakdownObjectsData(filteredZones);
        this.filterActiveLayersByID(this.polygonId)
        // this.addFtrToSel(filteredZones)
      }
    }
  }

  constructor() {
    super();
    this.data = {
      zoom: this.$store.getters.last_map_zoom ?? 10,
      center: this.$store.getters.last_map_center,
      selectionList: [],
      selectionData: [],
      maxBounds: common.OSM_MAX_BOUNDS,
      zones: source.ZONES_INIT_OBJECT,
    };
    this.layerList = [];
    this.layerStyles = {};
    this.layerFilters = {};
    this.layerSources = {};
    this.selectedObject = {};
    this.polygonId = null;
    this.geocodeApi = new GeocodeAPI();
    this.popup = new mapboxgl.Popup({
      closeOnClick: false,
      offset: [0, 0],
    });
  }

  mounted() {
    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN;
    this.map = new mapboxgl.Map(layer.MAP_INIT_LAYER);

    // Add search control
    const geocoder_api = this.geocodeApi.getApiSource();
    const options = tools.GEOCODER_OPTIONS;
    options.accessToken = mapboxgl.accessToken;
    options.maplibregl = mapboxgl;
    const geocoderContainer = new MapboxGeocoder(geocoder_api, options);
    this.geocodeApi.setSearchGroup(geocoderContainer, this.map);

    //Add geolocate control to the map.
    this.map.addControl(tools.GEOLOCATE_CONTROL);

    //Add compass
    this.map.addControl(tools.COMPASS_CONTROL);

    this.map.on("load", () => {
      this.loadMapData();

    });


    // Events
    this.map.on("click", layer.ZONES_POLYGON, (e) => {
      // this.toggleLegend(true);
      this.getBreakdownObjectsData(e.features![0]);
    });
    this.map.on("click", layer.ZONES_LINE, (e) => {
      this.toggleLegend(true);
      this.getBreakdownObjectsData(e.features![0].properties);
    });
    this.map.on("click", layer.ZONES_POINT, (e) => {
      this.toggleLegend(true);
      this.getBreakdownObjectsData(e.features![0].properties);
    });

    // Change the cursor to pointer when the mouse is over the layer.
    this.map.on("mouseenter", layer.ZONES_POLYGON, () => {
      this.map.getCanvas().style.cursor = "pointer";
    });
    this.map.on("mouseenter", layer.ZONES_LINE, () => {
      this.map.getCanvas().style.cursor = "pointer";
    });
    this.map.on("mouseenter", layer.ZONES_POINT, () => {
      this.map.getCanvas().style.cursor = "pointer";
    });

    // Change it back to default when it leaves.
    this.map.on("mouseleave", layer.ZONES_POLYGON, () => {
      this.map.getCanvas().style.cursor = "";
    });
    this.map.on("mouseleave", layer.ZONES_LINE, () => {
      this.map.getCanvas().style.cursor = "";
    });
    this.map.on("mouseleave", layer.ZONES_POINT, () => {
      this.map.getCanvas().style.cursor = "";
    });
    this.map.on("mousedown", this.rightClick);
  }

  public loadMapData() {
    getMapData().then((res) => {
      const data = res.data as any;
      data.forEach((def) => {
        //Populate local data
        this.layerList.push({
          id: def.layer.id,
          name: def.layer.layerName,
          type: def.layer.layerType.name,
        });
        this.layerSources[def.layer.id] = {
          sourceId: def.source.sourceName,
          sourceObj: JSON.parse(def.source.sourceObject),
        };
        this.layerStyles[def.layer.id] = {
          layout: JSON.parse(def.style.layout),
          paint: JSON.parse(def.style.paint),
        };
        if (def.filter) {
          this.layerFilters[def.layer.id] = {
            filter: JSON.parse(def.filter.condition),
            shortName: def.filter.shortName,
          };
        }
      });
      this.addMapData();
    });
  }
  public filterActiveLayersByID(osm_id) {
    this.clearSel()
    const selected = this.data.zones.features.filter(features => features?.properties?.osm_id === osm_id);
    if (selected.length > 0) {
      for (const feature of selected) {
        const selectionDataItem = {
          id: feature.properties?.osm_id,
          properties: feature.properties,
          geometry: feature.geometry,
          source: source.ZONES,
          // sourceLayer:layer.ZONES_POLYGON,
        };
        this.addFtrToSel(selectionDataItem);
      }

    }
  }
  public mountPopup(obj) {
    if(obj){
    if (obj.geom && obj.geom.coordinates && obj.geom.coordinates[0] && obj.geom.coordinates[0][0]) {
      const firstCoordinate = obj.geom.coordinates[0][0];
      const longitude = firstCoordinate[0];
      const latitude = firstCoordinate[1];

      if (typeof longitude === 'number' && typeof latitude === 'number') {
        this.popup
          .setLngLat([longitude, latitude])
          .setHTML(`<card  id="vue-popup-content"></card >`)
          .addTo(this.map);


        const PopupClass = Vue.extend(MapBoxCustomPopup);
        const popupClass = new PopupClass({
          propsData: {
            selectedElement: this.data.selectionData[0],
          },
        });

        popupClass.$mount("#vue-popup-content");

        
        this.popup._update();
      } else {
        console.error('Invalid coordinates:', firstCoordinate);
      }
    } else {
      console.error('Invalid geometry data:', obj.geom);
    }}
    else{
      this.popup.remove();
    }
  }
  public loadPolygonData(polygonID) {
    console.log(polygonID)
  }
  public updateUrlWithPolygonId(polygonId) {
    this.$router.push({ path: '/breakdownmap', query: { polygonId: polygonId } }).catch(err => {
      if (err.name !== 'NavigationDuplicated') {
        console.error(err);
      }
    });
  }

  public addMapData() {
    //Sources
    Object.keys(this.layerSources).forEach((key) => {
      const source = this.layerSources[key];
      if (!this.map.getSource(source.sourceId)) {
        this.map.addSource(source.sourceId, source.sourceObj as any);
      }
    });


    // Load source
    getPolygonsPRS().then((res) => {
      this.data.zones.features = res.data;
      console.log(this.data.zones.features)
      const polygonSrc = this.map.getSource(source.ZONES) as GeoJSONSource;
      polygonSrc.setData({ type: "FeatureCollection", features: res.data });
      // Polygon Popup
      // this.data.zones.features
      //   .filter((x) => x.geometry.type === "Polygon")
      //   .forEach((x) => {
      //     new mapboxgl.Popup({ closeOnClick: false })
      //       .setLngLat(x.geometry.coordinates[0][0])
      //       .setHTML(`${x.properties.name}`)
      //       .addTo(this.map);
      //   });

      this.setZoomAndCenter(
        this.data.zones.features.filter((x) => x.geometry.type === "Polygon"),
        this.data.zones.features.filter(
          (x) => x.geometry.type === "LineString"
        ),
        this.data.zones.features.filter((x) => x.geometry.type === "Point")
      );
      if (!this.polygonId) {
        this.setZoomAndCenter(
          this.data.zones.features.filter((x) => x.geometry.type === "Polygon"),
          this.data.zones.features.filter(
            (x) => x.geometry.type === "LineString"
          ),
          this.data.zones.features.filter((x) => x.geometry.type === "Point")
        );
      }
    });

    // Add layers
    this.layerList.forEach((l) => {
      const layerObj = {
        id: l.name,
        source: this.layerSources[l.id].sourceId,
        type: l.type,
        layout: this.layerStyles[l.id].layout,
        paint: this.layerStyles[l.id].paint,
      } as any;
      if (this.layerFilters[l.id]) {
        layerObj.filter = this.layerFilters[l.id].filter;
      }
      this.map.addLayer(layerObj);
    });
  }

  setZoomAndCenter(polygons = [], lines = [], points = []) {
    if (polygons.length > 0) {
      const center = polygons[polygons.length - 1].geometry.coordinates[0][0];
      this.map.setCenter(center);
      this.map.zoomTo(13, { duration: 2500, offset: [0, 0] });
    } else if (lines.length > 0) {
      const center = lines[lines.length - 1].geometry.coordinates[0];
      this.map.setCenter(center);
      this.map.zoomTo(18, { duration: 2500, offset: [0, 0] });
    } else if (points.length > 0) {
      const center = points[points.length - 1].geometry.coordinates;
      this.map.setCenter(center);
      this.map.zoomTo(18, { duration: 2500, offset: [0, 0] });
    }
  }

  public getBreakdownObjectsData(obj) {
    console.log(obj)
    // this.selectedObject = obj;
    this.addFtrToSel(obj)
    console.log("error is here")
    console.log(this.data.selectionData)
    this.updateUrlWithPolygonId(this.data.selectionData[0].props?.osm_id)

  }
  public clearSel() {
    this.data.selectionList.forEach((id, index) => {
      this.map.setFeatureState(
        {
          source: this.data.selectionData[index]["src"],
          id: id,
          sourceLayer: this.data.selectionData[index].sourceLayer,
        },
        { selected: false }
      );
    });
    this.data.selectionList = [];
    this.data.selectionData = [];
  }
  public rightClick(e) {
    if (e.originalEvent.button === 2) {
      this.clearSel();
    }
  }
  public goToBreakdown(item) {
    //   const bbox = item.breakdown?.bbox;
    // console.log(item)

    //   if(bbox){
    // console.log(bbox)
    //  this.map.fitBounds(bbox, { duration: 2500, offset: [0, 0] });
    // }
    const center = item.geometry.coordinates[0][0];
    this.map.setCenter(center);
    this.map.zoomTo(13, { duration: 2500, offset: [0, 0] });
    this.updateUrlWithPolygonId(item.properties.osm_id)
    this.filterActiveLayersByID(item.properties.osm_id)

  }
  public addFtrToSel(ftr) {
    this.clearSel()
    const idx = this.data.selectionList.indexOf(ftr.id);
    if (idx === -1) {
      this.data.selectionList.push(ftr.id);
      this.data.selectionData.push({
        props: ftr.properties,
        src: ftr.source,
        geom: ftr.geometry,
        sourceLayer: ftr.sourceLayer,
      });
      console.log("FRTTTT")
      console.log(ftr)
      this.map.setFeatureState(
        { source: ftr.source, id: ftr.id, sourceLayer: ftr.sourceLayer },
        { selected: true }
      );
    }
    this.populateSelectedDataToLayers();

  }
  public populateSelectedDataToLayers() {
    Object.keys(this.layerList).forEach((l) => {
      const layer = this.layerList[l];
      if (!layer.selectable && layer.name.toLowerCase().includes("select")) {
        this.map.setFilter(layer.name, [
          "in",
          "osm_id",
          ...this.data.selectionList,
        ] as any);
      }
    });
    console.log(this.data.selectionData)
    console.log(this.data.selectionList)
    console.log(this.selectedObject)

  }

  public toggleSidebarPanels(persist, elementID) {
    const elem = document.getElementById(elementID);
    if (elem) {
      const classes = elem.className.split(" ");
      const collapsed = classes.indexOf("collapsed") !== -1;
      if (collapsed) {
        classes.splice(classes.indexOf("collapsed"), 1);
      } else {
        if (!persist) {
          classes.push("collapsed");
        }
      }
      elem.className = classes.join(" ");
    }
  }

}
