/* eslint-disable */
import { MutationTree, ActionTree } from "vuex";
import { IAppStoreModule } from "./interfaces";

class AppState implements IAppStoreModule {
  public loading: boolean;
  public drawer: boolean;
  public locale: string;
  public isMobile: null | boolean;

  constructor() {
    this.loading = false;
    this.drawer = false;
    this.isMobile  = null;
    this.locale = "en";
  }
}

const mutations: MutationTree<AppState> = {
  TOGGLE_LOADING: (state) => {
    state.loading = !state.loading;
  },
  STOP_LOADING: (state) => {
    state.loading = false;
  },
  START_LOADING: (state) => {
    state.loading = true;
  },
  SET_DRAWER: (state, payload) => {
    state.drawer = payload;
  },
  SET_LOCALE: (state, locale) => {
    state.locale = locale;
  },
  SET_MOBLIE: (state, payload) => {
    state.isMobile = payload
  },
};

const actions: ActionTree<AppState, any> = {
  toggleLoading({ commit }) {
    commit("TOGGLE_LOADING");
  },
  stopLoading({ commit }) {
    commit("STOP_LOADING");
  },
  startLoading({ commit }) {
    commit("START_LOADING");
  },
};

const state = new AppState();

const AppModule = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default AppModule;
