
  import { Component, Vue } from 'vue-property-decorator';
  import CoreAppFooter from "./Footer.vue";

  interface ISrcs {
    [key: string]: string;
  }

  @Component({
    components: {
      CoreAppFooter
    }
  })
  export default class CoreAppView extends Vue {
    private srcs: ISrcs = {
      '/pages/lock': 'lock.jpg',
      '/pages/login': 'login.jpg',
    }


    get src(): string {
      return this.srcs[this.$route.path];
    }

    get styles () {
      return '';
    }
  }
