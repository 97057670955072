
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
  computed: {
  },
})
export default class Search extends Vue {
  constructor() {
    super();
  }
}
