
import { Component, Vue, Prop } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters([]),
  },
})
export default class SidebarMain extends Vue {
  public toggleIcon: string;
  public panel: number[];
  public showExternalSystems: boolean;
  public showOtherSystems: boolean;
  public showProjectSystems: boolean;
  public showOutOfUseSystems: boolean;
  public showUVMSystems: boolean;
  ;

  @Prop() readonly syslist: { id: number; show: boolean; name: string }[];
  @Prop() readonly objlist: { id: number; show: boolean; name: string }[];
  @Prop() readonly externalSystems: {
    id: number;
    show: boolean;
    name: string;
  }[];
  @Prop() readonly otherSystems: { id: number; show: boolean; name: string }[];
  @Prop() readonly projectSystems: {
    id: number;
    show: boolean;
    name: string;
  }[];
  @Prop() readonly outOfUseSystems: {
    id: number;
    show: boolean;
    name: string;
  }[];
  @Prop() readonly uvmSystems: { id: number; show: boolean; name: string }[];

  constructor() {
    super();

    this.toggleIcon = "mdi-graph-outline";
    this.panel = [0];

    this.showExternalSystems =
      Number(process.env.VUE_APP_SHOW_EXTERNAL_LAYERS) === 1 ? true : false;
    this.showOtherSystems =
      Number(process.env.VUE_APP_SHOW_OTHER_LAYERS) === 1 ? true : false;
    this.showProjectSystems =
      Number(process.env.VUE_APP_SHOW_PROJECT_LAYERS) === 1 ? true : false;
    this.showOutOfUseSystems =
      Number(process.env.VUE_APP_SHOW_OUT_OF_USE_LAYERS) === 1 ? true : false;
    this.showUVMSystems =
      Number(process.env.VUE_APP_SHOW_UVM_LAYERS) === 1 ? true : false;
      
  }

  public toggleLayers() {
    this.$emit("toggle-layers");
  }

  public toggleExternalLayers() {
    this.$emit("toggle-external-layers");
  }

  public toggleOtherLayers() {
    this.$emit("toggle-other-layers");
  }

  public toggleProjectLayers() {
    this.$emit("toggle-project-layers");
  }

  public toggleOutOfUseLayers() {
    this.$emit("toggle-out-of-use-layers");
  }

  public toggleUVMLayers() {
    this.$emit("toggle-uvm-layers");
  }

  public hideAllSymbolLayers() {
    this.$emit("hide-symbol-layers");
  }

  public showAllSymbolLayers() {
    this.$emit("show-symbol-layers");
  }

  public hideAllDrawnObject() {
    this.$emit("hide-drawn-layers");
  }

  public showAllDrawnObject() {
    this.$emit("show-drawn-layers");
  }

  public clearAllSelected() {
    this.$emit("clear-selected");
  }
  public showWaterMeter(item){

if (process.env.VUE_APP_SHOW_WATER_METERS == 1 || (item.id !== 3 && item.id !== 4)) {
  return true;
}

return false;
    
  }
  public toggleSidebar() {
    const elem = document.getElementById("sidebar-main");
    const filtersToggle = Array.from(
      document.getElementsByClassName(
        "sidebar-filter-toggle"
      ) as HTMLCollectionOf<HTMLElement>
    );
    const styleToggle = Array.from(
      document.getElementsByClassName(
        "sidebar-style-toggle"
      ) as HTMLCollectionOf<HTMLElement>
    );
    const layersToggle = Array.from(
      document.getElementsByClassName(
        "sidebar-layer-toggle"
      ) as HTMLCollectionOf<HTMLElement>
    );

    if (elem) {
      const classes = elem.className.split(" ");
      const collapsed = classes.indexOf("collapsed") !== -1;
      if (collapsed) {
        classes.splice(classes.indexOf("collapsed"), 1);

        setTimeout(() => {
          filtersToggle[0].style.display = "none";
          styleToggle[0].style.display = "none";
          layersToggle[0].style.display = "none";
          this.toggleIcon = "mdi-arrow-collapse-right";
        }, 100);
      } else {
        classes.push("collapsed");

        setTimeout(() => {
          filtersToggle[0].style.display = "flex";
          styleToggle[0].style.display = "flex";
          layersToggle[0].style.display = "flex";
          this.toggleIcon = "mdi-graph-outline";
        }, 550);
      }
      elem.className = classes.join(" ");
    }
  }
}
