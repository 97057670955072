/* eslint-disable */
import i18n from "@/i18n";

export default class ActiveDrawingService {
  setDrawingControlGroup() {
    const drawctrl = document.getElementsByClassName("mapboxgl-ctrl-group")[0];
    drawctrl.id = "draw_toolbar";

    const drawLineElement = document.getElementsByClassName(
      "mapbox-gl-draw_line"
    )[0] as HTMLButtonElement;
    drawLineElement.id = "draw_toolbar_line";
    drawLineElement.title = `${i18n.t("zone_line")}`;

    const drawPointElement = document.getElementsByClassName(
      "mapbox-gl-draw_point"
    )[0] as HTMLButtonElement;
    drawPointElement.id = "draw_toolbar_point";
    drawPointElement.title = `${i18n.t("zone_point")}`;

    const drawPolygonElement = document.getElementsByClassName(
      "mapbox-gl-draw_polygon"
    )[0] as HTMLButtonElement;
    drawPolygonElement.id = "draw_toolbar_poly";
    drawPolygonElement.title = `${i18n.t("zone_polygon")}`;

    const deleteElement = document.getElementsByClassName(
      "mapbox-gl-draw_trash"
    )[0] as HTMLButtonElement;
    deleteElement.id = "draw_toolbar_trash";
    deleteElement.title = `${i18n.t("delete")}`;

    const editbtn = document.createElement("button");
    editbtn.id = "draw_toolbar_edit_zone";
    editbtn.className = "mapbox-gl-draw_edit mapbox-gl-draw_ctrl-draw-btn";
    editbtn.title = `${i18n.t("drawing")}`;
    drawctrl.appendChild(editbtn);

    const editabtn = document.createElement("button");
    editabtn.id = "draw_toolbar_edit_zone_attr";
    editabtn.className =
      "mapbox-gl-draw_edit_attr mapbox-gl-draw_ctrl-draw-btn";
    editabtn.title = `${i18n.t("attributes")}`;
    drawctrl.appendChild(editabtn);

    const savebtn = document.createElement("button");
    savebtn.id = "draw_toolbar_save";
    savebtn.className = "mapbox-gl-draw_save mapbox-gl-draw_ctrl-draw-btn";
    savebtn.title = `${i18n.t("save")}`;
    drawctrl.appendChild(savebtn);

    const cancelbtn = document.createElement("button");
    cancelbtn.id = "draw_toolbar_cancel";
    cancelbtn.className = "mapbox-gl-draw_cancel mapbox-gl-draw_ctrl-draw-btn";
    cancelbtn.title = `${i18n.t("cancel_changes")}`;
    drawctrl.appendChild(cancelbtn);

    return {
      editbtn,
      editabtn,
      savebtn,
      cancelbtn,
    };
  }

  setMeasureControlGroup() {
    let measurectrl = document.getElementsByClassName("mapboxgl-ctrl-group")[0];
    if (!measurectrl) {
      measurectrl = document.createElement("div");
      measurectrl.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';
      const bottomLeftBtnGroup = document.getElementsByClassName(
        "mapboxgl-ctrl-bottom-left"
      )[0];
      bottomLeftBtnGroup.prepend(measurectrl)
    }

    const measurebtn = document.createElement("button");
    measurebtn.id = "draw_toolbar_measure";
    measurebtn.className =
      "mapbox-gl-draw_measure";
    measurebtn.title = `${i18n.t("measuring")}`;
    measurectrl.appendChild(measurebtn);

    const cancelMeasurebtn = document.createElement("button");
    cancelMeasurebtn.id = "draw_toolbar_cancel_measure";
    cancelMeasurebtn.className =
      "mapbox-gl-draw_cancel_measure hidebtn";
    cancelMeasurebtn.title = `${i18n.t("cancel_measuring")}`;
    measurectrl.appendChild(cancelMeasurebtn);

    const distcontainer = document.createElement("div");
    distcontainer.id = "distance";
    distcontainer.className = "distance-container";
    const mainWrap = document.getElementsByClassName(
      "v-main__wrap"
    )[0] as HTMLButtonElement;
    mainWrap?.appendChild(distcontainer);

    const centercontainer = document.createElement("div");
    centercontainer.id = "center_and_zoom";
    centercontainer.className = "center_and_zoom-container";
    mainWrap?.appendChild(centercontainer);

    return {
      measurebtn,
      cancelMeasurebtn,
    };
  }

  attributeModeSetUI() {
    document.getElementById("draw_toolbar_line")!.classList.add("hidebtn");
    document.getElementById("draw_toolbar_poly")!.classList.add("hidebtn");
    document.getElementById("draw_toolbar_point")!.classList.add("hidebtn");
    document.getElementById("draw_toolbar_trash")!.classList.add("hidebtn");
    document.getElementById("draw_toolbar_edit_zone")!.classList.add("hidebtn");
    document
      .getElementById("draw_toolbar_edit_zone_attr")!
      .classList.add("hidebtn");
    document.getElementById("draw_toolbar_save")!.classList.remove("hidebtn");
    document.getElementById("draw_toolbar_cancel")!.classList.remove("hidebtn");
  }

  noeditModeSetUI() {
    document.getElementById("draw_toolbar_line")!.classList.add("hidebtn");
    document.getElementById("draw_toolbar_poly")!.classList.add("hidebtn");
    document.getElementById("draw_toolbar_point")!.classList.add("hidebtn");
    document.getElementById("draw_toolbar_trash")!.classList.add("hidebtn");
    document
      .getElementById("draw_toolbar_edit_zone")!
      .classList.remove("hidebtn");
    document
      .getElementById("draw_toolbar_edit_zone_attr")!
      .classList.remove("hidebtn");
    document.getElementById("draw_toolbar_save")!.classList.add("hidebtn");
    document.getElementById("draw_toolbar_cancel")!.classList.add("hidebtn");
  }

  reshapeModeSetUI() {
    document.getElementById("draw_toolbar_line")!.classList.remove("hidebtn");
    document.getElementById("draw_toolbar_poly")!.classList.remove("hidebtn");
    document.getElementById("draw_toolbar_point")!.classList.remove("hidebtn");
    document.getElementById("draw_toolbar_trash")!.classList.remove("hidebtn");
    document.getElementById("draw_toolbar_edit_zone")!.classList.add("hidebtn");
    document
      .getElementById("draw_toolbar_edit_zone_attr")!
      .classList.add("hidebtn");
    document.getElementById("draw_toolbar_save")!.classList.remove("hidebtn");
    document.getElementById("draw_toolbar_cancel")!.classList.remove("hidebtn");
  }

  forbidByClientRights() {
    document.getElementById("draw_toolbar_line")!.classList.add("hidebtn");
    document.getElementById("draw_toolbar_poly")!.classList.add("hidebtn");
    document.getElementById("draw_toolbar_point")!.classList.add("hidebtn");
    document.getElementById("draw_toolbar_trash")!.classList.add("hidebtn");
    document.getElementById("draw_toolbar_edit_zone")!.classList.add("hidebtn");
    document
      .getElementById("draw_toolbar_edit_zone_attr")!
      .classList.add("hidebtn");
    document.getElementById("draw_toolbar_save")!.classList.add("hidebtn");
    document.getElementById("draw_toolbar_cancel")!.classList.add("hidebtn");
  }

  measureModeSetUI() {
    document.getElementById("draw_toolbar_measure")!.classList.add("hidebtn");
    document
      .getElementById("draw_toolbar_cancel_measure")!
      .classList.remove("hidebtn");
  }

  noMeasureModeSetUI() {
    document
      .getElementById("draw_toolbar_cancel_measure")!
      .classList.add("hidebtn");
    document
      .getElementById("draw_toolbar_measure")!
      .classList.remove("hidebtn");
  }
}
