import Vue, {ComponentOptions, AsyncComponent} from "vue";
import { _RouteConfigBase } from 'vue-router/types/router';
import VueRouter from "vue-router";

/* Layout */
import Layout from "@/layout/Layout.vue";

/* Pages */
import TMap from "@/views/ThematicMap.vue";
import TMapBreakdown from "@/views/BreakdownMap.vue";

Vue.use(VueRouter);

type Component = ComponentOptions<Vue> | typeof Vue | AsyncComponent
type Dictionary<T> = { [key: string]: T }


interface IRouteConfig extends _RouteConfigBase {
  hidden: boolean;
  // eslint-disable-next-line
  children?: any;
  // eslint-disable-next-line
  component?: Component;
  components?: Dictionary<Component>;
}

export interface IRoutingConfig_ extends IRouteConfig {
  children: IRouteConfig[];
}

export const constantRoutes: Array<IRoutingConfig_> = [
  {
    path: "/",
    name: "Начало",
    component: Layout,
    redirect: "thematicmap",
    meta: {
      title: "Начало",
      icon: "airplay",
      roleKey: 1,
      requiresAuth: true
    },
    hidden: true,
    children: [
      
    ]
  }
];

export const asyncRoutes = [
  {
    path: "/",
    name: "Начало",
    component: Layout,
    redirect: "/",
    meta: {
      title: "Начало",
      icon: "airplay",
      roleKey: "1",
    },
    hidden: true,
    children: [
      {
        path: "/thematicmap",
        name: "theme-map",
        components: { default: TMap },
        meta: {
          title: "theme-map",
          roleKey: "2",
          icon: "map",
        },
        hidden: true
      },
      {
        path: "/breakdownmap",
        name: "breakdown-map",
        components: { default: TMapBreakdown },
        meta: {
          title: "breakdown-map",
          roleKey: "2",
          icon: "map",
        },
        hidden: true
      }
    ]
  }
];


const createRouter = () => new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: constantRoutes.concat(asyncRoutes)
});

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter()
  // eslint-disable-next-line
  // @ts-ignore
  router.matcher = newRouter.matcher // reset router
}


export default router;