
import { Component, Vue } from "vue-property-decorator";
import { TMap } from "@/components/";

@Component({
  components: {
    TMap
  },
  computed: {
  },
})
export default class MMap extends Vue {
  // eslint-disable-next-line
  public timer: any;

  constructor() {
    super();
    this.timer = null;
  }
}
