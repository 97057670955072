
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import common from "@/utils/constants/map/common";
import { mapGetters } from "vuex";
import {
  Size,
  PageOrientation,
  Format,
  DPI,
} from "@/external/print-lib/lib/map-generator";
import FontPicker from "font-picker-vue";
import lodash from "lodash";

interface IFontOptions {
  apiKey: string;
  activeFont: string;
  limit: number;
  sort: string;
}
interface IPrintAttrObject {
  pageFormat: any;
  orientation: string;
  fileType: string;
  resolution: number | null;
  scale: string;
  color: string;
  fontStyle: string;
  fontSize: number;
  legend: boolean;
  drawingKey: number | null;
  object: string;
  date: string;
  contractor: string;
  preparedBy: string;
  signature: string;
  docNumber: string;
  subObject: string;
  system: string;
  coordSystem: string;
}
@Component({
  components: {
    FontPicker,
  },
  computed: {
    ...mapGetters([]),
  },
})
export default class ExportAttributes extends Vue {
  public panel: number[];
  public data: IPrintAttrObject;
  public preview: boolean;
  public options: IFontOptions;
  public gridType: object[];
  public gridData: any;
  public isScaleOutOfFocus: boolean;

  @Prop() readonly scale: string;

  constructor() {
    super();
    this.panel = [0];
    this.options = {
      apiKey: "AIzaSyDV002z2DjlwIMV32ju3KpV_HTIjOzbUEY",
      activeFont: "Times New Roman",
      limit: 50,
      sort: "popularity",
    };

    this.gridData = lodash.cloneDeep(common.GRID_DATA);
    this.gridType = lodash.cloneDeep(common.GRID_TYPES);
    this.data = lodash.cloneDeep(common.INITIAL_EXPORT_ATTRIBUTES);

    this.preview = false;
    this.isScaleOutOfFocus = false;
  }

  get getSize() {
    const list: any[] = [];
    Object.keys(Size).forEach((x) => {
      list.push({ key: x, value: Size[x] });
    });

    this.storeExportData(this.data);

    return list;
  }

  get getOrientation() {
    const list: any[] = [];
    Object.keys(PageOrientation).forEach((x) => {
      list.push({ key: x, value: PageOrientation[x] });
    });

    this.storeExportData(this.data);

    return list;
  }

  get getFormat() {
    const list: any[] = [];
    Object.keys(Format).forEach((x) => {
      list.push({ key: x, value: Format[x] });
    });

    this.storeExportData(this.data);

    return list;
  }

  get getDPI() {
    const list: any[] = [];
    Object.keys(DPI).forEach((x) => {
      list.push({ key: x, value: DPI[x] });
    });

    if (this.data.pageFormat.reduce((a, b) => a + b, 0) > 1400) {
      list.splice(3, 2);
      this.data.resolution = 200;
    }

    this.storeExportData(this.data);

    return list;
  }

  // set computedScale(newVal) {
  //   this.data.scale = newVal;
  // }

  // get computedScale() {
  //   return this.scale;
  // }

  onScaleChange() {
    if (this.scale && !this.data.scale) {
      this.data.scale = this.scale;
    }

    this.isScaleOutOfFocus = true;
    this.storeExportData(this.data);
    this.$emit("scale-changed", this.data.scale);
  }

  handleFontChange(selectedFont: any) {
    this.data.fontStyle = selectedFont.family;
    this.storeExportData(this.data);
  }

  sizeValidation(value) {
    const size = parseInt(value, 10);
    if (isNaN(size) || size < 12 || size > 24) {
      return "Височината трябва да бъде между 12 и 24 пиксела";
    }
    return true;
  }

  setColor(e) {
    this.data.color = e.hex;
    this.storeExportData(this.data);
  }

  onPageSizeChange(e) {
    this.storeExportData(this.data);
  }

  onResolutionChange(e) {
    this.storeExportData(this.data);
  }

  onFileTypeChange(e) {
    this.storeExportData(this.data);
  }

  onOrientationChange(e) {
    this.storeExportData(this.data);
  }

  onDrawingHeaderChange() {
    Object.keys(this.gridData).forEach((key) => {
      if (this.gridData[key].container.includes(this.data.drawingKey)) {
        this.gridData[key].show = true;
      } else {
        this.gridData[key].show = false;
      }
    });
    this.storeExportData(this.data);
  }

  public scaleChecker() {
    if (this.data.scale == "") {
      this.data.scale = this.scale;
      this.storeExportData(this.data);
    }
  }

  public storeExportData(data) {
    this.$store.dispatch("map/changeExportFeature", data);
  }

  public clearInitionData() {
    this.isScaleOutOfFocus = false;
    this.data = lodash.cloneDeep(common.INITIAL_EXPORT_ATTRIBUTES);
    this.storeExportData(this.data);
  }
}
