
  import { Component, Vue, Prop } from "vue-property-decorator";
  import { mapGetters } from "vuex";
  import { formatDateTime ,formatDateCusom} from "@/utils";

  
  @Component({
    components: {},
    computed: {
      ...mapGetters([]),
    },
  })
  export default class MapBoxCustomPopup extends Vue {
    public explStatus: any;
    public valveStateModel: any;
  
    @Prop() readonly selectedElement: any;

  
    constructor() {
      super();
      this.explStatus = [];
      this.valveStateModel = {
        valve_id: null,
        position_id: null,
        condition_id: null,
        operational_id: null,
        percent_open: null,
        note: "",
      };
    }
  
   public  formatDate(dateStr) {
     return   formatDateTime(dateStr)
   
    }

   
  }
  