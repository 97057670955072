import request from '@/utils/request';

export function getMapWMSLayers() {
  return request({
    url: process.env.VUE_APP_API_BACKEND + "WMS/GetWMSLayers",
    method: 'get'
  }) as any
}

export function sendBreakdown(data) {
  return request({
    url: process.env.VUE_APP_BREAKDOWN_BACKEND + 'VisoftReporting/CreateWorkCard',
    method: 'post',
    data: data
  })
}

export function setSupersetConfig(data) {
  return request({
    url: process.env.VUE_APP_API_BACKEND + 'Warehouse/SetSupersetConfig',
    method: 'post',
    data: data
  })
}

export function loadAllAttributes(ids, src) {
  return request({
    url: process.env.VUE_APP_API_BACKEND + 'Warehouse/GetAttributes',
    method: 'post',
    data: ids,
    params: { projectID: process.env.VUE_APP_PROJECTID, source: src }
  })
}

export function searchByPropertyValue(data) {
  return request({
    url: process.env.VUE_APP_API_BACKEND + 'Warehouse/SearchByPropertyValue',
    method: 'post',
    params: { projectID: process.env.VUE_APP_PROJECTID },
    data: data
  })
}

export function searchByCadastre(value) {
  return request({
    url: process.env.VUE_APP_API_BACKEND + 'Warehouse/SearchByCadastre',
    method: 'get',
    params: { value: value },
  })
}

export function exportFull(projection) {
  return request({
    url: process.env.VUE_APP_API_BACKEND + 'Export/ExportToShape',
    method: 'post',
    responseType: "blob",
    data: {
      mode: 'full',
      projection: projection
    }
  })
}

export function exportBox(minLat, minLng, maxLat, maxLng, projection) {
  return request({
    url: process.env.VUE_APP_API_BACKEND + 'Export/ExportToShape',
    method: 'post',
    responseType: "blob",
    data: {
      mode: 'box',
      minlat: minLat,
      minlng: minLng,
      maxlat: maxLat,
      maxlng: maxLng,
      projection: projection
    }
  })
}

export function searchPhoto(id) {
  return request({
    url: process.env.VUE_APP_BREAKDOWN_BACKEND + 'OSMDocuments/List',
    method: 'get',
    params: { osmNodeId: id },
  })
}
export function deletePhoto(id) {
  return request({
    url: process.env.VUE_APP_BREAKDOWN_BACKEND + 'OSMDocuments/Delete',
    method: 'get',
    params: { docId: id },
  })
}


export function sendPhoto(osm_id,FormData) {
  return request({
    url: process.env.VUE_APP_BREAKDOWN_BACKEND + 'OSMDocuments/Create',
    method: 'post',
    responseType: "blob",
    data:FormData,
    params: { osmNodeId: osm_id },

  })
}
export function downloadPhoto(id) {
  return request({
    url: process.env.VUE_APP_BREAKDOWN_BACKEND + 'OSMDocuments/Download',
    method: 'get',
    responseType: 'blob',
    params: { docId: id },
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'image.png'); 
    document.body.appendChild(link);
    link.click();
  });
}

export function getAddressGoogle(term) {
  return request({
    url: process.env.VUE_APP_API_BACKEND + 'Warehouse/SearchByAddress',
    method: 'get',
    params: { address: term },
  })
}

export function getAddressLocal(term) {
  return request({
    url: process.env.VUE_APP_ADDRESS_SEARCH,
    method: 'get',
    params: { q: term, limit: 10, format: 'jsonv2' },
  })
}
