/* eslint-disable */

const WATERLINES = 'waterlines';
const WATERPOINTS = 'waterpoints';
const CANALLINES = 'canallines';
const CANALPOINTS = 'canalpoints';
const SELECT_WATERLINE = 'select-line-water';
const SELECT_CANALLINE = 'select-line-canal';
const SELECT_WATERPOINT = 'select-point-water';
const SELECT_CANALPOINT = 'select-point-canal';
const ZONES_POLYGON = "zones_poly";
const ZONES_LINE = "zones_line";
const ZONES_POINT = "zones_point";
const WATERLINES_LABEL = "waterlines-label";
const WATERPOINTS_LABEL = "waterpoints-label";
const CANALLINES_LABEL = "canallines-label";
const CANALPOINTS_LABEL = "canalpoints-label";
const MEASURE_POINTS = "measure-points";
const MEASURE_LINES = "measure-lines";
const ELECTRICLINES = "electriclines";
const GASLINES = "gaslines";
const COMMUNICATIONLINES = "communicationlines";
const POWERLINES = "powerlines";
const SIGNALLINES = "signallines";
const FENCELINES = "fences";
const OTHERLINES = "otherlines";
const OTHERPOINTS = "otherpoints";
const OTHERPOINTS_LABEL = "otherpoints-label";
const OTHERAREAL = "otherareal";
const AREAL_POLY_WATER = "areal_poly_water";
const AREAL_POLY_CANAL = "areal_poly_canal";
const AREAL_LINE_WATER = "areal_poly_water-line";
const AREAL_LINE_CANAL = "areal_poly_canal-line";
const SELECT_AREAL_WATER = "select-areal-water";
const SELECT_AREAL_CANAL = "select-areal-canal";
const AREAL_POLY_WATER_LABEL = "areal_poly_water-label";
const AREAL_POLY_CANAL_LABEL = "areal_poly_canal-label";
const SELECT_ZONES_POLY = "select-zone-poly";
const SELECT_ZONES_LINE = "select-zone-line";
const SELECT_ZONES_POINT = "select-zone-point";
const LOGGER = "logger";
const LOGGER_LABEL = "logger-label";
const WDETAILPOINTS = "wdetailpoint"
const WDETAILPOINTS_LABEL = "wdetailpoint-label"
const ZONES_POLYGON_LABEL = "zone_poly-label";
const ZONES_LINE_LABEL = "zone_line-label";
const ZONES_POINT_LABEL = "zone_point-label";

const PROJECT_LINE_WATER = "project_lines_water";
const PROJECT_POINT_WATER = "project_points_water";
const PROJECT_POLYGON_WATER = "project_polygons_water";
const PROJECT_LINE_LABEL_WATER = "project_lines_water-label";
const PROJECT_POINT_LABEL_WATER = "project_points_water-label";
const PROJECT_POLYGON_LABEL_WATER = "project_polygons_water-label";
const SELECT_PROJECT_LINE_WATER = "select-project-lines_water";
const SELECT_PROJECT_POINT_WATER = "select-project-points_water";
const SELECT_PROJECT_POLYGON_WATER = "select-project-polygons_water";
const PROJECT_LINE_CANAL = "project_lines_canal";
const PROJECT_POINT_CANAL = "project_points_canal";
const PROJECT_POLYGON_CANAL = "project_polygons_canal";
const PROJECT_LINE_LABEL_CANAL = "project_lines_canal-label";
const PROJECT_POINT_LABEL_CANAL = "project_points_canal-label";
const PROJECT_POLYGON_LABEL_CANAL = "project_polygons_canal-label";
const SELECT_PROJECT_LINE_CANAL = "select-project-lines_canal";
const SELECT_PROJECT_POINT_CANAL = "select-project-points_canal";
const SELECT_PROJECT_POLYGON_CANAL = "select-project-polygons_canal";

const WATERMETERS_RESIDENTIAL = "watermeters_residential";
const SELECT_WATERMETERS_RESIDENTIAL = "select-watermeters_residential";
const WATERMETERS_RESIDENTIAL_LABEL = "watermeters_residential-label";
const WATERMETERS_PUBLIC = "watermeters_public";
const SELECT_WATERMETERS_PUBLIC = "select-watermeters_public";
const WATERMETERS_PUBLIC_LABEL = "watermeters_public-label";

const OUTOFSERVICE_WATERLINES = "out_of_service_waterlines";
const OUTOFSERVICE_WATERPOINTS = "out_of_service_waterpoints";
const OUTOFSERVICE_CANALLINES = "out_of_service_canallines";
const OUTOFSERVICE_CANALPOINTS = "out_of_service_canalpoints";
const OUTOFSERVICE_WATERPOINTS_LABEL = "out_of_service_waterpoints-label";
const OUTOFSERVICE_CANALPOINTS_LABEL = "out_of_service_canalpoints-label";
const SELECT_OUTOFSERVICE_WATERLINES = "select-outofservice-waterlines";
const SELECT_OUTOFSERVICE_WATERPOINTS = "select-outofservice-waterpoints";
const SELECT_OUTOFSERVICE_CANALLINES = "select-outofservice-canallines";
const SELECT_OUTOFSERVICE_CANALPOINTS = "select-outofservice-canalpoints";
const SANITARY_SECURITY_ZONES = "sanitary_security_zones";
const SANITARY_SECURITY_ZONES_LINE = "sanitary_security_zones-line";
const SANITARY_SECURITY_ZONES_LABEL = "sanitary_security_zones-label";
const SELECT_SANITARY_SECURITY_ZONES = "select-sanitary-security-zones";
const PER_ZONES = "per_zones";
const PER_ZONES_LABEL = "per_zones-label";
const SELECT_PER_ZONES = "select-per-zones";

const MAP_INIT_LAYER = {
    container: "map",
    style: "mapbox://styles/aquagis/cljwpwo6j024301pjefif51ln",
    center: JSON.parse(process.env.VUE_APP_OSM_CENTER),
    boxZoom: false,
    zoom: 9,
    minZoom: 3,
    maxZoom: 22,
    preserveDrawingBuffer: true,
} as any

export default {
    WATERLINES,
    WATERPOINTS,
    CANALLINES,
    CANALPOINTS,
    MAP_INIT_LAYER,
    SELECT_WATERLINE,
    SELECT_CANALLINE,
    SELECT_WATERPOINT,
    SELECT_CANALPOINT,
    ZONES_POLYGON,
    ZONES_LINE,
    ZONES_POINT,
    WATERLINES_LABEL,
    WATERPOINTS_LABEL,
    CANALLINES_LABEL,
    CANALPOINTS_LABEL,
    MEASURE_POINTS,
    MEASURE_LINES,
    ELECTRICLINES,
    GASLINES,
    COMMUNICATIONLINES,
    POWERLINES,
    SIGNALLINES,
    FENCELINES,
    OTHERLINES,
    OTHERPOINTS,
    OTHERPOINTS_LABEL,
    OTHERAREAL,
    AREAL_POLY_WATER,
    AREAL_POLY_CANAL,
    AREAL_LINE_WATER,
    AREAL_LINE_CANAL,
    SELECT_AREAL_WATER,
    SELECT_AREAL_CANAL,
    AREAL_POLY_WATER_LABEL,
    AREAL_POLY_CANAL_LABEL,
    LOGGER,
    LOGGER_LABEL,
    WDETAILPOINTS,
    WDETAILPOINTS_LABEL,
    SELECT_ZONES_LINE,
    SELECT_ZONES_POINT,
    SELECT_ZONES_POLY,
    ZONES_POLYGON_LABEL,
    ZONES_LINE_LABEL,
    ZONES_POINT_LABEL,
    PROJECT_LINE_WATER,
    PROJECT_POINT_WATER,
    PROJECT_POLYGON_WATER,
    PROJECT_LINE_LABEL_WATER,
    PROJECT_POINT_LABEL_WATER,
    PROJECT_POLYGON_LABEL_WATER,
    SELECT_PROJECT_LINE_WATER,
    SELECT_PROJECT_POINT_WATER,
    SELECT_PROJECT_POLYGON_WATER,
    PROJECT_LINE_CANAL,
    PROJECT_POINT_CANAL,
    PROJECT_POLYGON_CANAL,
    PROJECT_LINE_LABEL_CANAL,
    PROJECT_POINT_LABEL_CANAL,
    PROJECT_POLYGON_LABEL_CANAL,
    SELECT_PROJECT_LINE_CANAL,
    SELECT_PROJECT_POINT_CANAL,
    SELECT_PROJECT_POLYGON_CANAL,
    WATERMETERS_RESIDENTIAL,
    SELECT_WATERMETERS_RESIDENTIAL,
    WATERMETERS_RESIDENTIAL_LABEL,
    WATERMETERS_PUBLIC,
    SELECT_WATERMETERS_PUBLIC,
    WATERMETERS_PUBLIC_LABEL,
    OUTOFSERVICE_WATERLINES,
    OUTOFSERVICE_WATERPOINTS,
    OUTOFSERVICE_CANALLINES,
    OUTOFSERVICE_CANALPOINTS,
    OUTOFSERVICE_WATERPOINTS_LABEL,
    OUTOFSERVICE_CANALPOINTS_LABEL,
    SELECT_OUTOFSERVICE_WATERLINES,
    SELECT_OUTOFSERVICE_WATERPOINTS,
    SELECT_OUTOFSERVICE_CANALLINES,
    SELECT_OUTOFSERVICE_CANALPOINTS,
    PER_ZONES,
    PER_ZONES_LABEL,
    SELECT_PER_ZONES,
    SANITARY_SECURITY_ZONES,
    SANITARY_SECURITY_ZONES_LINE,
    SANITARY_SECURITY_ZONES_LABEL,
    SELECT_SANITARY_SECURITY_ZONES
}

// TODO - this is sample for 3d buildings...still not working
// this.map.addLayer({
//     id: "add-3d-buildings",
//     source: "composite",
//     "source-layer": "building",
//     filter: ["==", "extrude", "true"],
//     type: "fill-extrusion",
//     minzoom: 15,
//     paint: {
//       "fill-extrusion-color": "#aaa",
//       "fill-extrusion-height": [
//         "interpolate",
//         ["linear"],
//         ["zoom"],
//         15,
//         0,
//         15.05,
//         ["get", "height"],
//       ],
//       "fill-extrusion-base": [
//         "interpolate",
//         ["linear"],
//         ["zoom"],
//         15,
//         0,
//         15.05,
//         ["get", "min_height"],
//       ],
//       "fill-extrusion-opacity": 0.6,
//     },
//   });