import Vue from "vue";
import Vuetify from "vuetify";
import '@/sass/overrides.sass';
import i18n from '@/i18n'

Vue.use(Vuetify);

const theme = {
  primary: '#263238',
  secondary: '#263238',
  accent: '#9C27b0',
  info: '#00CAE3',
  error: '#ba240d',
  water: '#1588c2',
  canal: '#a86c45'
}

export default new Vuetify({
  lang: {
    //@ts-ignore
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  }
});