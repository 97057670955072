import { IRootState } from "./index";
const getters = {
  loading: (state: IRootState) => state.app.loading,
  token: (state: IRootState) => state.user.token,
  username: (state: IRootState) => state.user.username,
  role: (state: IRootState) => state.user.role,
  isLogged: (state: IRootState) => state.user.isLogged,
  last_map_center: (state: IRootState) => state.map.last_map_center,
  last_map_zoom: (state: IRootState) => state.map.last_map_zoom,
  selected_features: (state: IRootState) => state.map.selected_features,
  tag_refs: (state: IRootState) => state.map.tag_refs,
  zones_attributes: (state: IRootState) => state.map.zones_attributes,
  zones_attributes_edit_mode: (state: IRootState) => state.map.zones_attributes_edit_mode,
  zone_target_attribute: (state: IRootState) => state.map.zone_target_attribute,
  drawer: (state: IRootState) => state.app.drawer,
  isMobile: (state: IRootState) => state.app.isMobile,
  isProduction: (state) => state.app.production,
  search_feature: (state) => state.map.search_feature,
  search_cadastre: (state) => state.map.search_cadastre,
  export_feature: (state) => state.map.export_feature,
  color_data: (state) => state.map.color_data,
  label_data: (state) => state.map.label_data,
  diameter_data: (state) => state.map.diameter_data,
  reverse_color_data: (state) => state.map.reverse_color_data,
  reverse_label_data: (state) => state.map.reverse_label_data,
  reverse_diameter_data: (state) => state.map.reverse_diameter_data,
}

export default getters;