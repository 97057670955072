import i18n from "@/i18n";

const OSM_MAX_BOUNDS = [
  [41.34176252711261, 22.94906616210938],
  [43.7368715467591, 28.578872680664066],
];

const SYSTEMS = [//water
  { id: 0, show: true, name: `${i18n.t("water")}` },
  { id: 1, show: true, name: `${i18n.t("sewer")}` }
];

const OBJECTS = [
  [//Water
    { id: 0, show: true, name: `${i18n.t("linear")}` },
    { id: 1, show: true, name: `${i18n.t("points")}` },
    { id: 2, show: true, name: `${i18n.t("areal_objects")}` },
  ],
  [//Sewer
    { id: 0, show: true, name: `${i18n.t("linear")}` },
    { id: 1, show: true, name: `${i18n.t("points")}` },
    { id: 2, show: true, name: `${i18n.t("areal_objects")}` },
  ],
];

const EXTERNAL_SYSTEMS = [
  { id: 0, show: false, name: `${i18n.t("electric_network")}` },
  { id: 1, show: false, name: `${i18n.t("gas_distribution_network")}` },
  { id: 2, show: false, name: `${i18n.t("communication_network")}` },
  { id: 3, show: false, name: `${i18n.t("detailed_points")}` },
];

const OTHER_SYSTEMS = [
  { id: 0, show: false, name: `${i18n.t("signal_cables")}` },
  { id: 1, show: false, name: `${i18n.t("power_cables")}` },
  { id: 2, show: false, name: `${i18n.t("fence")}` },
  { id: 3, show: false, name: `${i18n.t("other_lines")}` },
  { id: 4, show: false, name: `${i18n.t("other_points")}` },
  { id: 5, show: false, name: `${i18n.t("other_areal")}` },
];

const PROJECT_SYSTEMS = [
  [
    { id: 0, show: false, name: `${i18n.t("project_line_water")}` },
    { id: 1, show: false, name: `${i18n.t("project_point_water")}` },
    { id: 2, show: false, name: `${i18n.t("project_polygon_water")}` }
  ],
  [
    { id: 0, show: false, name: `${i18n.t("project_line_canal")}` },
    { id: 1, show: false, name: `${i18n.t("project_point_canal")}` },
    { id: 2, show: false, name: `${i18n.t("project_polygon_canal")}` }
  ]
];

const OUT_OF_USE_SYSTEMS = [
  [
    { id: 0, show: false, name: `${i18n.t("linear")}` },
    { id: 1, show: false, name: `${i18n.t("points")}` },
  ],
  [
    { id: 0, show: false, name: `${i18n.t("linear")}` },
    { id: 1, show: false, name: `${i18n.t("points")}` },
  ]
];

const UVM_SYSTEMS = [
  { id: 0, show: false, name: `${i18n.t("sanitary_security_zones")}` },
  { id: 1, show: false, name: `${i18n.t("per")}` }
];

const COORDINATE_SYSTEMS = [
  {
    id: 1,
    key: "+proj=longlat +datum=WGS84 +no_defs",
    value: "WGS84",
    projection: "naturalEarth"
  },
  {
    id: 2,
    key: "+proj=laea +lat_0=52 +lon_0=10 +x_0=4321000 +y_0=3210000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    value: "ETRS89/ETRS - LAEA",
    projection: "mercator"
  },
  {
    id: 3,
    key: "+proj=lcc +lat_0=52 +lon_0=10 +lat_1=35 +lat_2=65 +x_0=4000000 +y_0=2800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    value: "ETRS89/ETRS89 - LCC",
    projection: "mercator"
  },
  {
    id: 4,
    key: "+proj=lcc +lat_0=42.6678756833333 +lon_0=25.5 +lat_1=42 +lat_2=43.3333333333333 +x_0=500000 +y_0=4725824.3591 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    value: "BGS 2005 Geodetic",
    projection: "lambertConformalConic"
  },
  {
    id: 5,
    key: "+proj=utm +zone=34 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    value: "BGS 2005 UTM34",
    projection: "mercator"
  },
  {
    id: 6,
    key: "+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs",
    value: "BGS 2005 UTM35",
    projection: "mercator"
  },

];

const GRID_TYPES = [
  { value: "Без антетка", key: 0 },
  { value: "A4-A3 кратка: обект, дата, мащаб", key: 1 },
  { value: "A4-A3 стандартна: кратка + възложител, изготвил", key: 2 },
  { value: "A4-A3 голяма: стандартна + подпис", key: 3 },
  { value: "A3 пълна", key: 4 },
]
const GRID_DATA = {
  Object: { description: `${i18n.t("object")}`, show: false, container: [1, 2, 3, 4] },
  Date: { description: `${i18n.t("date")}`, show: false, container: [1, 2, 3, 4] },
  Scale: { description: `${i18n.t("scale")}`, show: false, container: [1, 2, 3, 4] },
  Contractor: { description: `${i18n.t("contractor")}`, show: false, container: [2, 3, 4] },
  PreparedBy: { description: `${i18n.t("prepared_by")}`, show: false, container: [2, 3, 4] },
  Signature: { description: `${i18n.t("signature")}`, show: false, container: [3] },
  DocNumber: { description: `${i18n.t("doc_number")}`, show: false, container: [4] },
  SubObject: { description: `${i18n.t("sub_object")}`, show: false, container: [4] },
  System: { description: `${i18n.t("system")}`, show: false, container: [4] },
  CoordSystem: { description: `${i18n.t("coord_system")}`, show: false, container: [4] },
}

const INITIAL_EXPORT_ATTRIBUTES = {
  pageFormat: [297, 210],
  orientation: "landscape",
  fileType: "pdf",
  resolution: 200,
  scale: "",
  color: "",
  fontStyle: "Times New Roman",
  fontSize: 14,
  legend: false,
  drawingKey: 0,
  object: "",
  date: "",
  contractor: "",
  preparedBy: "",
  signature: "",
  docNumber: "",
  subObject: "",
  system: "",
  coordSystem: "",
};

export default {
  OSM_MAX_BOUNDS,
  SYSTEMS,
  OBJECTS,
  EXTERNAL_SYSTEMS,
  OTHER_SYSTEMS,
  PROJECT_SYSTEMS,
  COORDINATE_SYSTEMS,
  GRID_TYPES, GRID_DATA,
  INITIAL_EXPORT_ATTRIBUTES,
  OUT_OF_USE_SYSTEMS,
  UVM_SYSTEMS
};
