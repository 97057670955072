import i18n from "@/i18n";
import { getAddressGoogle, getAddressLocal } from "@/api/map";

export default class Geocode {
  private isGoogleSearch = Number(process.env.VUE_APP_SEARCH_BY_GOOGLE) === 1 ? true : false;

  getApiSource() {
    const geocode = {
      forwardGeocode: async (config) => {
        let features = [] as any;
        try {
          if (this.isGoogleSearch)
            features = await this.callGoogleEngine(config, features);
          else
            features = await this.callLocalEngine(config, features);

        } catch (e) {
          console.error(`${i18n.t('address_not_found')}: ${e}`);
        }
        return {
          features: features,
        };
      },
    };
    return geocode;
  }

  private async callLocalEngine(config, features) {
    const response = await getAddressLocal(config.query);
    const localItems = await response.data;

    features = this.interpretLocalItems(localItems, features);

    return features;
  }

  private async callGoogleEngine(config, features) {
    const response = await getAddressGoogle(config.query);
    const data = await response.data;
    const geojsonGoogle = JSON.parse(data.google);
    const geojsonHere = JSON.parse(data.hire);

    let googleItems = [];
    if (geojsonGoogle) googleItems = geojsonGoogle;

    let hereItems = [];
    if (geojsonHere) hereItems = geojsonHere.items;

    features = this.interpretGoogleItems(googleItems, features);
    features = this.interpretHereItems(hereItems, features);

    return features;
  }

  private interpretLocalItems(items, features) {
    for (const item of items) {
      const center = [item.lon, item.lat];
      const point = {
        type: item.type,
        geometry: {
          type: "Point",
          coordinates: center,
        },
        place_name: item.display_name,
        properties: item,
        text: item.display_name,
        place_type: [item.osm_type],
        center: center,
      } as never;
      features.push(point);
    }
    return features;
  }

  private interpretGoogleItems(items, features) {
    for (const item of items) {
      const center = [item.geometry.location.lng, item.geometry.location.lat];
      const point = {
        type: item.geometry.location_type,
        geometry: {
          type: "Point",
          coordinates: center,
        },
        place_name: item.formatted_address,
        properties: item,
        text: item.formatted_address,
        center: center,
      } as never;
      features.push(point);
    }
    return features;
  }

  private interpretHereItems(items, features) {
    for (const item of items) {
      const center = [item.position.lng, item.position.lat];
      const point = {
        type: item.resultType,
        geometry: {
          type: "Point",
          coordinates: center,
        },
        place_name: item.title,
        properties: item,
        text: item.title,
        center: center,
      } as never;
      features.push(point);
    }
    return features;
  }

  setSearchGroup(container, map) {
    document.getElementById("geocoder-search")!.appendChild(container.onAdd(map));
    const input = Array.from(
      document.getElementsByClassName("mapboxgl-ctrl-geocoder--input") as HTMLCollectionOf<HTMLElement>
    );

    //If search address box is in focus hide search by attribute and search by cadastre
    input[0].addEventListener('focusin', () => {
      const searchAttrInput = document.getElementById("attribute-search");
      searchAttrInput!.style.display = "none";
      const searchCADASTREInput = document.getElementById("cadastre-search");
      searchCADASTREInput!.style.display = "none";
    });
    //If search address box is out if focus show search by attribute and search by cadastre
    input[0].addEventListener('focusout', () => {
      const searchAttrInput = document.getElementById("attribute-search");
      searchAttrInput!.style.display = "block";
      const searchCADASTREInput = document.getElementById("cadastre-search");
      searchCADASTREInput!.style.display = "block";
    });
  }
}
