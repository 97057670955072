
import { searchByPropertyValue } from "@/api/map";
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { getPropertiesToSearchBy } from "@/api/nomenclatures";
import i18n from "@/i18n";

@Component({
  components: {
  },
  computed: {
    ...mapGetters(["tag_refs","isMoblie"]),
  },
})
export default class SearchByProperty extends Vue {
  public dataContainer: object[];
  public selectedData: object;
  public loading: boolean;
  public panel: number[];
  public innerPanel: number[];
  public searchProperties: any[];
  public noObjectMsg: string;
  public termData: { field: string | null; term: string | null };

  @Watch("$store.getters.token")
  onBearerTokenPresent() {
    this.loadPropertiesToSearchBy();
  }

  constructor() {
    super();
    this.dataContainer = [];
    this.selectedData = {};
    this.loading = false;
    this.panel = [];
    this.innerPanel = [];
    this.noObjectMsg = "";
    this.searchProperties = [];
    this.termData = {
      field: null,
      term: null,
    };
  }
  onItemClick(item, source, layer) {
    this.selectedData = {
      item,
      source,
      layer,
    };
    this.$store.dispatch("map/changeSearchFeature", this.selectedData);
  }

  loadPropertiesToSearchBy() {
    getPropertiesToSearchBy().then((res) => {
      this.searchProperties = res.data as any;
    });
  }

  onSearch() {
    if (this.termData.term) {
      this.loading = true;
      this.noObjectMsg = ""
      searchByPropertyValue(this.termData)
        .then((result) => {
          const property = result.data;
        if(property.length == 0 ){
          this.noObjectMsg=`${i18n.t("no_results_found")}`}
          this.dataContainer = [...result.data];
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  MoblieonSearch(){
    if(this.$store.getters.isMobile){
      this.onSearch()
    }
  }
}
