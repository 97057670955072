
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  components: {},
  computed: {
    ...mapGetters(["tag_refs", "reverse_color_data"]),
  },
})
export default class Color extends Vue {
  public nValues: any[];
  public nRange: any[];
  public currentType: number;
  public rules: any;
  public tHeaders: any[];
  public tItems: any[];
  public dropdownSelection: any[];

  public storeData: { tags: object; tableItems: any[] };

  public allowedForStylingSystem: number[];
  public allowedForStylingObject: number[];

  @Prop() readonly filters: any;
  @Prop() readonly styleSet: any;

  @Watch("filters", { immediate: true, deep: true })
  onObjectChange(filters: any) {
    this.populateStyleSet(filters);
  }

  @Watch("reverse_color_data")
  onReverseColorStyleDataChange(data: any) {
    this.reverseStyleData(data);
  }

  constructor() {
    super();
    this.nValues = [];
    this.nRange = [];
    this.currentType = -1;
    this.rules = {
      positive: (value) => value >= 0 || `${this.$t("attribute")}`,
    };
    this.tHeaders = [
      { value: "actions", width: "5%", sortable: false },
      {
        text: `${this.$t("attribute")}`,
        align: "start",
        value: "code",
        width: "15%",
        sortable: false,
      },
      {
        text: `${this.$t("value")}`,
        align: "start",
        value: "value",
        width: "30%",
        sortable: false,
      },
      {
        text: "",
        align: "start",
        value: "color",
        width: "15%",
        sortable: false,
      },
    ];
    this.tItems = [];
    this.allowedForStylingSystem = [0, 1, 10, 11];
    this.allowedForStylingObject = [0, 10];
    this.storeData = { tags: {}, tableItems: [] };
    this.dropdownSelection = [];
  }

  populateStyleSet(filters) {
    this.styleSet.splice();
    Object.keys(filters).forEach((key) => {
      const f = filters[key];

      if (
        this.allowedForStylingSystem.includes(f.systemType.id) &&
        this.allowedForStylingObject.includes(f.objectType.id)
      ) {
        this.styleSet.push({
          id: key,
          layerId: f.layerId,
          code: f.nomType.code,
          nomType: f.nomType,
          displayText: `${f.systemType.name} (${
            this.$store.getters.tag_refs[f.nomType.code]
          })`,
          nomenclatureValues:
            f.nomType.type === "text" || f.nomType.type === "radio"
              ? f.nomenclatureValues
              : [],
          from: f.from,
          to: f.to,
          selectedIds: [],
        });
      }
    });
  }

  getValues(typeId) {
    const tag = this.styleSet.find((x) => x.id === typeId);
    this.currentType = typeId;
    if (tag.nomType.type === "text" || tag.nomType.type === "radio") {
      this.nRange = [];
      this.nValues = this.styleSet.find(
        (x) => x.id === typeId
      ).nomenclatureValues;
    } else if (tag.nomType.type === "number" || tag.nomType.type === "date") {
      this.nValues = [];
      this.nRange = [tag.from, tag.to];
    }
  }

  onSelect(selection, from, to) {
    const tag = this.styleSet.find((x) => x.id === this.currentType);
    if (selection) {
      tag.selectedIds = selection;
    } else {
      tag.from = Number(from);
      tag.to = Number(to);
    }
  }

  setColor(e) {
    const tag = this.styleSet.find((x) => x.id === this.currentType);
    if (tag.nomType.type === "text" || tag.nomType.type === "radio") {
      tag.nomenclatureValues.forEach((v) => {
        if (tag.selectedIds.includes(v.id)) {
          v.color = e.hex;
        }
      });
    } else if (tag.nomType.type === "number" || tag.nomType.type === "date") {
      tag.color = e.hex;
    }

    this.storeData.tags[tag.id] = tag;
    this.$store.dispatch("map/changeColorData", this.storeData);

    this.$emit("set-color", tag, false);
    this.displayStyle(tag);
  }

  displayStyle(obj) {
    if (obj.nomType.type === "text" || obj.nomType.type === "radio") {
      obj.nomenclatureValues.forEach((x) => {
        if (x.color) {
          const uid = `${x.id}${obj.code}${x.value}`; //custom unique identifier for more aqurate targeting
          const index = this.tItems.map((i) => i.uid).indexOf(uid);
          if (index === -1) {
            this.tItems.push({
              uid: uid,
              code: obj.displayText,
              value: x.value,
              color: x.color,
              layerId: obj.layerId,
              type: obj.nomType.type,
            });
          } else {
            this.tItems[index].color = x.color;
          }
        }
      });
    } else if (obj.nomType.type === "number" || obj.nomType.type === "date") {
      const uid = `${obj.id}${obj.code}${obj.from}-${obj.to}`; //custom unique identifier for more aqurate targeting
      const index = this.tItems.map((i) => i.uid).indexOf(uid);
      if (index === -1) {
        this.tItems.push({
          uid: uid,
          code: obj.displayText,
          value: `${obj.from}-${obj.to}`,
          color: obj.color,
          layerId: obj.layerId,
          type: obj.nomType.type,
        });
      } else {
        this.tItems[index].color = obj.color;
      }
    }

    this.storeData.tableItems = this.tItems;
    this.$store.dispatch("map/changeColorData", this.storeData);
  }

  deleteItem(e, i) {
    this.tItems.splice(i, 1);
    this.$emit("set-color", e, true);
  }

  reverseStyleData(data) {
    if (Object.keys(data).length > 0 && data.tableItems.length > 0 && Object.keys(data.tags).length > 0) {
      this.tItems = data.tableItems;
      Object.keys(data.tags).forEach((key) => {
        const tag = data.tags[key];

        this.getValues(tag.id);
        if (tag.nomType.type === "text" || tag.nomType.type === "radio") {
          this.dropdownSelection = [...new Set(data.tags[key].selectedIds)];
        } else if (tag.nomType.type === "number") {
          this.nRange[0] = tag.from;
          this.nRange[1] = tag.to;
        }
      });
    }else {
      this.currentType = -1;
      this.tItems = [];
      this.nValues = [];
      this.nRange = [];
    }
  }
}
