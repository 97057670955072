import { calcScale, calcZoom } from '@/utils';
import { Map as MapboxMap } from 'mapbox-gl';
import {
  Unit,
} from './map-generator';

import * as turf from "@turf/turf";
import store from '@/store';

export default class PrintableAreaManager {
  private map: MapboxMap | undefined;
  private width: number;
  private height: number;
  private unit: string;
  private fakeWidth: any;
  private fakeHeight: any;
  private backupObj: any;
  private svgCanvas: SVGElement | undefined;
  private svgPath: SVGElement | undefined;

  constructor(
    map: MapboxMap | undefined,
  ) {
    this.map = map;
    if (this.map === undefined) {
      return;
    }
    this.mapResize = this.mapResize.bind(this);
    this.map.on('resize', this.mapResize);
    const clientWidth = this.map?.getCanvas().clientWidth;
    const clientHeight = this.map?.getCanvas().clientHeight;
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.style.position = 'absolute';
    svg.style.top = '0px';
    svg.style.left = '0px';
    svg.setAttribute('width', `${clientWidth}px`);
    svg.setAttribute('height', `${clientHeight}px`);
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.setAttribute('style', 'fill:#888888;stroke-width:0');
    path.setAttribute('fill-opacity', '0.5');
    svg.append(path);
    this.map?.getCanvasContainer().appendChild(svg);
    this.svgCanvas = svg;
    this.svgPath = path;
    this.backupObj = {
      primaryZoom: 0
    };
  }

  private mapResize() {
    this.generateCutOut();
  }

  public updateArea(width: number, height: number, fakeWidth: number, fakeHeight: number) {
    this.width = width;
    this.height = height;
    this.unit = Unit.mm;
    this.fakeWidth = fakeWidth;
    this.fakeHeight = fakeHeight;
    this.generateCutOut();
  }

  private generateCutOut() {
    if (this.map === undefined
      || this.svgCanvas === undefined
      || this.svgPath === undefined) {
      return;
    }

    if (store.getters.export_feature.scale !== "") {
      this.backupObj.primaryZoom = calcZoom(store.getters.export_feature.scale, this.map.getCenter().lat);
    }
    if (this.backupObj.primaryZoom !== 0) {
      this.map.setZoom(this.backupObj.primaryZoom);
    }

    const width = this.toPixels(this.width);
    const height = this.toPixels(this.height);
    const clientWidth = this.map?.getCanvas().clientWidth;
    const clientHeight = this.map?.getCanvas().clientHeight;
    const startX = clientWidth / 2 - width / 2;
    const endX = startX + width;
    const startY = clientHeight / 2 - height / 2;
    const endY = startY + height;
    const startCoordinates = this.map.unproject([startX, startY]);
    const endCoordinates = this.map.unproject([endX, endY]);
    const point1 = {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [startCoordinates.lng, startCoordinates.lat]
      }
    } as any;
    const point2 = {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [endCoordinates.lng, endCoordinates.lat]
      }
    } as any;
    const distance = turf.distance(point1, point2, "kilometers" as any) * 1000;

    const width_fake = this.toPixels(this.fakeWidth);
    const height_fake = this.toPixels(this.fakeHeight);

    const startX_fake = clientWidth / 2 - width_fake / 2;
    const endX_fake = startX_fake + width_fake;
    const startY_fake = clientHeight / 2 - height_fake / 2;
    const endY_fake = startY_fake + height_fake;

    const a4Diagonal = Math.sqrt(Math.pow(this.fakeWidth, 2) + Math.pow(this.fakeHeight, 2));
    const scale_fake = (distance / (a4Diagonal / 10)) * 100;
    const zoomLevel_fake = calcZoom(scale_fake, this.map.getCenter().lat);

    this.map.setZoom(zoomLevel_fake);

    this.svgCanvas.setAttribute('width', `${clientWidth}px`);
    this.svgCanvas.setAttribute('height', `${clientHeight}px`);
    this.svgPath.setAttribute('d', `M 0 0 L ${clientWidth} 0 L ${clientWidth} ${clientHeight} L 0 ${clientHeight} M ${startX_fake} ${startY_fake} L ${startX_fake} ${endY_fake} L ${endX_fake} ${endY_fake} L ${endX_fake} ${startY_fake}`);
  }

  public destroy() {
    if (this.svgCanvas !== undefined) {
      this.svgCanvas.remove();
      this.svgCanvas = undefined;
    }

    if (this.map !== undefined) {
      this.map = undefined;
    }
  }

  /**
   * Convert mm/inch to pixel
   * @param length mm/inch length
   * @param conversionFactor DPI value. default is 96.
   */
  private toPixels(length: number, conversionFactor = 96) {
    if (this.unit === Unit.mm) {
      conversionFactor /= 25.4;
    }
    return conversionFactor * length;
  }

  public clearBackupdata() {
    this.backupObj = {
      primaryZoom: 0
    };
  }

  public revertToPrimaryZoom(map) {
    if(this.backupObj.primaryZoom !== 0){
      map.setZoom(this.backupObj.primaryZoom);
    }
  }
}
