/* eslint-disable */

const ZONES = "zones_src";
const DMAZONES = "src_dma_zones";
const MEASURE = "measure_src";
const WATERMETERS_RESIDENTIAL = "src_watermeters_res";
const WATERMETERS_PUBLIC = "src_watermeters_pub";

const ZONES_INIT_OBJECT = {
  type: "FeatureCollection",
  features: [],
} as any;

export default {
  ZONES_INIT_OBJECT,
  ZONES,
  DMAZONES,
  MEASURE,
  WATERMETERS_RESIDENTIAL,
  WATERMETERS_PUBLIC,
}