/* eslint-disable */

import layer from "@/utils/constants/map/layer";

export default class SymbolLayers {

    setLayersByParams(map, systems, activeObjects, zoomValid) {
        if (zoomValid) { // zoom higher than 15
            if (systems[0].show) { // Water
                if (activeObjects[0][0].show) { // Lines
                    map.setLayoutProperty(layer.WATERLINES_LABEL, 'visibility', 'visible');
                } else {
                    map.setLayoutProperty(layer.WATERLINES_LABEL, 'visibility', 'none');
                }
                if (activeObjects[0][1].show) { // Points
                    map.setLayoutProperty(layer.WATERPOINTS_LABEL, 'visibility', 'visible');
                } else {
                    map.setLayoutProperty(layer.WATERPOINTS_LABEL, 'visibility', 'none');
                }
                if (activeObjects[0][2].show) { // Areal
                    map.setLayoutProperty(layer.AREAL_POLY_WATER_LABEL, 'visibility', 'visible');
                } else {
                    map.setLayoutProperty(layer.AREAL_POLY_WATER_LABEL, 'visibility', 'none');
                }
                if (activeObjects[0][3].show) { // Watermeters residential
                    map.setLayoutProperty(layer.WATERMETERS_RESIDENTIAL_LABEL, 'visibility', 'visible');
                } else {
                    map.setLayoutProperty(layer.WATERMETERS_RESIDENTIAL_LABEL, 'visibility', 'none');
                }
                if (activeObjects[0][4].show) { // Watermeters public
                    map.setLayoutProperty(layer.WATERMETERS_PUBLIC_LABEL, 'visibility', 'visible');
                } else {
                    map.setLayoutProperty(layer.WATERMETERS_PUBLIC_LABEL, 'visibility', 'none');
                }
            } else {
                map.setLayoutProperty(layer.WATERLINES_LABEL, 'visibility', 'none');
                map.setLayoutProperty(layer.WATERPOINTS_LABEL, 'visibility', 'none');
                map.setLayoutProperty(layer.AREAL_POLY_WATER_LABEL, 'visibility', 'none');
                map.setLayoutProperty(layer.WATERMETERS_RESIDENTIAL_LABEL, 'visibility', 'none');
                map.setLayoutProperty(layer.WATERMETERS_PUBLIC_LABEL, 'visibility', 'none');
            }

            if (systems[1].show) { // Canal
                if (activeObjects[1][0].show) { // Lines
                    map.setLayoutProperty(layer.CANALLINES_LABEL, 'visibility', 'visible');
                } else {
                    map.setLayoutProperty(layer.CANALLINES_LABEL, 'visibility', 'none');
                }
                if (activeObjects[1][1].show) { // Points
                    map.setLayoutProperty(layer.CANALPOINTS_LABEL, 'visibility', 'visible');
                } else {
                    map.setLayoutProperty(layer.CANALPOINTS_LABEL, 'visibility', 'none');
                }
                if (activeObjects[1][2].show) { // Areal
                    map.setLayoutProperty(layer.AREAL_POLY_CANAL_LABEL, 'visibility', 'visible');
                } else {
                    map.setLayoutProperty(layer.AREAL_POLY_CANAL_LABEL, 'visibility', 'none');
                }
            } else {
                map.setLayoutProperty(layer.CANALLINES_LABEL, 'visibility', 'none');
                map.setLayoutProperty(layer.CANALPOINTS_LABEL, 'visibility', 'none');
                map.setLayoutProperty(layer.AREAL_POLY_CANAL_LABEL, 'visibility', 'none');
            }

            map.setLayoutProperty(layer.ZONES_POLYGON_LABEL, 'visibility', 'visible');
            map.setLayoutProperty(layer.ZONES_LINE_LABEL, 'visibility', 'visible');
            map.setLayoutProperty(layer.ZONES_POINT_LABEL, 'visibility', 'visible');

        } else { // zoom lower than 15
            this.hideAll(map);
        }
    }

    showAll(map) {
        map.setLayoutProperty(layer.WATERLINES_LABEL, 'visibility', 'visible');
        map.setLayoutProperty(layer.WATERPOINTS_LABEL, 'visibility', 'visible');
        map.setLayoutProperty(layer.CANALLINES_LABEL, 'visibility', 'visible');
        map.setLayoutProperty(layer.CANALPOINTS_LABEL, 'visibility', 'visible');
        map.setLayoutProperty(layer.AREAL_POLY_WATER_LABEL, 'visibility', 'visible');
        map.setLayoutProperty(layer.AREAL_POLY_CANAL_LABEL, 'visibility', 'visible');
        map.setLayoutProperty(layer.ZONES_POLYGON_LABEL, 'visibility', 'visible');
        map.setLayoutProperty(layer.ZONES_LINE_LABEL, 'visibility', 'visible');
        map.setLayoutProperty(layer.ZONES_POINT_LABEL, 'visibility', 'visible');
        if (Number(process.env.VUE_APP_SHOW_OUT_OF_USE_LAYERS) === 1) {
            map.setLayoutProperty(layer.OUTOFSERVICE_WATERPOINTS_LABEL, 'visibility', 'visible');
            map.setLayoutProperty(layer.OUTOFSERVICE_CANALPOINTS_LABEL, 'visibility', 'visible');
        }
        if (Number(process.env.VUE_APP_SHOW_UVM_LAYERS) === 1) {
            map.setLayoutProperty(layer.PER_ZONES_LABEL, 'visibility', 'visible');
            map.setLayoutProperty(layer.SANITARY_SECURITY_ZONES_LABEL, 'visibility', 'visible');
        }
    }

    hideAll(map) {
        map.setLayoutProperty(layer.WATERLINES_LABEL, 'visibility', 'none');
        map.setLayoutProperty(layer.WATERPOINTS_LABEL, 'visibility', 'none');
        map.setLayoutProperty(layer.CANALLINES_LABEL, 'visibility', 'none');
        map.setLayoutProperty(layer.CANALPOINTS_LABEL, 'visibility', 'none');
        map.setLayoutProperty(layer.AREAL_POLY_WATER_LABEL, 'visibility', 'none');
        map.setLayoutProperty(layer.AREAL_POLY_CANAL_LABEL, 'visibility', 'none');
        map.setLayoutProperty(layer.ZONES_POLYGON_LABEL, 'visibility', 'none');
        map.setLayoutProperty(layer.ZONES_LINE_LABEL, 'visibility', 'none');
        map.setLayoutProperty(layer.ZONES_POINT_LABEL, 'visibility', 'none');
        if (Number(process.env.VUE_APP_SHOW_OUT_OF_USE_LAYERS) === 1) {
            map.setLayoutProperty(layer.OUTOFSERVICE_WATERPOINTS_LABEL, 'visibility', 'none');
            map.setLayoutProperty(layer.OUTOFSERVICE_CANALPOINTS_LABEL, 'visibility', 'none');
        }
        if (Number(process.env.VUE_APP_SHOW_UVM_LAYERS) === 1) {
            map.setLayoutProperty(layer.PER_ZONES_LABEL, 'visibility', 'none');
            map.setLayoutProperty(layer.SANITARY_SECURITY_ZONES_LABEL, 'visibility', 'none');
        }
    }
}