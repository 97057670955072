import { IMarkerLocation, IPointData } from "@/interfaces";
import { IControl} from 'mapbox-gl';
import moment from "moment";


export default class PointDataModel implements IPointData {
  public location: IMarkerLocation;
  public registrationDate: string;
  public width: number;
  public length: number;
  public latitude: number;
  public longitude: number;
  public altitude: number;
  public remarks: string;

  constructor(){
    this.location = {
      coordinates: [0, 0],
      type: "Point"
    }

    this.registrationDate = moment().format('YYYY-MM-DD');
    this.width = 0;
    this.length = 0;
    this.latitude = 0;
    this.longitude = 0;
    this.altitude = 0;
    this.remarks = ""
  }
}

export class MeasureControl implements IControl {
  private measureContainer: HTMLElement;

  public onAdd(): HTMLElement {
    this.measureContainer = document.createElement('div');
    this.measureContainer.classList.add('mapboxgl-measure-group');

    return this.measureContainer;
  }

  public onRemove(): void {
    if (!this.measureContainer) {
      return;
    }
    this.measureContainer.parentNode!.removeChild(this.measureContainer);
  }
}