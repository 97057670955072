
import { searchByCadastre } from "@/api/map";
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import i18n from "@/i18n";

@Component({
  components: {},
  computed: {
    ...mapGetters(["tag_refs","isMobile"]),
  },
  
})
export default class SearchByCadastre extends Vue {
  public term: string;
  public loading: boolean;
  public noObjectMsg: string;

  constructor() {
    super();
    this.term = "";
    this.loading = false;
    this.noObjectMsg = "";
  }

  onSearch() {
    this.loading = true;
    this.noObjectMsg = "";
    searchByCadastre(this.term)
      .then((res) => {
        const property = res.data;
        if(!property){this.noObjectMsg=`${i18n.t("no_results_found")}`}
        this.$store.dispatch("map/changeSearchCADASTRE", property);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  MobileonSearch(){
    if(this.$store.getters.isMobile){
      this.onSearch()

    }
  }
}
