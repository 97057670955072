import Vue from "vue";
import VueI18n from "vue-i18n";
import i18nBG from "./locales/bg";
import i18nEN from "./locales/en";

Vue.use(VueI18n);

const languages = {
  BG: { bg: i18nBG },
  EN: { en: i18nEN },
};


const i18n = new VueI18n({
  locale: "bg",
  fallbackLocale: "bg",
  messages: languages["BG"],
});

// const i18n = new VueI18n({
//   locale: "en",
//   fallbackLocale: "en",
//   messages: languages["EN"],
// });

export default i18n;
