
  import { Component, Vue } from 'vue-property-decorator'
  import { mapGetters } from 'vuex';
  import CoreAppBar from "./components/AppBar.vue";
  import CoreAppDrawer from "./components/Drawer.vue";
  import CoreAppView from "./components/View.vue";

  @Component({
    components: {
      CoreAppBar,
      CoreAppDrawer,
      CoreAppView
    },
    computed: {
      ...mapGetters(['isLogged'])
    }
  })
  export default class Layout extends Vue {
    private expandOnHover: boolean;
    public showByMobileDevice: boolean;

    constructor(){
      super();
      this.expandOnHover = false;
       this.showByMobileDevice = window.matchMedia("(pointer:coarse)").matches
      ? true
      : false;
       
      this.$store.commit("app/SET_MOBLIE", this.showByMobileDevice);
    }
  }
