import common from "@/utils/constants/map/common";
import store from '@/store';

export default class ExportService {
    private mmToPxRatio = 3.779528;
    private topOffset = 20; //mm
    private sidesOffset = 5; //mm
    private data = store.getters.export_feature;

    drawFrame(mapCanvas) {
        //Create canvas container
        const canvas = document.createElement("canvas");
        canvas.setAttribute("id", "export-grid");
        const ctx = canvas.getContext("2d")!;

        //Configure canvas
        let width = 0; let height = 0;
        if (this.data.orientation === 'landscape') {
            width = this.data.pageFormat[0] * this.mmToPxRatio;
            height = this.data.pageFormat[1] * this.mmToPxRatio;
        }
        else if (this.data.orientation === 'portrait') {
            width = this.data.pageFormat[1] * this.mmToPxRatio;
            height = this.data.pageFormat[0] * this.mmToPxRatio;
        }

        ctx.canvas.width = width;
        ctx.canvas.height = height;

        //Frame properties
        const tofst = this.topOffset * this.mmToPxRatio;
        const sofst = this.sidesOffset * this.mmToPxRatio;
        const bottomX = width - tofst - sofst;
        const bottomY = height - (2 * sofst);

        const promice = new Promise<any>((resolve) => {
            const img = new Image();
            img.onload = () => {
                //Main image
                ctx.drawImage(img, tofst, sofst, bottomX, bottomY);

                //Main frame
                ctx.strokeRect(tofst, sofst, bottomX, bottomY);

                //Drawing grid by choise
                this.attachGridAndData(ctx, tofst, sofst, bottomX, bottomY);

                //@ts-ignore
                resolve();
            }
            img.setAttribute("src", mapCanvas.toDataURL());
        });

        return promice.then(() => {
            return canvas;
        });
    }

    attachGridAndData(ctx, tofst, sofst, bottomX, bottomY) {
        if (this.data.drawingKey === 1)
            this.getSmall(ctx, tofst, sofst, bottomX, bottomY);
        else if (this.data.drawingKey === 2)
            this.getMedium(ctx, tofst, sofst, bottomX, bottomY)
        else if (this.data.drawingKey === 3)
            this.getLarge(ctx, tofst, sofst, bottomX, bottomY)
        else if (this.data.drawingKey === 4)
            this.getFull(ctx, tofst, sofst, bottomX, bottomY)
        /* eslint-disable */
        else { } // drawingKey is 0 (no grid)
    }

    getSmall(ctx, tofst, sofst, bottomX, bottomY) { //A4-A3 small
        const gridWidth = 101 * this.mmToPxRatio;
        const gridHeight = 32 * this.mmToPxRatio;
        const firstRowHeight = 16 * this.mmToPxRatio;
        const secondRowHeight = 8 * this.mmToPxRatio;
        const thirdRowHeight = 8 * this.mmToPxRatio;
        const firstColWidth = 5;
        const secondColWidth = 31 * this.mmToPxRatio;

        ctx.fillStyle = 'white'; //set white to clear the area of the grid
        ctx.fillRect(bottomX + tofst - 1, bottomY + sofst - 1, -gridWidth, -gridHeight);
        ctx.fillStyle = 'black'; //set black to start drawing the grid 

        //Vertical grid separators
        ctx.beginPath();
        ctx.moveTo(bottomX + tofst - gridWidth, bottomY + sofst - gridHeight);
        ctx.lineTo(bottomX + tofst - gridWidth, bottomY + sofst);
        ctx.moveTo(bottomX - gridWidth + sofst + secondColWidth, bottomY + sofst - gridHeight);
        ctx.lineTo(bottomX - gridWidth + sofst + secondColWidth, bottomY + sofst);
        ctx.stroke();

        //Horizontal grid separators
        ctx.beginPath();
        ctx.moveTo(bottomX + tofst - gridWidth, bottomY + sofst - gridHeight);
        ctx.lineTo(bottomX + tofst, bottomY + sofst - gridHeight);
        ctx.moveTo(bottomX + tofst - gridWidth, bottomY + sofst - gridHeight + firstRowHeight);
        ctx.lineTo(bottomX + tofst, bottomY + sofst - gridHeight + firstRowHeight);
        ctx.moveTo(bottomX + tofst - gridWidth, bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight);
        ctx.lineTo(bottomX + tofst, bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight);
        ctx.stroke();

        //Titles and text values
        //Titles
        ctx.font = `16px Times New Roman`; //set text size and font of the titles
        ctx.fillText(
            `${common.GRID_DATA.Object.description}:`,
            bottomX + tofst - gridWidth + firstColWidth,
            bottomY + sofst - gridHeight + firstRowHeight - firstColWidth);

        ctx.fillText(
            `${common.GRID_DATA.Date.description}:`,
            bottomX + tofst - gridWidth + firstColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight - firstColWidth);

        ctx.fillText(
            `${common.GRID_DATA.Scale.description}:`,
            bottomX + tofst - gridWidth + firstColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight + thirdRowHeight - firstColWidth);


        //Values
        ctx.font = `${this.data.fontSize}px ${this.data.fontStyle}`; //set text size and font   
        ctx.fillStyle = `${this.data.color}`; //set selected text color

        ctx.fillText(
            `${this.data.object}`,
            bottomX + sofst - gridWidth + secondColWidth + firstColWidth,
            bottomY + sofst - gridHeight + firstRowHeight - firstColWidth);

        ctx.fillText(
            `${this.getDateToday()}`,
            bottomX + sofst - gridWidth + firstColWidth + secondColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight - firstColWidth);

        ctx.fillText(
            `1:${this.data.scale}`,
            bottomX + sofst - gridWidth + secondColWidth + firstColWidth,
            bottomY + sofst - firstColWidth);
    }

    getMedium(ctx, tofst, sofst, bottomX, bottomY) { //A4-A3 medium
        const gridWidth = 101 * this.mmToPxRatio;
        const gridHeight = 48 * this.mmToPxRatio;
        const firstRowHeight = 16 * this.mmToPxRatio;
        const secondRowHeight = 8 * this.mmToPxRatio;
        const thirdRowHeight = 8 * this.mmToPxRatio;
        const firstColWidth = 5;
        const secondColWidth = 32.5 * this.mmToPxRatio;

        ctx.fillStyle = 'white'; //set white to clear the area of the grid
        ctx.fillRect(bottomX + tofst - 1, bottomY + sofst - 1, -gridWidth, -gridHeight);
        ctx.fillStyle = 'black'; //set black to start drawing the grid 

        //Vertical grid separators
        ctx.beginPath();
        ctx.moveTo(bottomX + tofst - gridWidth, bottomY + sofst - gridHeight);
        ctx.lineTo(bottomX + tofst - gridWidth, bottomY + sofst);
        ctx.moveTo(bottomX - gridWidth + 2 * secondColWidth - tofst + firstColWidth, bottomY + sofst - gridHeight);
        ctx.lineTo(bottomX - gridWidth + 2 * secondColWidth - tofst + firstColWidth, bottomY + sofst);
        ctx.stroke();

        //Horizontal grid separators
        ctx.beginPath();
        ctx.moveTo(bottomX + tofst - gridWidth, bottomY + sofst - gridHeight);
        ctx.lineTo(bottomX + tofst, bottomY + sofst - gridHeight);
        ctx.moveTo(bottomX + tofst - gridWidth, bottomY + sofst - gridHeight + firstRowHeight);
        ctx.lineTo(bottomX + tofst, bottomY + sofst - gridHeight + firstRowHeight);
        ctx.moveTo(bottomX + tofst - gridWidth, bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight);
        ctx.lineTo(bottomX + tofst, bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight);
        ctx.moveTo(bottomX + tofst - gridWidth, bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight + thirdRowHeight);
        ctx.lineTo(bottomX + tofst, bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight + thirdRowHeight);
        ctx.moveTo(bottomX + tofst - gridWidth, bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight + 2 * thirdRowHeight);
        ctx.lineTo(bottomX + tofst, bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight + 2 * thirdRowHeight);
        ctx.stroke();

        //Titles and text values
        //Titles
        ctx.font = `16px Times New Roman`; //set text size and font of the titles
        ctx.fillText(
            `${common.GRID_DATA.Object.description}:`,
            bottomX + tofst - gridWidth + firstColWidth,
            bottomY + sofst - gridHeight + firstRowHeight - firstColWidth);

        ctx.fillText(
            `${common.GRID_DATA.Contractor.description}:`,
            bottomX + tofst - gridWidth + firstColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight - firstColWidth);

        ctx.fillText(
            `${common.GRID_DATA.PreparedBy.description}:`,
            bottomX + tofst - gridWidth + firstColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight + thirdRowHeight - firstColWidth);

        ctx.fillText(
            `${common.GRID_DATA.Date.description}:`,
            bottomX + tofst - gridWidth + firstColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight + 2 * thirdRowHeight - firstColWidth);

        ctx.fillText(
            `${common.GRID_DATA.Scale.description}:`,
            bottomX + tofst - gridWidth + firstColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight + 3 * thirdRowHeight - firstColWidth);

        //Values
        ctx.font = `${this.data.fontSize}px ${this.data.fontStyle}`; //set text size and font   
        ctx.fillStyle = `${this.data.color}`; //set selected text color

        ctx.fillText(
            `${this.data.object}`,
            bottomX + tofst - sofst - gridWidth + secondColWidth,
            bottomY + sofst - gridHeight + firstRowHeight - firstColWidth);

        ctx.fillText(
            `${this.data.contractor}`,
            bottomX + tofst - sofst - gridWidth + secondColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight - firstColWidth);

        ctx.fillText(
            `${this.data.preparedBy}`,
            bottomX + tofst - sofst - gridWidth + secondColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight + thirdRowHeight - firstColWidth);

        ctx.fillText(
            `${this.getDateToday()}`,
            bottomX + tofst - sofst - gridWidth + secondColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight + 2 * thirdRowHeight - firstColWidth);

        ctx.fillText(
            `1:${this.data.scale}`,
            bottomX + tofst - sofst - gridWidth + secondColWidth,
            bottomY + sofst - firstColWidth);
    }

    getLarge(ctx, tofst, sofst, bottomX, bottomY) { //A4-A3 large
        const gridWidth = 185 * this.mmToPxRatio;
        const gridHeight = 32 * this.mmToPxRatio;
        const firstRowHeight = 16 * this.mmToPxRatio;
        const secondRowHeight = 8 * this.mmToPxRatio;
        const thirdRowHeight = 8 * this.mmToPxRatio;
        const firstColWidth = 5;
        const secondColWidth = 136.04 * this.mmToPxRatio;

        ctx.fillStyle = 'white'; //set white to clear the area of the grid
        ctx.fillRect(bottomX + tofst - 1, bottomY + sofst - 1, -gridWidth, -gridHeight);
        ctx.fillStyle = 'black'; //set black to start drawing the grid 

        //Vertical grid separators
        ctx.beginPath();
        ctx.moveTo(bottomX + tofst - gridWidth, bottomY + sofst - gridHeight);
        ctx.lineTo(bottomX + tofst - gridWidth, bottomY + sofst);
        ctx.moveTo(bottomX - gridWidth + sofst + secondColWidth, bottomY + sofst - gridHeight);
        ctx.lineTo(bottomX - gridWidth + sofst + secondColWidth, bottomY + sofst);
        ctx.stroke();

        //Horizontal grid separators
        ctx.beginPath();
        ctx.moveTo(bottomX + tofst - gridWidth, bottomY + sofst - gridHeight);
        ctx.lineTo(bottomX + tofst, bottomY + sofst - gridHeight);
        ctx.moveTo(bottomX + tofst - gridWidth, bottomY + sofst - gridHeight + firstRowHeight);
        ctx.lineTo(bottomX + tofst, bottomY + sofst - gridHeight + firstRowHeight);
        ctx.moveTo(bottomX + tofst - gridWidth, bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight);
        ctx.lineTo(bottomX + tofst, bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight);
        ctx.stroke();

        //Titles and text values
        //Titles
        ctx.font = `16px Times New Roman`; //set text size and font of the titles
        ctx.fillText(
            `${common.GRID_DATA.Object.description}:`,
            bottomX + tofst - gridWidth + firstColWidth,
            bottomY + sofst - gridHeight + firstRowHeight - firstColWidth);

        ctx.fillText(
            `${common.GRID_DATA.Contractor.description}:`,
            bottomX + tofst - gridWidth + firstColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight - firstColWidth);

        ctx.fillText(
            `${common.GRID_DATA.PreparedBy.description}:`,
            bottomX + tofst - gridWidth + firstColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight + thirdRowHeight - firstColWidth);

        ctx.fillText(
            `${common.GRID_DATA.Date.description}:`,
            bottomX + sofst - gridWidth + firstColWidth + secondColWidth,
            bottomY + sofst - gridHeight + firstRowHeight - firstColWidth);

        ctx.fillText(
            `${common.GRID_DATA.Scale.description}:`,
            bottomX + sofst - gridWidth + firstColWidth + secondColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight - firstColWidth);

        ctx.fillText(
            `${common.GRID_DATA.Signature.description}:`,
            bottomX + sofst - gridWidth + firstColWidth + secondColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight + thirdRowHeight - firstColWidth);

        //Values
        ctx.font = `${this.data.fontSize}px ${this.data.fontStyle}`; //set text size and font   
        ctx.fillStyle = `${this.data.color}`; //set selected text color

        //first column values
        ctx.fillText(
            `${this.data.object}`,
            bottomX + 2 * tofst - sofst - gridWidth + firstColWidth,
            bottomY + sofst - gridHeight + firstRowHeight - firstColWidth);

        ctx.fillText(
            `${this.data.contractor}`,
            bottomX + 2 * tofst + sofst - gridWidth + firstColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight - firstColWidth);

        ctx.fillText(
            `${this.data.preparedBy}`,
            bottomX + 2 * tofst - gridWidth + firstColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight + thirdRowHeight - firstColWidth);

        ctx.fillText(
            `${this.getDateToday()}`,
            bottomX + 2 * tofst - gridWidth + secondColWidth,
            bottomY + sofst - gridHeight + firstRowHeight - firstColWidth);

        ctx.fillText(
            `1:${this.data.scale}`,
            bottomX + 2 * tofst - gridWidth + secondColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight - firstColWidth);
    }

    getFull(ctx, tofst, sofst, bottomX, bottomY) { //A3
        const gridWidth = 114 * this.mmToPxRatio;
        const gridHeight = 88 * this.mmToPxRatio;
        const firstRowHeight = 8 * this.mmToPxRatio;
        const secondRowHeight = 8 * this.mmToPxRatio;
        const thirdRowHeight = 16 * this.mmToPxRatio;
        const firstColWidth = 5;
        const secondColWidth = 41 * this.mmToPxRatio;

        ctx.fillStyle = 'white'; //set white to clear the area of the grid
        ctx.fillRect(bottomX + tofst - 1, bottomY + sofst - 1, -gridWidth, -gridHeight);
        ctx.fillStyle = 'black'; //set black to start drawing the grid 

        //Vertical grid separators
        ctx.beginPath();
        ctx.moveTo(bottomX + tofst - gridWidth, bottomY + sofst - gridHeight);
        ctx.lineTo(bottomX + tofst - gridWidth, bottomY + sofst);
        ctx.moveTo(bottomX - gridWidth + 2 * sofst + secondColWidth, bottomY + sofst - gridHeight);
        ctx.lineTo(bottomX - gridWidth + 2 * sofst + secondColWidth, bottomY + sofst);
        ctx.stroke();

        //Horizontal grid separators
        ctx.beginPath();
        ctx.moveTo(bottomX + tofst - gridWidth, bottomY + sofst - gridHeight);
        ctx.lineTo(bottomX + tofst, bottomY + sofst - gridHeight);
        ctx.moveTo(bottomX + tofst - gridWidth, bottomY + sofst - gridHeight + firstRowHeight);
        ctx.lineTo(bottomX + tofst, bottomY + sofst - gridHeight + firstRowHeight);
        ctx.moveTo(bottomX + tofst - gridWidth, bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight);
        ctx.lineTo(bottomX + tofst, bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight);
        ctx.moveTo(bottomX + tofst - gridWidth, bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight + thirdRowHeight);
        ctx.lineTo(bottomX + tofst, bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight + thirdRowHeight);
        ctx.moveTo(bottomX + tofst - gridWidth, bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight + 2 * thirdRowHeight);
        ctx.lineTo(bottomX + tofst, bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight + 2 * thirdRowHeight);
        ctx.moveTo(bottomX + tofst - gridWidth, bottomY + sofst - gridHeight + firstRowHeight + 2 * secondRowHeight + 2 * thirdRowHeight);
        ctx.lineTo(bottomX + tofst, bottomY + sofst - gridHeight + firstRowHeight + 2 * secondRowHeight + 2 * thirdRowHeight);
        ctx.moveTo(bottomX + tofst - gridWidth, bottomY + sofst - gridHeight + firstRowHeight + 3 * secondRowHeight + 2 * thirdRowHeight);
        ctx.lineTo(bottomX + tofst, bottomY + sofst - gridHeight + firstRowHeight + 3 * secondRowHeight + 2 * thirdRowHeight);
        ctx.moveTo(bottomX + tofst - gridWidth, bottomY + sofst - gridHeight + firstRowHeight + 4 * secondRowHeight + 2 * thirdRowHeight);
        ctx.lineTo(bottomX + tofst, bottomY + sofst - gridHeight + firstRowHeight + 4 * secondRowHeight + 2 * thirdRowHeight);
        ctx.moveTo(bottomX + tofst - gridWidth, bottomY + sofst - gridHeight + firstRowHeight + 5 * secondRowHeight + 2 * thirdRowHeight);
        ctx.lineTo(bottomX + tofst, bottomY + sofst - gridHeight + firstRowHeight + 5 * secondRowHeight + 2 * thirdRowHeight);
        ctx.stroke();

        //Titles and text values
        //Titles
        ctx.font = `16px Times New Roman`; //set text size and font of the titles
        ctx.fillText(
            `${common.GRID_DATA.DocNumber.description}:`,
            bottomX + tofst - gridWidth + firstColWidth,
            bottomY + sofst - gridHeight + firstRowHeight - firstColWidth);

        ctx.fillText(
            `${common.GRID_DATA.Contractor.description}:`,
            bottomX + tofst - gridWidth + firstColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight - firstColWidth);

        ctx.fillText(
            `${common.GRID_DATA.Object.description}:`,
            bottomX + tofst - gridWidth + firstColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight + thirdRowHeight - firstColWidth);

        ctx.fillText(
            `${common.GRID_DATA.SubObject.description}:`,
            bottomX + tofst - gridWidth + firstColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight + 2 * thirdRowHeight - firstColWidth);

        ctx.fillText(
            `${common.GRID_DATA.System.description}:`,
            bottomX + tofst - gridWidth + firstColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + 2 * secondRowHeight + 2 * thirdRowHeight - firstColWidth);

        ctx.fillText(
            `${common.GRID_DATA.PreparedBy.description}:`,
            bottomX + tofst - gridWidth + firstColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + 3 * secondRowHeight + 2 * thirdRowHeight - firstColWidth);

        ctx.fillText(
            `${common.GRID_DATA.Date.description}:`,
            bottomX + tofst - gridWidth + firstColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + 4 * secondRowHeight + 2 * thirdRowHeight - firstColWidth);

        ctx.fillText(
            `${common.GRID_DATA.Scale.description}:`,
            bottomX + tofst - gridWidth + firstColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + 5 * secondRowHeight + 2 * thirdRowHeight - firstColWidth);

        ctx.fillText(
            `${common.GRID_DATA.CoordSystem.description}:`,
            bottomX + tofst - gridWidth + firstColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + 6 * secondRowHeight + 2 * thirdRowHeight - firstColWidth);

        //Values
        ctx.font = `${this.data.fontSize}px ${this.data.fontStyle}`; //set text size and font   
        ctx.fillStyle = `${this.data.color}`; //set selected text color

        ctx.fillText(
            `${this.data.docNumber}`,
            bottomX + tofst - sofst - gridWidth + secondColWidth,
            bottomY + sofst - gridHeight + firstRowHeight - firstColWidth);

        ctx.fillText(
            `${this.data.contractor}`,
            bottomX + tofst - sofst - gridWidth + secondColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight - firstColWidth);

        ctx.fillText(
            `${this.data.object}`,
            bottomX + tofst - sofst - gridWidth + secondColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight + thirdRowHeight - firstColWidth);

        ctx.fillText(
            `${this.data.subObject}`,
            bottomX + tofst - sofst - gridWidth + secondColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + secondRowHeight + 2 * thirdRowHeight - firstColWidth);

        ctx.fillText(
            `${this.data.system}`,
            bottomX + tofst - sofst - gridWidth + secondColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + 2 * secondRowHeight + 2 * thirdRowHeight - firstColWidth);

        ctx.fillText(
            `${this.data.preparedBy}`,
            bottomX + tofst - sofst - gridWidth + secondColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + 3 * secondRowHeight + 2 * thirdRowHeight - firstColWidth);

        ctx.fillText(
            `${this.getDateToday()}`,
            bottomX + tofst - sofst - gridWidth + secondColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + 4 * secondRowHeight + 2 * thirdRowHeight - firstColWidth);

        ctx.fillText(
            `1:${this.data.scale}`,
            bottomX + tofst - sofst - gridWidth + secondColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + 5 * secondRowHeight + 2 * thirdRowHeight - firstColWidth);

        ctx.fillText(
            `${this.data.coordSystem}`,
            bottomX + tofst - sofst - gridWidth + secondColWidth,
            bottomY + sofst - gridHeight + firstRowHeight + 6 * secondRowHeight + 2 * thirdRowHeight - firstColWidth);
    }

    getDateToday() {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        const date = dd + "." + mm + "." + yyyy;
        return date;
    }
}